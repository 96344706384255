export const projectTitle = [
  { id: 0, name: 'clientContracts', label: 'Client Contracts' },
  { id: 1, name: 'invoices', label: 'Client Invoices' }
];

export const subNavList = [
  { id: 0, name: 'clientcontracts', label: 'Client Contracts' },
  { id: 1, name: 'invoices', label: 'Client Invoices' }
];

export const idSubTabNameMapping = {
  0: 'clientcontracts',
  1: 'invoices',
};

export const CLIENT_BILLING_DELETE_START = 'CLIENT_BILLING_DELETE_START';
export const CLIENT_BILLING_DELETE_SUCCESS = 'CLIENT_BILLING_DELETE_SUCCESS';
export const CLIENT_BILLING_DELETE_FAILED = 'CLIENT_BILLING_DELETE_FAILED';
export const CLIENT_BILLING_DELETE_RESET = 'CLIENT_BILLING_DELETE_RESET';
export const CLIENT_BILLING_GET_START =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_GET_START';
export const CLIENT_BILLING_GET_SUCCESS =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_GET_SUCCESS';
export const CLIENT_BILLING_GET_FAILED =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_GET_FAILED';
export const CLIENT_BILLING_DETAILS_GET_START =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_DETAILS_GET_START';
export const CLIENT_BILLING_DETAILS_GET_SUCCESS =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_DETAILS_GET_SUCCESS';
export const CLIENT_BILLING_DETAILS_GET_FAILED =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_DETAILS_GET_FAILED';
export const CLIENT_BILLING_DETAILS_GET_RESET =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_DETAILS_GET_RESET';

export const CLIENT_BILLING_DETAILS_SAVE_UPDATE_START =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_DETAILS_SAVE_UPDATE_START';
export const CLIENT_BILLING_DETAILS_SAVE_UPDATE_SUCCESS =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_DETAILS_SAVE_UPDATE_SUCCESS';
export const CLIENT_BILLING_DETAILS_SAVE_UPDATE_FAILED =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_DETAILS_SAVE_UPDATE_FAILED';
export const CLIENT_BILLING_DETAILS_SAVE_UPDATE_RESET =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_DETAILS_SAVE_UPDATE_RESET';

export const FORECAST_PROJECT_DETAILS_GET_START =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/FORECAST_PROJECT_DETAILS_GET_START';
export const FORECAST_PROJECT_DETAILS_GET_SUCCESS =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/FORECAST_PROJECT_DETAILS_GET_SUCCESS';
export const FORECAST_PROJECT_DETAILS_GET_FAILED =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/FORECAST_PROJECT_DETAILS_GET_FAILED';

export const GET_COST_STRUCTURE_START =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/GET_COST_STRUCTURE_START';
export const GET_COST_STRUCTURE_SUCCESS =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/GET_COST_STRUCTURE_SUCCESS';
export const GET_COST_STRUCTURE_FAILURE =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/GET_COST_STRUCTURE_FAILURE';

export const CLIENT_BILLING_SUBMIT_START =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_SUBMIT_START';
export const CLIENT_BILLING_SUBMIT_SUCCESS =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_SUBMIT_SUCCESS';
export const CLIENT_BILLING_SUBMIT_FAILED =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_SUBMIT_FAILED';

export const CLIENT_BILLING_REVOKE_START =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_REVOKE_START';
export const CLIENT_BILLING_REVOKE_SUCCESS =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_REVOKE_SUCCESS';
export const CLIENT_BILLING_REVOKE_FAILED =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_REVOKE_FAILED';
export const CLIENT_BILLING_REVOKE_RESET =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_REVOKE_RESET';

export const CLIENT_BILLING_VALIDATION_START =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_VALIDATION_START';
export const CLIENT_BILLING_VALIDATION_SUCCESS =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_VALIDATION_SUCCESS';
export const CLIENT_BILLING_VALIDATION_FAILED =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_VALIDATION_FAILED';
export const CLIENT_BILLING_VALIDATION_RESET =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_BILLING_VALIDATION_RESET';
export const BID_STATUS_SAVE_SUCCESS =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/BID_STATUS_SAVE_SUCCESS';
export const BID_STATUS_SAVE_FAILURE =
  'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/BID_STATUS_SAVE_FAILURE';
  export const CLIENT_INVOICES_GET_START = 'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_INVOICES_GET_START';
  export const CLIENT_INVOICES_GET_SUCCESS = 'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_INVOICES_GET_SUCCESS';
  export const CLIENT_INVOICES_GET_FAILED = 'CBRE-PJM-PRINCIPAL-LITE-CLIENT-BILLING/CLIENT_INVOICES_GET_FAILED';
