export const GET_CRITERIA_DATA_SUCCESS = 'GET_CRITERIA_DATA_SUCCESS';
export const GET_CRITERIA_DATA_FAILURE = 'GET_CRITERIA_DATA_FAILURE';
export const GET_CRITERIA_DATA_RESET = 'GET_CRITERIA_DATA_RESET';
export const GET_CRITERIA_DATA_START = 'GET_CRITERIA_DATA_START';
export const POST_CRITERIA_DATA_SUCCESS = 'POST_CRITERIA_DATA_SUCCESS';
export const POST_CRITERIA_DATA_FAILURE = 'POST_CRITERIA_DATA_FAILURE';
export const POST_CRITERIA_DATA_START = 'POST_CRITERIA_DATA_START';
export const RESET_CRITERIA_DATA_SUCCESS = 'RESET_CRITERIA_DATA_SUCCESS';
export const RESET_CRITERIA_DATA_FAILURE = 'RESET_CRITERIA_DATA_FAILURE';
export const RESET_CRITERIA_DATA_START = 'RESET_CRITERIA_DATA_START';
