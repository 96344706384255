
/* eslint-disable */
import {
  GET_PDA_LIST_FILTER_SUCCESS,
  GET_PDA_LIST_FILTER_FAILURE,
  GET_PDA_LIST_FILTER_RESET,
  GET_PDA_DIVISION_FILTER_SUCCESS,
  GET_PDA_DIVISION_FILTER_FAILURE,
  GET_PDA_DIVISION_FILTER_RESET,

  OPEN_DELETE_PDA_DIALOG,
  CLOSE_DELETE_PDA_DIALOG,
  DELETE_PDA_START,
  DELETE_PDA_SUCCESS,
  DELETE_PDA_FAILURE,
  CLOSE_DELETE_PDA_TOAST,
  OPEN_DELETE_PDA_DIALOG_SUCCESS,
  OPEN_DELETE_PDA_DIALOG_FAILURE,
  GET_PROJECT_COLUMNS_FAILURE,
  GET_PROJECT_COLUMNS_SUCCESS,
  GET_MANAGE_COLUMNS_FAILURE,
  GET_MANAGE_COLUMNS_SUCCESS,
  GET_ALL_CONTACT_LOOKUPS_FAILURE,
  GET_ALL_CONTACT_LOOKUPS_SUCCESS,
  GET_ALL_LOOKUPS_SUCCESS,
  GET_ALL_SIMPLE_LOOKUPS_FAILURE,
  GET_ALL_SIMPLE_LOOKUPS_SUCCESS,
  RESET_COLUMNS,
  LOADING_COLUMNS
} from './constants';

const INITIAL_STATE = {
  planHdrDetails: {},
  filterHdrDetails: {},
  divisionFilterDetails: {},
  error: false,
  errorCode: '',

  open: false,
  mode: '',
  loading: false,
  alertDeleteProject: '',
  alertMessage: '',
  projectId: '',
  projectTitle: '',
  deleteDialogOpen: false,
  defaultValues: {},
  projectsGridRenderCount: 0,
  showDeleteToaster: false,
  handleAlertClose: () => { },
  routeChangeFlag: false,
  columnList: { clientColumns: [], userColumns: [] },
  manageColumnList: { clientMilestoneColumns: [], userMilestoneColumns: [] },
  allLookup: [],
  allLegacy: [],
  allContacts: [],
  isColumnLoading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PDA_LIST_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        filterHdrDetails: action.payload,
      };
    case GET_PDA_LIST_FILTER_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        errorCode: action.payload,
      };
    case GET_PDA_LIST_FILTER_RESET:
      return {
        ...state,
        loading: false,
        filterHdrDetails: {},
      };

    case RESET_COLUMNS:
      return {
        ...state,
        columnList: { clientColumns: [], userColumns: [] },
        manageColumnList: { clientMilestoneColumns: [], userMilestoneColumns: [] },
      };

    case GET_PDA_DIVISION_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        divisionFilterDetails: action.payload,
      };
    case GET_PDA_DIVISION_FILTER_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        errorCode: action.payload,
      };
    case GET_PDA_DIVISION_FILTER_RESET:
      return {
        ...state,
        loading: false,
        divisionFilterDetails: {},
      };
    case OPEN_DELETE_PDA_DIALOG:
      return {
        ...state,
        open: true,
        mode: 'delete',
        projectId: action.payload.projectId,
        documentId: action.payload.documentId,
        enabled: action.payload.enabled,
        routeChangeFlag: action.payload.routeChangeFlag,
      };
    case OPEN_DELETE_PDA_DIALOG_SUCCESS:
      return {
        ...state,
        open: true,
        mode: 'delete',
        projectId: action.payload.projectId,
        deleteDialogOpen: true,
      };
    case OPEN_DELETE_PDA_DIALOG_FAILURE:
      return {
        ...state,
        open: false,
        mode: '',
      };
    case CLOSE_DELETE_PDA_DIALOG:
      return {
        ...state,
        open: false,
        mode: '',
        projectId: '',
        deleteDialogOpen: false,
      };
    case DELETE_PDA_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case DELETE_PDA_SUCCESS:

      return {
        ...state,
        loading: false,
        alertDeleteProject: 'success',
        alertMessage: action.payload.deleteProjectMsgSuccess,
        open: false,
        deleteDialogOpen: false,
        projectsGridRenderCount: state.projectsGridRenderCount + 1,
        showDeleteToaster: true,
        handleAlertClose: action.payload.handleAlertClose,
      };
    case DELETE_PDA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
        alertDeleteProject: 'failure',
        alertMessage: action.payload.deleteProjectMsgFailure,
        open: false,
        deleteDialogOpen: false,
        showDeleteToaster: true,
        handleAlertClose: action.payload.handleAlertClose,
      };
    case CLOSE_DELETE_PDA_TOAST:
      return {
        ...state,
        alertDeleteProject: 'close',
        alertMessage: '',
        deleteDialogOpen: false,
      };
    case GET_PROJECT_COLUMNS_SUCCESS:
      return {
        ...state,
        loading: false,
        columnList: action.payload,
      };
    case GET_PROJECT_COLUMNS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
        columnList: { clientColumns: [], userColumns: [] },
        manageColumnList: { clientMilestoneColumns: [], userMilestoneColumns: [] },
      };
    case GET_MANAGE_COLUMNS_SUCCESS:
      return {
        ...state,
        loading: false,
        manageColumnList: action.payload,
      };
    case GET_MANAGE_COLUMNS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        allLegacy: action.payload,
        loading: false,
        fetchLookupsStatus: 'success',
      };
    case GET_ALL_CONTACT_LOOKUPS_SUCCESS:
      return {
        ...state,
        allContacts: action.payload,
        loading: false,
        fetchLookupsStatus: 'success',
      };
    case GET_ALL_CONTACT_LOOKUPS_FAILURE:
      return {
        ...state,
        fetchLookupsError: true,
        loading: false,
        fetchLookupsStatus: 'error',
      };
    case GET_ALL_SIMPLE_LOOKUPS_SUCCESS:
      return {
        ...state,
        allLookup: action.payload,
        loading: false,
        fetchLookupsStatus: 'success',
      };
    case GET_ALL_SIMPLE_LOOKUPS_FAILURE:
      return {
        ...state,
        fetchLookupsError: true,
        loading: false,
        fetchLookupsStatus: 'error',
      };
    case LOADING_COLUMNS:
      return {
        ...state,
        isColumnLoading: action.payload.loading
      }
    default:
      return state;
  }
};
