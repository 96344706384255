
/* eslint-disable */
import {
  GET_PDA_LIST_START,
  GET_PDA_LIST_SUCCESS,
  GET_PDA_LIST_FAILURE,
  GET_PDA_LIST_RESET,
  GET_PDA_PROJECT_DETAILS_RESET,
  GET_CPP_DOCUMENT_FAILURE,
  GET_CPP_DOCUMENT_RESET,
  GET_CPP_DOCUMENT_START,
  GET_CPP_DOCUMENT_SUCCESS,
  SAVE_CPP_DOCUMENT_START,
  SAVE_CPP_DOCUMENT_SUCCESS,
  SAVE_CPP_DOCUMENT_FAILURE,
  SAVE_CPP_DOCUMENT_RESET,
  GET_CPP_DOCUMENT_SUBMIT_START,
  GET_CPP_DOCUMENT_SUBMIT_RESET,
  GET_CPP_DOCUMENT_SUBMIT_SUCCESS,
  GET_CPP_DOCUMENT_SUBMIT_FAILURE,
  GET_CPP_DOCUMENT_REVOKE_START,
  GET_CPP_DOCUMENT_REVOKE_RESET,
  GET_CPP_DOCUMENT_REVOKE_SUCCESS,
  GET_CPP_DOCUMENT_REVOKE_FAILURE
} from './constants';
import {
  GET_DISTRIBUTION_LIST_LOOKUPS_START,
  GET_DISTRIBUTION_LIST_LOOKUPS_SUCCESS,
  GET_DISTRIBUTION_LIST_LOOKUPS_FAILURE,
  SAVE_CPP_PROJECT_DETAILS_START,
  SAVE_CPP_PROJECT_DETAILS_SUCCESS,
  SAVE_CPP_PROJECT_DETAILS_FAILURE,
  SAVE_CPP_PROJECT_DETAILS_RESET,
  GET_CUSTOMER_LOOKUPS_START,
  GET_CUSTOMER_LOOKUPS_SUCCESS,
  GET_CUSTOMER_LOOKUPS_FAILURE
} from './projectInformationConstants';
import {
  GET_CPP_RISK_LIST_START,
  GET_CPP_RISK_LIST_SUCCESS,
  GET_CPP_RISK_LIST_FAILURE,
  SAVE_CPP_RISK_LIST_SUCCESS,
  SAVE_CPP_RISK_LIST_START,
  SAVE_CPP_RISK_LIST_FAILURE,
  SAVE_CPP_RISK_LIST_RESET,
  GET_CPP_CONTACT_LIST_SUCCESS
} from './cppDocumentConstants';

const processSections = section => {
  const { htmlBlock } = section || {};
  const blockFields = {};
  let subSectionFields = {};
  htmlBlock.map(hblock => {
    return hblock.htmlBlockField.map(blckField => {
      return (blockFields[blckField.fieldId] = blckField.fieldValue);
    });
  });
  if (section.sections.length > 0) {
    section.sections.map(subsection => {
      subSectionFields = {
        ...processSections(subsection),
        ...subSectionFields
      };
    });
  }
  return { ...blockFields, ...subSectionFields };
};
const INITIAL_STATE = {
  error: false,
  errorCode: '',
  loading: false,
  riskLoading: false,
  projectDetails: {},
  distributionList: [],
  cppBasicInfoData: {
    message: '',
    messageCode: '',
    loading: false
  },
  messageCode: '',
  alert: '',
  alertMessage: '',
  documentInfo: {
    message: '',
    messageCode: '',
    loading: false
  },
  documentSave: {
    message: '',
    messageCode: '',
    loading: false
  },
  submitData: {
    message: '',
    messageCode: '',
    loading: false
  },
  revokeData: {
    message: '',
    messageCode: '',
    loading: false
  },
  documentSectionsData: [],
  documentNavSectionsData: [],
  documentSectionFieldData: [],
  customerLookupData: [],
  cppTemplateRisks: [],
  riskSaved: {
    message: '',
    messageCode: '',
    loading: false
  },
  initialSection: +sessionStorage.getItem('initialSection') ?? ''
};

// eslint-disable-next-line complexity
const cppFormRootReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_CPP_RISK_LIST_START:
      return {
        ...state,
        riskSaved: {
          message: '',
          messageCode: '',
          loading: false
        }
      };
    case SAVE_CPP_RISK_LIST_SUCCESS:
      return {
        ...state,
        riskSaved: {
          message: 'success',
          loading: false,
          messageCode: ''
        }
      };
    case SAVE_CPP_RISK_LIST_FAILURE:
      return {
        ...state,
        riskSaved: {
          ...action.payload,
          loading: false
        }
      };
    case SAVE_CPP_RISK_LIST_RESET:
      return {
        ...state,
        riskSaved: {
          message: '',
          messageCode: '',
          loading: false
        }
      };
    case GET_CPP_DOCUMENT_START:
      return {
        ...state,
        documentInfo: {
          message: '',
          messageCode: '',
          loading: true
        },
        loading: true
      };
    case GET_CPP_DOCUMENT_RESET:
      return {
        ...state,
        projectDetails: {},
        documentInfo: {
          message: '',
          messageCode: '',
          loading: false
        }
      };
    case GET_CPP_DOCUMENT_SUCCESS:
      let documentSections = action.payload.sections;
      let allBlockFields = {};
      documentSections?.map(section => {
        allBlockFields = { ...allBlockFields, ...processSections(section) };
      });

      sessionStorage.setItem(
        'initialSection',
        action.payload.navSections[0]?.id
      );

      return {
        ...state,
        documentInfo: {
          message: 'success',
          loading: false,
          messageCode: ''
        },
        projectDetails: action.payload,
        documentSectionsData: action.payload.sections,
        documentSectionFieldData: allBlockFields,
        documentNavSectionsData: action.payload.navSections,
        initialSection: action.payload.navSections[0]?.id,
        loading: false
      };
    case GET_CPP_DOCUMENT_FAILURE:
      return {
        ...state,
        documentInfo: {
          response: action.payload,
          loading: false
        },
        loading: false
      };
    case SAVE_CPP_DOCUMENT_START:
      return {
        ...state,
        documentSave: {
          message: '',
          messageCode: '',
          loading: true
        },
        loading: true
      };
    case SAVE_CPP_DOCUMENT_RESET:
      return {
        ...state,
        documentSave: {
          message: '',
          messageCode: '',
          loading: false
        }
      };
    case SAVE_CPP_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentSave: {
          message: 'success',
          loading: false,
          messageCode: ''
        },
        loading: false
      };
    case SAVE_CPP_DOCUMENT_FAILURE: {
      return {
        ...state,
        documentSave: {
          response: action.payload,
          loading: false
        },
        loading: false
      };
    }
    case GET_DISTRIBUTION_LIST_LOOKUPS_START:
      return {
        ...state,
        distributionList: []
        // loading: true
      };
    case GET_DISTRIBUTION_LIST_LOOKUPS_SUCCESS:
      return {
        ...state,
        distributionList: (action.payload || []).sort((a, b) => {
          const x = a.order,
            y = b.order;
          return x < y ? -1 : x > y ? 1 : 0;
        })
        // loading: false
      };
    case GET_DISTRIBUTION_LIST_LOOKUPS_FAILURE:
      return {
        ...state,
        distributionList: []
        // loading: false
      };
    case GET_CUSTOMER_LOOKUPS_START:
      return {
        ...state,
        customerLookupData: []
        // loading: true
      };
    case GET_CUSTOMER_LOOKUPS_SUCCESS:
      return {
        ...state,
        customerLookupData: action.payload
        // loading: false
      };
    case GET_CUSTOMER_LOOKUPS_FAILURE:
      return {
        ...state,
        customerLookupData: []
        // loading: false
      };
    case SAVE_CPP_PROJECT_DETAILS_START:
      return {
        ...state,
        loading: true,
        cppBasicInfoData: {
          message: '',
          loading: true,
          messageCode: ''
        }
      };
    case SAVE_CPP_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        cppBasicInfoData: {
          message: 'success',
          loading: false,
          messageCode: ''
        }
      };
    case SAVE_CPP_PROJECT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        cppBasicInfoData: {
          ...action.payload,
          loading: false
        }
      };
    case GET_CPP_DOCUMENT_SUBMIT_START:
      return {
        ...state,
        loading: true,
        submitData: {
          message: '',
          messageCode: '',
          loading: true
        }
      };
    case GET_CPP_DOCUMENT_SUBMIT_RESET:
      return {
        ...state,
        submitData: {
          message: '',
          messageCode: '',
          loading: false
        },
        loading: false
      };
    case GET_CPP_DOCUMENT_SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        submitData: {
          message: 'success',
          loading: false,
          messageCode: ''
        }
      };
    case GET_CPP_DOCUMENT_SUBMIT_FAILURE:
      return {
        ...state,
        loading: false,
        submitData: {
          message: 'failure',
          loading: false,
          messageCode: action.payload
        }
      };
    case GET_CPP_DOCUMENT_REVOKE_START:
      return {
        ...state,
        loading: true,
        revokeData: {
          message: '',
          messageCode: '',
          loading: true
        }
      };
    case GET_CPP_DOCUMENT_REVOKE_RESET:
      return {
        ...state,
        loading: false,
        revokeData: {
          message: '',
          loading: false,
          messageCode: ''
        }
      };
    case GET_CPP_DOCUMENT_REVOKE_SUCCESS:
      return {
        ...state,
        loading: false,
        revokeData: {
          message: 'success',
          loading: false,
          messageCode: ''
        }
      };
    case GET_CPP_DOCUMENT_REVOKE_FAILURE:
      return {
        ...state,
        loading: false,
        revokeData: {
          message: 'failure',
          loading: false,
          messageCode: action.payload
        }
      };
    case SAVE_CPP_PROJECT_DETAILS_RESET:
      return {
        ...state,
        loading: false,
        cppBasicInfoData: {
          message: '',
          loading: false,
          messageCode: ''
        }
      };
    case GET_CPP_RISK_LIST_START:
      return {
        ...state,
        cppTemplateRisks: [],
        riskLoading: true
      };
    case GET_CPP_RISK_LIST_SUCCESS:
      return {
        ...state,
        cppTemplateRisks: action.payload,
        riskLoading: false
      };
    case GET_CPP_CONTACT_LIST_SUCCESS:
      return {
        ...state,
        contactList: action.payload,
        riskLoading: false
      };
    case GET_CPP_RISK_LIST_FAILURE:
      return {
        ...state,
        cppTemplateRisks: [],
        riskLoading: false
      };
    default:
      return state;
  }
};

export default cppFormRootReducer;
