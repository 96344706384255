export const SET_LOADING_TRUE =
  'cbre-pjm-project-template-drawer/SET_LOADING_TRUE';
export const SET_LOADING_FALSE =
  'cbre-pjm-project-template-drawer/SET_LOADING_FALSE';
export const GET_TEMPLATE_LIST_FAILURE =
  'cbre-pjm-project-template-drawer/GET_TEMPLATE_LIST_FAILURE';
export const GET_TEMPLATE_LIST_START =
  'cbre-pjm-project-template-drawer/GET_TEMPLATE_LIST_START';
export const GET_TEMPLATE_LIST_RESET =
  'cbre-pjm-project-template-drawer/GET_TEMPLATE_LIST_RESET';
export const GET_TEMPLATE_LIST_SUCCESS =
  'cbre-pjm-project-template-drawer/GET_TEMPLATE_LIST_SUCCESS';
