export const FUNDING_REQUEST_DELETE_START = 'FUNDING_REQUEST_DELETE_START';
export const FUNDING_REQUEST_DELETE_SUCCESS = 'FUNDING_REQUEST_DELETE_SUCCESS';
export const FUNDING_REQUEST_DELETE_FAILED = 'FUNDING_REQUEST_DELETE_FAILED';
export const FUNDING_REQUEST_DELETE_RESET = 'FUNDING_REQUEST_DELETE_RESET';
export const FUNDING_REQUEST_GET_START = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_GET_START';
export const FUNDING_REQUEST_GET_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_GET_SUCCESS';
export const FUNDING_REQUEST_GET_FAILED = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_GET_FAILED';
export const FUNDING_REQUEST_DETAILS_GET_START = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_DETAILS_GET_START';
export const FUNDING_REQUEST_DETAILS_GET_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_DETAILS_GET_SUCCESS';
export const FUNDING_REQUEST_DETAILS_GET_FAILED = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_DETAILS_GET_FAILED';
export const FUNDING_REQUEST_DETAILS_GET_RESET = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_DETAILS_GET_RESET';

export const FUNDING_REQUEST_DETAILS_SAVE_UPDATE_START = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_DETAILS_SAVE_UPDATE_START';
export const FUNDING_REQUEST_DETAILS_SAVE_UPDATE_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_DETAILS_SAVE_UPDATE_SUCCESS';
export const FUNDING_REQUEST_DETAILS_SAVE_UPDATE_FAILED = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_DETAILS_SAVE_UPDATE_FAILED';
export const FUNDING_REQUEST_DETAILS_SAVE_UPDATE_RESET = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_DETAILS_SAVE_UPDATE_RESET';

export const FORECAST_PROJECT_DETAILS_GET_START = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FORECAST_PROJECT_DETAILS_GET_START';
export const FORECAST_PROJECT_DETAILS_GET_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FORECAST_PROJECT_DETAILS_GET_SUCCESS';
export const FORECAST_PROJECT_DETAILS_GET_FAILED = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FORECAST_PROJECT_DETAILS_GET_FAILED';

export const GET_COST_STRUCTURE_START = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/GET_COST_STRUCTURE_START';
export const GET_COST_STRUCTURE_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/GET_COST_STRUCTURE_SUCCESS';
export const GET_COST_STRUCTURE_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/GET_COST_STRUCTURE_FAILURE';

export const FUNDING_REQUEST_SUBMIT_START = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_SUBMIT_START';
export const FUNDING_REQUEST_SUBMIT_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_SUBMIT_SUCCESS';
export const FUNDING_REQUEST_SUBMIT_FAILED = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_SUBMIT_FAILED';

export const FUNDING_REQUEST_REVOKE_START = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_REVOKE_START';
export const FUNDING_REQUEST_REVOKE_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_REVOKE_SUCCESS';
export const FUNDING_REQUEST_REVOKE_FAILED = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_REVOKE_FAILED';
export const FUNDING_REQUEST_REVOKE_RESET = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_REVOKE_RESET';

export const FUNDING_REQUEST_VALIDATION_START = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_VALIDATION_START';
export const FUNDING_REQUEST_VALIDATION_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_VALIDATION_SUCCESS';
export const FUNDING_REQUEST_VALIDATION_FAILED = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_VALIDATION_FAILED';
export const FUNDING_REQUEST_VALIDATION_RESET = 'CBRE-PJM-CAPITAL-PLAN-FUNDING-REQUEST/FUNDING_REQUEST_VALIDATION_RESET';