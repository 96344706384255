export const SET_LOADING_TRUE =
  'cbre-pjm-approval-actions/SET_LOADING_TRUE';
export const SET_LOADING_FALSE =
  'cbre-pjm-approval-actions/SET_LOADING_FALSE';

export const BUDGET_REVOKE_START =
    'cbre-pjm-approval-actions/BUDGET_REVOKE_START';
export const BUDGET_REVOKE_FAILED =
    'cbre-pjm-approval-actions/BUDGET_REVOKE_FAILED';
export const BUDGET_REVOKE_SUCCESS =
    'cbre-pjm-approval-actions/BUDGET_REVOKE_SUCCESS';
export const BUDGET_REVOKE_RESET =
    'cbre-pjm-approval-actions/BUDGET_REVOKE_RESET';