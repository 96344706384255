
/* eslint-disable */
import {
  all, put, fork, call, takeEvery,
} from 'redux-saga/effects';
import apiService from 'util/apiService';
import {
  GET_CLIENT,
} from './constants';
import { addClientsToState, addClientsState } from './actions';
// import {showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess} from "actions/Auth";

const getClientsWithAPI = async (payload) => apiService.post(payload.clientListUrl, payload.apiPayload)
  // .then(response => { console.log("respinse -- ",response.data.data); response.json();})
  .then((resp) => {
    console.log('respinse OP--', resp);
    return resp;
  })
  .catch((error) => error);

function* getClientsListAPI({ payload }) {
  try {
    yield put(addClientsState());
    console.log('getClientsList from saga');
    const clientsListFromAPI = yield call(getClientsWithAPI, payload);
    // console.log("clientsListFromAPI -- ",clientsListFromAPI)
    if (clientsListFromAPI && clientsListFromAPI.loginRequired) {
      sessionStorage.removeItem('pjm-token');
    } else {
      yield put(addClientsToState(clientsListFromAPI));
    }
  } catch (error) {
    // yield put(showChatMessage(error));
  }
}

export function* getClients() {
  yield takeEvery(GET_CLIENT, getClientsListAPI);
}

export default function* rootSaga() {
  yield all([fork(getClients)]);
}
