export const OPEN_MOVE_PROGRAM_DIALOG = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROGRAM/OPEN_MOVE_PROGRAM_DIALOG';
export const OPEN_MOVE_PROGRAM_DIALOG_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROGRAM/OPEN_MOVE_PROGRAM_DIALOG_SUCCESS';
export const OPEN_MOVE_PROGRAM_DIALOG_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROGRAM/OPEN_MOVE_PROGRAM_DIALOG_FAILURE';
export const MOVE_PROGRAM_START = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROGRAM/MOVE_PROGRAM_START';
export const MOVE_PROGRAM_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROGRAM/MOVE_PROGRAM_SUCCESS';
export const MOVE_PROGRAM_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROGRAM/MOVE_PROGRAM_FAILURE';
export const CLOSE_MOVE_PROGRAM_TOAST = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROGRAM/CLOSE_MOVE_PROGRAM_TOAST';
export const CLOSE_MOVE_PROGRAM_DIALOG = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROGRAM/CLOSE_MOVE_PROGRAM_DIALOG';
export const UPDATE_PLAN_ID = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROGRAM/UPDATE_PLAN_ID';
export const UPDATE_PLAN_NAME = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROGRAM/UPDATE_PLAN_NAME';
export const ENABLE_MOVE_PROGRAM = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROGRAM/ENABLE_MOVE_PROGRAM';
