
/* eslint-disable */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import AddClient from 'modules/cbre-pjm-newclient/ducks/reducers';
import NewPlan from 'modules/cbre-pjm-capital-plan-addplan/ducks/reducers';
import AddMigration from 'modules/cbre-pjm-kahua-new-migration/ducks/reducers';
import GetKahuaDataMigrations from 'modules/cbre-pjm-kahua-data-migration/ducks/reducers';
import GetPlan from 'modules/cbre-pjm-capital-plan-details/ducks/reducers';
import GetCriteriaData from 'modules/cbre-pjm-capital-plan-criteriasetting/ducks/reducers';
import PlansList from 'modules/cbre-pjm-capital-plan-plans-list/ducks/reducers';
import TrackerProjectsList from 'modules/cbre-pjm-project-tracker-projects-list/ducks/reducers';
import JAProjectsList from 'modules/cbre-pjm-ja-projects-list/ducks/reducers';
import JABasicProjectInfo from 'modules/cbre-pjm-pds-basic-project-info/ducks/reducers';
import CostPlanInfo from 'modules/cbre-pjm-pds-cost-plan/ducks/reducers';
import PDSProjectOverview from 'modules/cbre-pjm-pds-project-overview/ducks/reducers';
import PDSProjectApprovals from 'modules/cbre-pjm-pds-approvals/ducks/reducers';
import PJMApprovals from 'modules/cbre-pjm-approvals/ducks/reducers';
import PJMDocument from 'modules/cbre-pjm-document/ducks/reducers';
import PDSProjectRisks from 'modules/cbre-pjm-pds-project-risks/ducks/reducers';
import PDSClientApprovals from 'modules/cbre-pjm-pds-client-approvals/ducks/reducers';
import capitalPlanNewProject from 'modules/cbre-pjm-capital-plan-new-project/ducks/reducers';
import ImportProject from 'modules/cbre-pjm-capital-plan-import/ducks/reducers';
import VendorProject from 'modules/cbre-pjm-fusion-vendors-projects/ducks/reducers';
import MyActions from 'modules/cbre-pjm-pds-my-actions/ducks/reducers';
import DeletePlan from 'modules/cbre-pjm-capital-plan-deleteplan/ducks/reducers';
import DeleteProgram from 'modules/cbre-pjm-capital-plan-deleteprogram/ducks/reducers';
import DeleteProject from 'modules/cbre-pjm-capital-plan-deleteproject/ducks/reducers';
import GetClients from 'modules/cbre-pjm-user-management/ducks/reducers';
import getClientCustomFields from 'modules/cbre-pjm-custom-fields-management/ducks/reducers';
import allClientsInfo from 'modules/cbre-pjm-requestaccess/ducks/reducers';
import requestAccess from 'modules/cbre-pjm-requestaccess/ducks/reducers';
import projectTrackerNewProject from 'modules/cbre-pjm-project-tracker-new-project/ducks/reducers';
import CapitalPlannerExportToKahua from 'modules/cbre-pjm-capital-planner-export-to-kahua/ducks/reducers'
import getClientsInfo from 'modules/cbre-pjm-capital-plan-addplan/ducks/reducers';
import TrackerNewProjectKahua from 'modules/cbre-pjm-project-tracker-new-project-kahua/ducks/reducers';
import TrackerAddUser from 'modules/cbre-pjm-project-tracker-new-project-add-user/ducks/reducers';
import UserManagementSearchAddUser from 'modules/cbre-pjm-user-management-search-add-user/ducks/reducers';
import CreateProgram from 'modules/cbre-pjm-create-program/ducks/reducers';
import HierarchyAccessSearchAddUserTeam from 'modules/cbre-pjm-hierarchy-access-search-add-user-team/ducks/reducers';
import ManageTeams from 'modules/cbre-pjm-manage-teams/ducks/reducers';
import ManageTeamUsers from 'modules/cbre-pjm-manage-team-users/ducks/reducers';
import EmailAuthentication from 'modules/cbre-pjm-email-authentication/ducks/reducers';
import estimatorClientsListInfo from '../../cbre-pjm-estimator-clients/ducks/reducers';
import clientsListInfo from '../../cbre-pjm-cp-clients/ducks/reducers';
import Common from './Common';
import Client from './Client';
import Auth from './Auth';
import Settings from './Settings';
import EstimatorSpacePlan from "modules/cbre-pjm-estimator-space-plan/ducks/reducers";
import EstimatorNewProject from "modules/cbre-pjm-estimator-new-project/ducks/reducers";
import EstimatorProjectsList from "modules/cbre-pjm-estimator-projects-list/ducks/reducers";
import PDSFormRoot from "modules/cbre-pjm-pds-form-root/ducks/reducers";
import AdminDataImport from 'modules/cbre-pjm-admin-data-import/ducks/reducers';
import DocumentList from "components/FileUploadComponent/ducks/reducers";
import DocumentFileUploadList from "components/DocumentFileUpload/ducks/reducers";
import CPPProjectsList from 'modules/cbre-pjm-cpp-projects-list/ducks/reducers';
import CPPFormRoot from "modules/cbre-pjm-cpp-form-root/ducks/reducers";
import MoveProject from 'modules/cbre-pjm-capital-plan-moveproject/ducks/reducers';
import MoveProgram from 'modules/cbre-pjm-capital-plan-moveprogram/ducks/reducers';
import ClientDataFields from 'modules/cbre-pjm-capital-plan-client-data-fields/ducks/reducers';
import FundingRequest from 'modules/cbre-pjm-capital-plan-funding-request/ducks/reducers';
import ApprovalStatusList from 'modules/cbre-pjm-approvals-status-list/ducks/reducers';

import PMClientsList from 'modules/cbre-pjm-pm-clients-list/ducks/reducers';
import PMProgramsList from 'modules/cbre-pjm-pm-program-list/ducks/reducers';
import PMProjectsList from 'modules/cbre-pjm-pm-projects-list/ducks/reducers';
import projectTagging from 'modules/cbre-pjm-project-tagging/ducks/reducers';
import forecast from 'modules/cbre-pjm-capital-plan-forecasting/ducks/reducers';
import forecastPL from 'modules/principal-lite/cbre-pjm-principal-lite-project-forecast/src/ducks/reducers';
import ManageReference from 'modules/cbre-pjm-capital-plan-manage-references/ducks/reducers';
import ApprovalConfigAddGroup from 'modules/cbre-pjm-approval-configuration-add-group/ducks/reducers';
import ApprovalConfig from 'modules/cbre-pjm-approval-configuration/ducks/reducers';
import AdminRootDetails from 'modules/cbre-pjm-admin-root-details/ducks/reducers';
import ApprovalsGroup from 'modules/cbre-pjm-approvals-group/ducks/reducers';
import GridList from 'modules/cbre-pjm-grid/ducks/reducers';

import PrincipalLiteCPPDocument from 'modules/principal-lite/cbre-pjm-principal-lite-cpp-document/src/ducks/reducers';
import PrincipalLiteProjectRoot from 'modules/principal-lite/cbre-pjm-principal-lite-project-root/src/ducks/reducers';
import PrincipalLiteRoot from 'modules/principal-lite/cbre-pjm-principal-lite-root/src/ducks/reducers';
import PrincipalLiteProjectCostPlan from 'modules/principal-lite/cbre-pjm-principal-lite-project-cost-plan/src/ducks/reducers';
import PrincipalLiteNewProject from 'modules/principal-lite/cbre-pjm-principal-lite-new-project/src/ducks/reducers';
import PrincipalLiteCheckList from 'modules/principal-lite/cbre-pjm-principal-lite-project-checklist/src/ducks/reducers';
import PrincipalLiteClientBilling from 'modules/principal-lite/cbre-pjm-principal-lite-client-billing/src/ducks/reducers';
import ProjectOverviewCost from 'modules/cbre-pjm-project-cost-overview/src/ducks/reducers';
import approvalHistory from 'modules/common/cbre-pjm-approval-history/src/ducks/reducers';
import PrincipalLiteMoveProject from 'modules/principal-lite/cbre-pjm-principal-lite-moveproject/src/ducks/reducers';
import PrincipalLiteAddUser from 'modules/principal-lite/cbre-pjm-principle-lite-add-user/ducks/reducers';
import PrincipalLiteBudgetManagement from 'modules/principal-lite/cbre-pjm-principal-lite-budget-management/src/ducks/reducers'

import gridLayoutReducer from 'modules/principal-lite/cbre-pjm-principal-lite-grid-layout/src/ducks/reducers';
import recentProjectsReducer from 'modules/principal-lite/cbre-pjm-principal-lite-widget-content/src/RecentProjects/ducks/reducers';
import outstandingTasksReducer from 'modules/principal-lite/cbre-pjm-principal-lite-widget-actions/src/Myactions/ducks/reducers';
import widgetGraphReducer from 'modules/principal-lite/cbre-pjm-principal-lite-widget-graphs/src/graphs/ducks/reducers';
import widgetFilterReducer from 'modules/principal-lite/cbre-pjm-principal-lite-widget-filters/src/filters/ducks/reducers';
import PrincipalLiteProjectOverview from 'modules/principal-lite/cbre-pjm-principal-lite-financial-overview/src/ducks/reducers';
import inputPlanReducer from 'modules/principal-lite/cbre-pjm-principal-lite-mmm-input/src/ducks/reducers';
import widgetSummaryReducer from 'modules/principal-lite/cbre-pjm-principal-lite-widget-summary/src/Myactions/ducks/reducers';
import approvalActionsReducer from 'modules/cbre-pjm-approval-actions/src/ducks/reducers';
import myBuyRequestListGridReducer from 'modules/principal-lite/cbre-pjm-principal-lite-mybuy-requests/src/ducks/reducers';
import ProjectTemplateDrawerReducer from 'modules/cbre-pjm-project-template-drawer/src/ducks/reducers';


export default (history) => ({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  commonClients: Client,
  common: Common,
  client: Common,
  clientsListInfo,
  estimatorClientsListInfo,
  addClient: AddClient,
  getPlan: GetPlan,
  getCriteriaData: GetCriteriaData,
  newPlan: NewPlan,
  plansList: PlansList,
  programList: PMProgramsList,
  trackerProjectsList: TrackerProjectsList,
  jAProjectsList: JAProjectsList,
  jAProjectsListData: JAProjectsList,
  'cbre-pjm-pds-basic-project-info': JABasicProjectInfo,
  'cbre-pjm-pds-cost-plan': CostPlanInfo,
  'cbre-pjm-pds-project-overview': PDSProjectOverview,
  'cbre-pjm-pds-project-risks': PDSProjectRisks,
  'cbre-pjm-pds-project-approvals': PDSProjectApprovals,
  'cbre-pjm-pds-client-approvals': PDSClientApprovals,
  'cbre-pjm-approvals': PJMApprovals,
  'cbre-pjm-document': PJMDocument,
  'cbre-pjm-pm-clients-list': PMClientsList,
  'cbre-pjm-pm-program-list': PMProgramsList,
  'cbrePjmPmProjectsList': PMProjectsList,
  capitalPlanNewProject,
  projectTrackerNewProject,
  deletePlan: DeletePlan,
  deleteProgram: DeleteProgram,
  deleteProject: DeleteProject,
  allClientsInfo,
  requestAccess,
  'cbre-pjm-capital-plan-import': ImportProject,
  'cbre-pjm-fusion-vendors-projects': VendorProject,
  'cbre-pjm-pds-my-actions': MyActions,
  'cbre-pjm-estimator-space-plan': EstimatorSpacePlan,
  'cbre-pjm-capital-planner-export-to-kahua': CapitalPlannerExportToKahua,
  trackerNewProjectKahua: TrackerNewProjectKahua,
  getClientsInfo,
  addUser: TrackerAddUser,
  getClients: GetClients,
  getClientCustomFields,
  searchAddUser: UserManagementSearchAddUser,
  hierarchyAddUserTeam: HierarchyAccessSearchAddUserTeam,
  createProgram: CreateProgram,
  manageTeams: ManageTeams,
  'cbre-pjm-manage-team-users': ManageTeamUsers,
  'cbre-pjm-email-authentication': EmailAuthentication,
  'cbre-pjm-estimator-new-project': EstimatorNewProject,
  'cbre-pjm-estimator-projects-list': EstimatorProjectsList,
  'cbre-pjm-pds-form-root': PDSFormRoot,
  'cbre-pjm-admin-data-import': AdminDataImport,
  'cbre-pjm-documents': DocumentList,
  'cbre-pjm-documents-file-upload': DocumentFileUploadList,
  'cbre-pjm-cpp-list': CPPProjectsList,
  'cbre-pjm-cpp-form-root': CPPFormRoot,
  newMigration: AddMigration,
  kahuaMigrations: GetKahuaDataMigrations,
  moveProject: MoveProject,
  moveProgram: MoveProgram,
  clientDataFields: ClientDataFields,
  projectTagging: projectTagging,
  'cbre-pjm-capital-plan-funding-request': FundingRequest,
  'cbre-pjm-approvals-status-list': ApprovalStatusList,
  'cbre-pjm-capital-plan-forecasting': forecast,
  'cbre-pjm-principal-lite-project-forecast': forecastPL,
  'cbre-pjm-capital-plan-manage-references': ManageReference,
  'cbre-pjm-approval-config-add-group': ApprovalConfigAddGroup,
  'cbre-pjm-approval-config': ApprovalConfig,
  'cbre-pjm-admin-root-details': AdminRootDetails,
  'cbre-pjm-approvals-group': ApprovalsGroup,
  'cbre-pjm-grid': GridList,
  'cbre-pjm-principal-lite-project-root': PrincipalLiteProjectRoot,
  'cbre-pjm-principal-lite-root': PrincipalLiteRoot,
  'cbre-pjm-principal-lite-new-project': PrincipalLiteNewProject,
  'cbre-pjm-principal-lite-project-checklist': PrincipalLiteCheckList,
  'cbre-pjm-principal-lite-project-cost-plan': PrincipalLiteProjectCostPlan,
  'cbre-pjm-principal-lite-project-budget-management': PrincipalLiteBudgetManagement,
  'cbre-pjm-principal-lite-client-billing': PrincipalLiteClientBilling,
  'cbre-pjm-project-cost-overview': ProjectOverviewCost,
  'cbre-pjm-approval-history': approvalHistory,
  'cbre-pjm-principal-lite-cpp-document': PrincipalLiteCPPDocument,
  'cbre-pjm-principal-lite-moveproject': PrincipalLiteMoveProject,
  'cbre-pjm-principal-lite-financial-overview': PrincipalLiteProjectOverview,
  'cbre-pjm-principle-lite-add-user': PrincipalLiteAddUser,
  'cbre-pjm-approval-actions': approvalActionsReducer,
  'cbre-pjm-project-template-drawer': ProjectTemplateDrawerReducer,
  principalLiteAddUser: PrincipalLiteAddUser,
  gridLayoutReducer,
  recentProjectsReducer,
  outstandingTasksReducer,
  widgetSummaryReducer,
  widgetGraphReducer,
  widgetFilterReducer,
  inputPlanReducer,
  'myBuyRequests': myBuyRequestListGridReducer,
});
