
/* eslint-disable */
import {
  INITIATE_IMPORT,
  RESET_INITIATE_IMPORT,
  INITIATE_IMPORT_FAILURE,
  INITIATE_IMPORT_SUCCESS,
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  GET_IMPORT_TYPE_SUCCESS,
  GET_IMPORT_TYPE_FAILURE,
  IMPORT_DATA_START,
  IMPORT_DATA_SUCCESS,
  IMPORT_DATA_FAILURE,
  GET_IMPORTS_DETAILS_SUCCESS,
  GET_IMPORTS_DETAILS_FAILURE,
  GET_IMPORTS_LIST_SUCCESS,
  GET_IMPORTS_LIST_START,
  GET_IMPORTS_LIST_FAILURE,
  SET_SELECTED_JOB_ID,
  GET_IMPORTS_DETAILS_START,
} from './constants';

const INITIAL_STATE = {
  loading: true,
  loadingSidebar: false,
  loadingImportDetails: false,
  importInitiated: false,
  getAllImportTypeList: [],
  totalRecords: 0,
  importJobList: [],
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INITIATE_IMPORT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_FALSE:
        return {
          ...state,
          loading: false,
        };  
    case GET_IMPORT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllImportTypeList: action.payload,
      }
    case GET_IMPORT_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      }
    case IMPORT_DATA_START:
      return {
        ...state,
        loading: true,
      }
    case IMPORT_DATA_SUCCESS:
      return {
        ...state,
      }
    case IMPORT_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      }
    case GET_IMPORTS_LIST_START:
      return {
        ...state,
        loadingSidebar: true,
      };
    case GET_IMPORTS_LIST_SUCCESS:
      let { recordList, totalRecords } = action.payload;
      return {
        ...state,
        currentImportDetails: recordList && recordList.length === 0 ? {} : state.currentImportDetails,
        importJobList: recordList,
        totalRecords,
        loadingSidebar: false,
      };
    case GET_IMPORTS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        loadingSidebar: false,
      };
    case GET_IMPORTS_DETAILS_START:
      return {
        ...state,
        loadingImportDetails: true,
      };
    case GET_IMPORTS_DETAILS_SUCCESS:
      return {
        ...state,
        loadingImportDetails: false,
        currentImportDetails: { ...action.payload },
      };
    case GET_IMPORTS_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case SET_SELECTED_JOB_ID:
      return {
        ...state,
        selectedJobId: action.payload,
      };
    case INITIATE_IMPORT:
      return {
        ...state, 
        importInitiated: true
      }
    case RESET_INITIATE_IMPORT: 
      return {
        ...state,
        loading: false,
        importInitiated: false
      }
    default:
      return state;
  }
};
