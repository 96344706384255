import {
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  GET_TEMPLATE_LIST_SUCCESS,
  GET_TEMPLATE_LIST_START,
  GET_TEMPLATE_LIST_FAILURE,
} from './constants';

const INITIAL_STATE = {
  loading: false,
  templateList: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case GET_TEMPLATE_LIST_START:
      return {
        ...state,
        loading: true,
        templateList: [],
      };
    case GET_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        templateList: action.payload,
      };
    case GET_TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        templateList: action?.payload,
      };
    default:
      return state;
  }
};
