// risk constants

export const GET_CPP_RISK_LIST_START = 'CBRE-PJM-CPP-FORM-ROOT/GET_CPP_RISK_LIST_START';
export const GET_CPP_RISK_LIST_SUCCESS = 'CBRE-PJM-CPP-FORM-ROOT/GET_CPP_RISK_LIST_SUCCESS';
export const GET_CPP_CONTACT_LIST_SUCCESS = 'CBRE-PJM-CPP-FORM-ROOT/GET_CPP_CONTACT_LIST_SUCCESS';
export const GET_CPP_RISK_LIST_FAILURE = 'CBRE-PJM-CPP-FORM-ROOT/GET_CPP_RISK_LIST_FAILURE';

export const SAVE_CPP_RISK_LIST_START = 'CBRE-PJM-CPP-FORM-ROOT/SAVE_CPP_RISK_LIST_START';
export const SAVE_CPP_RISK_LIST_SUCCESS = 'CBRE-PJM-CPP-FORM-ROOT/SAVE_CPP_RISK_LIST_SUCCESS';
export const SAVE_CPP_RISK_LIST_FAILURE = 'CBRE-PJM-CPP-FORM-ROOT/SAVE_CPP_RISK_LIST_FAILURE';
export const SAVE_CPP_RISK_LIST_RESET = 'CBRE-PJM-CPP-FORM-ROOT/SAVE_CPP_RISK_LIST_RESET';
