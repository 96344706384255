
/* eslint-disable */
import {
  GET_CRITERIA_DATA_START,
  GET_CRITERIA_DATA_SUCCESS,
  GET_CRITERIA_DATA_FAILURE,
  GET_CRITERIA_DATA_RESET,
  POST_CRITERIA_DATA_START,
  POST_CRITERIA_DATA_SUCCESS,
  POST_CRITERIA_DATA_FAILURE,
  RESET_CRITERIA_DATA_START,
  RESET_CRITERIA_DATA_SUCCESS,
  RESET_CRITERIA_DATA_FAILURE,
} from './constants';

const INITIAL_STATE = {
  criteriaSettingsDetails: {},
  saveCriteriaDetails: {},
  error: false,
  errorCode: '',
  loading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CRITERIA_DATA_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case GET_CRITERIA_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        criteriaSettingsDetails: action.payload,
      };
    case GET_CRITERIA_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_CRITERIA_DATA_RESET:
      return {
        ...state,
        loading: false,
        criteriaSettingsDetails: {},
      };
    case POST_CRITERIA_DATA_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case POST_CRITERIA_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        saveCriteriaDetails: action.payload,
      };
    case POST_CRITERIA_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
      case RESET_CRITERIA_DATA_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case RESET_CRITERIA_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        criteriaSettingsDetails: action.payload,
      };
    case RESET_CRITERIA_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    default:
      return state;
  }
};
