export const GET_CLIENT_DATA_FIELDS_START = 'cbre-pjm-client-data-fields/GET_CLIENT_DATA_FIELDS_START';
export const GET_CLIENT_DATA_FIELDS_SUCCESS = 'cbre-pjm-client-data-fields/GET_CLIENT_DATA_FIELDS_SUCCESS';
export const GET_CLIENT_DATA_FIELDS_FAILURE = 'cbre-pjm-client-data-fields/GET_CLIENT_DATA_FIELDS_FAILURE';
export const SUBMIT_CLIENT_DATA_FIELDS_START = 'cbre-pjm-client-data-fields/SUBMIT_CLIENT_DATA_FIELDS_START';
export const SUBMIT_CLIENT_DATA_FIELDS_SUCCESS = 'cbre-pjm-client-data-fields/SUBMIT_CLIENT_DATA_FIELDS_SUCCESS';
export const SUBMIT_CLIENT_DATA_FIELDS_FAILURE = 'cbre-pjm-client-data-fields/SUBMIT_CLIENT_DATA_FIELDS_FAILURE';
export const GET_ALL_LOOKUPS_SUCCESS = 'cbre-pjm-client-data-fields/GET_ALL_LOOKUPS_SUCCESS';
export const GET_ALL_LOOKUPS_FAILURE = 'cbre-pjm-client-data-fields/GET_ALL_LOOKUPS_FAILURE';
export const GET_ALL_LOOKUPS_START = 'cbre-pjm-client-data-fields/GET_ALL_LOOKUPS_START';
export const SET_DEFAULT_VALUES = 'cbre-pjm-client-data-fields/SET_DEFAULT_VALUES';
export const RESET = 'cbre-pjm-client-data-fields/RESET';
export const RESET_API_MSG = 'cbre-pjm-client-data-fields/RESET_API_MSG';
