
/* eslint-disable */
import { UPDATE_CP_TAB_COUNT } from './constants';

const INIT_STATE = {
  key: 'plans',
  error: false,
  errorCode: '',
  loading: true,
  planListCount: 0
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_CP_TAB_COUNT:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
