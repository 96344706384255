
/* eslint-disable */
import {
  OPEN_DELETE_PLAN_DIALOG,
  CLOSE_DELETE_PLAN_DIALOG,
  DELETE_PLAN_START,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_FAILURE,
  CLOSE_DELETE_PLAN_TOAST,
  OPEN_DELETE_PLAN_DIALOG_SUCCESS,
  OPEN_DELETE_PLAN_DIALOG_FAILURE,

} from '../constants';

const INIT_STATE = {
  open: false,
  mode: '',
  loading: false,
  error: false,
  errorCode: '',
  alert: '',
  alertMessage: '',
  planId: '',
  planName: '',
  deleteDialogOpen: false,
  defaultValues: {},
  plansGridRenderCount: 0,
  showDeleteToaster: false,
  handleAlertClose: () => {},
  routeChangeFlag: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPEN_DELETE_PLAN_DIALOG:
      return {
        ...state,
        open: true,
        mode: 'delete',
        planId: action.payload.planId,
        enabled: action.payload.enabled,
        routeChangeFlag: action.payload.routeChangeFlag,
      };
    case OPEN_DELETE_PLAN_DIALOG_SUCCESS:
      return {
        ...state,
        open: true,
        mode: 'delete',
        planId: action.payload.planId,
        deleteDialogOpen: true,
      };
    case OPEN_DELETE_PLAN_DIALOG_FAILURE:
      return {
        ...state,
        open: false,
        mode: '',
      };
    case CLOSE_DELETE_PLAN_DIALOG:
      return {
        ...state,
        open: false,
        mode: '',
        planId: '',
        deleteDialogOpen: false,
      };
    case DELETE_PLAN_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case DELETE_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        alert: 'success',
        alertMessage: 'Plan Deleted Successfully !',
        // planName: action.payload.planName,
        open: false,
        deleteDialogOpen: false,
        plansGridRenderCount: state.plansGridRenderCount + 1,
        showDeleteToaster: true,
        handleAlertClose: action.payload.handleAlertClose,
        // mode: ''
      };
    case DELETE_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
        handleAlertClose: action.payload.handleAlertClose,
      };
    case CLOSE_DELETE_PLAN_TOAST:
      return {
        ...state,
        alert: 'close',
        alertMessage: '',
        deleteDialogOpen: false,
      };
    default:
      return state;
  }
};
