
/* eslint-disable */
import {
  OPEN_ADD_USER_DIALOG,
  CLOSE_ADD_USER_DIALOG,
  DISABLE_ADD_USER,
  RESET_ADD_USER,
  START_ADD_USER,
  FAILURE_ADD_USER,
  SUCCESS_ADD_USER,
  DUPLICATE_ADD_USER,
  CLOSE_TOAST_ADD_USER,
} from './constants';

const INITIAL_STATE = {
  open: false,
  loading: false,
  error: false,
  messageCode: '',
  alert: '',
  alertMessage: '',
  closeDialog: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_ADD_USER_DIALOG:
      return {
        ...state,
        open: true,
      };
    case CLOSE_ADD_USER_DIALOG:
      return {
        ...state,
        open: false,
        closeDialog: true,
        ...action.payload,
      };
    case DISABLE_ADD_USER:
      return {
        ...state,
        open: false,
      };
    case RESET_ADD_USER:
      return {
        ...state,
        error: false,
        messageCode: '',
        closeDialog: false,
      };
    case START_ADD_USER:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };

    case SUCCESS_ADD_USER:
      return {
        ...state,
        loading: false,
        open: false,
        alert: 'success',
        closeDialog: true,
        ...action.payload,

      };
    case FAILURE_ADD_USER:
      return {
        ...state,
        loading: false,
        error: true,

      };
    case DUPLICATE_ADD_USER:
      return {
        ...state,
        loading: false,
        error: true,
        ...action.payload,

      };
    case CLOSE_TOAST_ADD_USER:
      return {
        ...state,
        alert: 'close',
        alertMessage: '',
        ...action.payload,

      };
    default:
      return state;
  }
};
