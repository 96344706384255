import React, { memo, useContext, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { UserHierarchyAccessContext } from 'containers/Services/UserHierarchyAccessProvider';
import { setRedirectPath } from './sessionStorageUtils';
import currentProjectIdSingleton from './currentProjectIdSingleton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';

const withSegmentSelection = (WrappedComponent) => {
  const MemoizedComponent = (props) => {
    const { projectId } = useParams();
    const history = useHistory();
    const configInfo = useSelector((state) => state.config);
    const pdsApiUrl = `${configInfo.client.pdsApiUrl}project/${projectId}`;
    const [isLoading, setIsLoading] = useState(true);
    const [hasProjectAccess, setHasProjectAccess] = useState(true);
    const { segmentArray, isAccessLoading, handleSegmentChange, handleClientChange } = useContext(
      UserHierarchyAccessContext,
    );

    const handleDialogClose = () => {
      history.push('/dashboard');
    };

    const updateSegmentSelection = (segmentId) => {
      if (segmentId && segmentArray.length > 0) {
        const activeSegment = segmentArray.find(segment => segment.id === segmentId);
        sessionStorage.setItem('selectedSegment', JSON.stringify(activeSegment));
        const updatedSegments = segmentArray.map((segment) => {
          if (segment.id === segmentId) {
            return { ...segment, isSelected: true };
          } else {
            return { ...segment, isSelected: false };
          }
        });
        handleSegmentChange(updatedSegments);
      }
    };

    const updateClientSelection = (client) => {
      const { clientId, clientName } = client;
      if (clientId && clientName) {
        const updatedClient = { clientId, clientName };
        sessionStorage.setItem('selectedClient', JSON.stringify(updatedClient));
        handleClientChange(updatedClient);
      }
    };

    useEffect(() => {
      const currentProjectId = currentProjectIdSingleton.getCurrentProjectId();
      const selectedSegment = sessionStorage.getItem('selectedSegment');

      if (selectedSegment || !projectId || (currentProjectId && currentProjectId === projectId)) {
        setIsLoading(false);
        return;
      }

      const fetchProjectDetails = async () => {
        setIsLoading(true);
        try {
          const response = await Axios.get(pdsApiUrl, {
            headers: {
              'x-authorization': sessionStorage.getItem('pjm-token'),
              'x-locale': localStorage.getItem('locale') || 'en_US',
              AzureToken: sessionStorage.getItem('accessToken'),
            },
          });
          const status = response?.data?.status;
          if (status === 'Failed') {
            setHasProjectAccess(false);
          } else if(status === 'Success') {
            const projectDetails = response?.data?.data;
            if(projectDetails) {
              setHasProjectAccess(true);
            }
            const segmentId = projectDetails?.projectPipeLine?.pjmSegmentId;
            const client = projectDetails?.project?.client;
            currentProjectIdSingleton.setCurrentProjectId(projectId);
            updateSegmentSelection(segmentId);
            if(client) {
              updateClientSelection(client);
            }
          }
          setIsLoading(false);
        } catch (error) {
          console.error('Failed to fetch project details', error);
          setIsLoading(false);
        }
      };
      
      if(projectId && projectId !== currentProjectId && !isAccessLoading) {
        setIsLoading(true);
        currentProjectIdSingleton.setCurrentProjectId(projectId);
        fetchProjectDetails();
      }
      
    }, [pdsApiUrl, projectId, isAccessLoading, segmentArray]);


    if (isLoading || isAccessLoading) {
      return (
        <Backdrop
          open={isLoading || isAccessLoading}
          className="main-backdrop-custom"
        >
          <CircularProgress className="main-loader-custom" />
        </Backdrop>
      );
    }

    if (!hasProjectAccess) {
      return (
        <Dialog
          open={true}
          onClose={handleDialogClose}
          style={{ zIndex: '2300' }}
        >
          <DialogTitle onClose={handleDialogClose}>
            <IntlMessages id="PDA.segment.noDataHeading" />
          </DialogTitle>
          <DialogContent>
            <IntlMessages id="PDA.segment.noProjectViewAccess" />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDialogClose}
            >
              <span> {'OK'}</span>
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    
    if (
      segmentArray?.length === 0 ||
      !sessionStorage.getItem('selectedSegment')
    ) {
      const redirectPath = props.location.pathname + props.location.search;
      setRedirectPath(redirectPath);
      
      return <Redirect to="/segment-selector" />;
    }

    if (sessionStorage.getItem('selectedSegment') && segmentArray.length > 0) {
      return <WrappedComponent {...props} />;
    }

  };

  return memo(MemoizedComponent);

};

export default withSegmentSelection;
