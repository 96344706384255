
/* eslint-disable */
import {
  UPDATE_CP_TAB_COUNT,
  GET_CLIENT_RESET,
  GET_CLIENT_FAILURE,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_START,
  GET_ALL_LOOKUPS_SUCCESS,
  GET_HIERARCHY_SUCCESS,
  GET_HIERARCHY,
  GET_HIERARCHY_FAILURE,
  SET_APPROVAL_FLOW_TYPE,
  SET_GROUP_NAME,
  SET_VALID_PARALLEL_FLOW_TYPE,
  SET_VALID_GROUP_SEQUENTIAL_FLOW_TYPE
} from './constants';

const INIT_STATE = {
  module: "cbre-pjm-approval-configuration-add-group.",
  approvalFlowTypeData:[],
  parallelFlowTypeFormData:[],
  groupSequentialFlowTypeFormData: [],
  initialGroupSequenceFlowTypeFormData: [],
  parallelFlowTypeRoles:[],
  approvalLevelList:[],
  selectedApprovalFlowType:'',
  groupName:'',
  rolesList:[],
  contactList:[],
  isValidFlowType:false

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_APPROVAL_FLOW_TYPE:
      return {
        ...state,
        selectedApprovalFlowType: action.payload,
      };
    case SET_GROUP_NAME:
      return {
        ...state,
        groupName: action.payload,
      };
    case SET_VALID_PARALLEL_FLOW_TYPE:
      return {
        ...state,
        isValidFlowType: action.payload.isValid,
        parallelFlowTypeFormData: action.payload.fields,
      };
    case SET_VALID_GROUP_SEQUENTIAL_FLOW_TYPE:
      return {
        ...state,
        isValidFlowType: action.payload.isValid,
        groupSequentialFlowTypeFormData: action.payload.fields,
      };
    default:
      return state;
  }
};
