export const PROGRAM_MANAGEMENT_ROUTE = 'programmanagement';
export const PROGRAM_MANAGEMENT_CLIENTS_ROUTE = 'programmanagement/clients';
export const PROGRAM_MANAGEMENT_PROGRAMS_ROUTE = 'programmanagement/:clientId/programs';
export const PROGRAM_MANAGEMENT_PROJECTS_ROUTE = 'programmanagement/projectDetails/:clientId/:clientName/:planId/:planName';
export const DASHBOARD_ROUTE = 'dashboard';

export const getProgramManagementRoute = () => `/${PROGRAM_MANAGEMENT_ROUTE}`;
export const getProgramManagmentClientsRoute = () => `/${PROGRAM_MANAGEMENT_CLIENTS_ROUTE}`;
export const getProgramManagementProgramsRoute = (clientId) => `/${PROGRAM_MANAGEMENT_ROUTE}/${clientId}/programs`;
export const getProgramManagementProjectRoutes = (clientId, planId, clientName, planName) => `/${PROGRAM_MANAGEMENT_ROUTE}/projectDetails/${clientId}/${clientName}/${planId}/projects/${planName}`;
export const getDashboardRoute = () => `/${DASHBOARD_ROUTE}`;
