export const CLIENT_APPROVALS_SAVE_START = 'CLIENT_APPROVALS_SAVE_START';
export const CLIENT_APPROVALS_SAVE_SUCCESS = 'CLIENT_APPROVALS_SAVE_SUCCESS';
export const CLIENT_APPROVALS_SAVE_FAILURE = 'CLIENT_APPROVALS_SAVE_FAILURE';
export const CLIENT_APPROVALS_RESET = 'CLIENT_APPROVALS_RESET';
export const SYNC_KAHUA_SAVE_START = 'SYNC_KAHUA_SAVE_START';
export const SYNC_KAHUA_SAVE_SUCCESS = 'SYNC_KAHUA_SAVE_SUCCESS';
export const SYNC_KAHUA_SAVE_FAILURE = 'SYNC_KAHUA_SAVE_FAILURE';
export const SYNC_KAHUA_RESET = 'SYNC_KAHUA_RESET';
export const GET_KAHUA_STATUS_START = 'GET_KAHUA_STATUS_START';
export const GET_KAHUA_STATUS_SUCCESS = 'GET_KAHUA_STATUS_SUCCESS';
export const GET_KAHUA_STATUS_FAILURE = 'GET_KAHUA_STATUS_FAILURE';
