export const OPEN_SEARCH_ADD_USER_TEAM_DIALOG = 'CBRE-PJM-HIERARCHY-ACCESS-ADD-USER/OPEN_SEARCH_ADD_USER_TEAM_DIALOG';
export const CLOSE_SEARCH_ADD_USER_TEAM_DIALOG = 'CBRE-PJM-HIERARCHY-ACCESS-ADD-USER/CLOSE_SEARCH_ADD_USER_TEAM_DIALOG';
export const RESET_SEARCH_ADD_USER_TEAM = 'CBRE-PJM-HIERARCHY-ACCESS-ADD-USER/RESET_SEARCH_ADD_USER_TEAM';

export const DISABLE_SEARCH_ADD_USER_TEAM = 'CBRE-PJM-HIERARCHY-ACCESS-ADD-USER/DISABLE_SEARCH_ADD_USER_TEAM';
export const START_SEARCH_ADD_USER_TEAM = 'CBRE-PJM-HIERARCHY-ACCESS-ADD-USER/START_SEARCH_ADD_USER_TEAM';

export const SUCCESS_SEARCH_ADD_USER_TEAM = 'CBRE-PJM-HIERARCHY-ACCESS-ADD-USER/SUCCESS_SEARCH_ADD_USER_TEAM';
export const FAILURE_SEARCH_ADD_USER_TEAM = 'CBRE-PJM-HIERARCHY-ACCESS-ADD-USER/FAILURE_SEARCH_ADD_USER_TEAM';
export const DUPLICATE_SEARCH_ADD_USER_TEAM = 'CBRE-PJM-HIERARCHY-ACCESS-ADD-USER/DUPLICATE_SEARCH_ADD_USER_TEAM';
export const CLOSE_TOAST_SEARCH_ADD_USER_TEAM = 'CBRE-PJM-HIERARCHY-ACCESS-ADD-USER/CLOSE_TOAST_SEARCH_ADD_USER_TEAM';
export const FETCH_ROLES_SUCCESS = 'CBRE-PJM-HIERARCHY-ACCESS-ADD-USER/FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILURE = 'CBRE-PJM-HIERARCHY-ACCESS-ADD-USER/FETCH_ROLES_FAILURE';
export const UPDATE_ROLE_SUCCESS = 'CBRE-PJM-HIERARCHY-ACCESS-ADD-USER/UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'CBRE-PJM-HIERARCHY-ACCESS-ADD-USER/UPDATE_ROLE_FAILURE';
