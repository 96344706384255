export const OPEN_MOVE_PROJECT_DIALOG = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROJECT/OPEN_MOVE_PROJECT_DIALOG';
export const OPEN_MOVE_PROJECT_DIALOG_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROJECT/OPEN_MOVE_PROJECT_DIALOG_SUCCESS';
export const OPEN_MOVE_PROJECT_DIALOG_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROJECT/OPEN_MOVE_PROJECT_DIALOG_FAILURE';
export const MOVE_PROJECT_START = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROJECT/MOVE_PROJECT_START';
export const MOVE_PROJECT_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROJECT/MOVE_PROJECT_SUCCESS';
export const MOVE_PROJECT_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROJECT/MOVE_PROJECT_FAILURE';
export const CLOSE_MOVE_PROJECT_TOAST = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROJECT/CLOSE_MOVE_PROJECT_TOAST';
export const CLOSE_MOVE_PROJECT_DIALOG = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROJECT/CLOSE_MOVE_PROJECT_DIALOG';
export const UPDATE_PLAN_ID = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROJECT/UPDATE_PLAN_ID';
export const UPDATE_PLAN_NAME = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROJECT/UPDATE_PLAN_NAME';
export const ENABLE_MOVE_PROJECT = 'CBRE-PJM-CAPITAL-PLAN-MOVEPROJECT/ENABLE_MOVE_PROJECT';
