import {
  FORECAST_DETAILS_GET_SUCCESS,
  FORECAST_DETAILS_GET_START,
  FORECAST_DETAILS_GET_FAILED,
  FORECAST_DETAILS_GET_RESET,
  FORECAST_DETAILS_SUBMIT_SUCCESS,
  FORECAST_DETAILS_SUBMIT_RESET,
} from './constants';

const INITIAL_STATE = {
  error: false,
  errorCode: '',
  loading: false,
  forecastDetails: {forecasts:[]},
  workPackageData:[],
  forecastDetailsInfo: {
    message: '',
    messageCode: '',
    loading: false,
    error:false
  },
};

// eslint-disable-next-line complexity,default-param-last
const forecastReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    
    case FORECAST_DETAILS_GET_START:
      return {
        ...state,
        forecastDetailsInfo: {
          message: '',
          messageCode: '',
          loading: true,
          error:false
        },
        forecastDetailsSubmitInfo: {
          message: '',
          messageCode: '',
          loading: false,
          error:false
        },
        loading: true
      };
    case FORECAST_DETAILS_GET_SUCCESS:
      return {
        ...state,
        forecastDetails: {...(action?.payload?.forecastData || {forecasts:[]})},
        workPackageData: action?.payload?.workPackageData || [],
        forecastDetailsInfo: {
          message: 'success',
          messageCode: '',
          loading: false,
          error:false
        },
        loading: false
      };
    case FORECAST_DETAILS_SUBMIT_SUCCESS:
      return {
        ...state,
        forecastDetailsSubmitInfo: {
          message: 'success',
          messageCode: '',
          loading: false,
          error:false
        },
        loading: false
      };
    case FORECAST_DETAILS_GET_FAILED:
      return {
        ...state,
        loading: false,
        forecastDetailsInfo: {
          message: '',
          messageCode: '',
          loading: false,
          error:true,
        }
      };
    case FORECAST_DETAILS_GET_RESET:
      return {
        ...state,
        loading: false,
        workPackageData:[],
        forecastDetails:{forecasts:[]},
        forecastDetailsInfo: {
          message: '',
          messageCode: '',
          loading: false,
          error:false
        }
      };
    case FORECAST_DETAILS_SUBMIT_RESET:
      return {
        ...state,
        forecastDetailsSubmitInfo: {
          message: '',
          messageCode: '',
          loading: false,
          error:false
        },
      };
    
    default:
      return state;
  }
};

export default forecastReducer;
