export const SET_LOADING_TRUE = 'cbre-pjm-principal-lite-mybuy-requests/SET_LOADING_TRUE';
export const SET_LOADING_FALSE = 'cbre-pjm-principal-lite-mybuy-requests/SET_LOADING_FALSE';
export const FETCH_DATA_REQUEST = 'cbre-pjm-principal-lite-mybuy-requests/FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'cbre-pjm-principal-lite-mybuy-requests/FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'cbre-pjm-principal-lite-mybuy-requests/FETCH_DATA_FAILURE';
export const FETCH_COMMENTS_REQUEST = 'FETCH_COMMENTS_REQUEST';
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS';
export const FETCH_COMMENTS_FAILURE = 'FETCH_COMMENTS_FAILURE';

export const ADD_COMMENT_REQUEST = 'ADD_COMMENT_REQUEST';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE';

export const UPDATE_VIEWED_COMMENTS = 'UPDATE_VIEWED_COMMENTS';
export const RESET_COMMENTS = 'RESET_COMMENTS';
export const CURRENT_USER = 'CURRENT_USER';
