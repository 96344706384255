/* eslint-disable react/jsx-filename-extension */
import React, { useContext } from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import { useSelector } from 'react-redux';
import { useTreatments, SplitContext } from '@splitsoftware/splitio-react';
import Navigation from '../../components/Navigation';

// eslint-disable-next-line complexity
const getNavigationMenus = (appConfig) => {
  return [
    /* {
    name: 'sidebar.menu',
    type: 'section',
    children: [ */
    {
      name: 'logo.justificationAndAuthorization',
      type: 'item',
      isExternalURL: false,
      disabled: false,
      link: '/pda',
      customIcon: 'backgroundImagePDA-sm',
    },
    {
      name: 'pages.capitalPlanning',
      type: 'item',
      link: '/capitalplan',
      disabled: false,
      customIcon: 'pjmProductsCapitalPlan-sm backgroundImageDashboardIcons-sm',
    },
    {
      name: 'logo.programmanagement',
      type: 'item',
      link: '/programmanagement',
      disabled: false,
      customIcon: 'sideMenuCustomIconsPJM backgroundImagePM-sm',
    },
    {
      name: 'logo.spacer',
      type: 'item',
      isExternalURL: true,
      disabled: false,
      link: `${
        appConfig && appConfig.client && appConfig.client.spacerUrl
          ? appConfig.client.spacerUrl
          : ''
      }`,
      customIcon: 'pjmProductsSpacer-sm backgroundImageDashboardIcons-sm',
    },
    {
      name: 'pages.estimator',
      type: 'item',
      isExternalURL: false,
      disabled: false,
      link: '/estimator',
      customIcon: 'pjmProductsEstimator-sm backgroundImageDashboardIcons-sm ',
    },
    {
      name: 'logo.plansPro',
      type: 'item',
      isExternalURL: true,
      disabled: false,
      link: `${
        appConfig && appConfig.client && appConfig.client.plansProUrl
          ? appConfig.client.plansProUrl
          : ''
      }`,
      customIcon: 'pjmProductsPlansPro-sm backgroundImageDashboardIcons-sm',
    },
    {
      name: 'logo.officeReady',
      type: 'item',
      isExternalURL: true,
      disabled: false,
      link: `${
        appConfig && appConfig.client && appConfig.client.officeReadyUrl
          ? appConfig.client.officeReadyUrl
          : ''
      }`,
      customIcon: 'pjmProductsOfficeReady-sm backgroundImageDashboardIcons-sm',
    },
    {
      name: 'logo.kahua',
      type: 'item',
      isExternalURL: true,
      disabled: false,
      link: `${
        appConfig && appConfig.client && appConfig.client.kahuaUrl
          ? appConfig.client.kahuaUrl
          : ''
      }`,
      customIcon: 'pjmProductsKahua-sm backgroundImageDashboardIcons-sm',
    },
    {
      name: 'logo.biAnalytics',
      type: 'item',
      isExternalURL: true,
      disabled: false,
      link: `${
        appConfig && appConfig.client && appConfig.client.biAnalyticsUrl
          ? appConfig.client.biAnalyticsUrl
          : ''
      }`,
      customIcon: 'pjmProductsAnalytics-sm backgroundImageDashboardIcons-sm',
    },
    {
      name: 'logo.projectSplit',
      type: 'item',
      link: '/projecttracker',
      disabled: false,
      customIcon: 'pjmProductsProjectSplit-sm backgroundImageDashboardIcons-sm',
    },
  ];
};
const addPrincipalLite = (config, navigationMenus) => {
  if (config?.showPrincipalLite) {
    navigationMenus.unshift({
      name: 'logo.principalLite',
      type: 'item',
      isExternalURL: false,
      disabled: false,
      link: '/myprojects',
      customIcon: 'backgroundImagePL-sm',
    });
  }
};

const addAdminItems = (permissions, navigationMenus) => {
  if (permissions.isSystemAdmin || permissions.level.includes(1)) {
    const children = [];
    if (permissions.isSystemAdmin) {
      children
        .push
        // ... admin items
        ();
    }
    children.push({
      name: 'logo.dataAdmin',
      type: 'item',
      link: '/dataadministration',
      customIcon: 'sideMenuCustomIcons backgroundImageDataAdmins',
    });
    navigationMenus.push({
      name: 'sidebar.administration',
      type: 'item',
      link: '/Administration/AccessManagement/ClientAccess',
      customIcon: 'sideMenuCollapseIcons backgroundImageAdminIcon-sm',
      isbottom: true,
    });
  }
};

const SideBarContent = () => {
  const appConfig = useSelector((state) => state.config);
  const { permissions } = useSelector((state) => state.auth);
  const tileFeatureName = 'PJM_PL_SHOW_TILE';
  const { isReady } = useContext(SplitContext);
  const treatments = useTreatments([tileFeatureName]);
  const { treatment, config: treatmentConfig } = treatments[tileFeatureName];
  const defaultConfig = {
    showPrincipalLite: true,
    maxWidth: 57,
    columns: { desktop: 4, tablet: 3, mobile: 1 },
  };

  const config = isReady ? JSON.parse(treatmentConfig || {}) : defaultConfig;
  let navigationMenus = getNavigationMenus(appConfig);
  addPrincipalLite(config, navigationMenus);
  addAdminItems(permissions, navigationMenus);

  return (
    <CustomScrollbars className=" scrollbar">
      <Navigation menuItems={navigationMenus} />
    </CustomScrollbars>
  );
};

export default SideBarContent;
