import React, { useEffect, useState } from 'react';
import useFetchSegmentData from './useFetchSegmentData';

export const UserHierarchyAccessContext = React.createContext();

export const UserHierarchyAccessProvider = ({ children }) => {
  const contextValue = useFetchSegmentData();

  return (
    <UserHierarchyAccessContext.Provider value={contextValue}>
      {children}
    </UserHierarchyAccessContext.Provider>
  );
};

