
/* eslint-disable */
import {
  GET_PDA_LOOKUP_SUCCESS,
  GET_PDA_LOOKUP_FAILURE,
  GET_PDA_LOOKUP_RESET,
} from './constants';

const INITIAL_STATE = {
  simpleLookups: [],
  simpleLookupSuccess: '',
  error: false,
  errorCode: '',
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case GET_PDA_LOOKUP_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        simpleLookupSuccess: 'success',
      };
    case GET_PDA_LOOKUP_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        errorCode: action.payload,
        simpleLookupSuccess: '',
      };
    case GET_PDA_LOOKUP_RESET:
      return {
        ...state,
        loading: false,
        lookupData: {},
        simpleLookupSuccess: '',
      };
    default:
      return state;
  }
};
