export const BASIC_INFORMATION_ADD_NEW_PROJECT_START = 'BASIC_INFORMATION_ADD_NEW_PROJECT_START';
export const BASIC_INFORMATION_ADD_NEW_PROJECT_SUCCESS = 'BASIC_INFORMATION_ADD_NEW_PROJECT_SUCCESS';
export const BASIC_INFORMATION_ADD_NEW_PROJECT_FAILURE = 'BASIC_INFORMATION_ADD_NEW_PROJECT_FAILURE';
export const BASIC_INFORMATION_ADD_NEW_PROJECT_EXISTS_FAILURE = 'BASIC_INFORMATION_ADD_NEW_PROJECT_EXISTS_FAILURE';
export const BASIC_INFORMATION_ADD_NEW_PROJECT_RESET = 'BASIC_INFORMATION_ADD_NEW_PROJECT_RESET';
export const UPDATE_APPROVED_PROJECT_START = 'UPDATE_APPROVED_PROJECT_START';
export const UPDATE_APPROVED_PROJECT_SUCCESS = 'UPDATE_APPROVED_PROJECT_SUCCESS';
export const UPDATE_APPROVED_PROJECT_FAILURE = 'UPDATE_APPROVED_PROJECT_FAILURE';
export const UPDATE_APPROVED_PROJECT_RESET = 'UPDATE_APPROVED_PROJECT_RESET';
export const SET_LOADING_TRUE = 'CBRE-PJM-PDA-FORM-ROOT/SET_LOADING_TRUE';
export const SET_LOADING_FALSE = 'CBRE-PJM-PDA-FORM-ROOT/SET_LOADING_FALSE';
