import {
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  OPEN_DELETE_PROJECT_DIALOG,
  OPEN_DELETE_PROJECT_DIALOG_SUCCESS,
  OPEN_DELETE_PROJECT_DIALOG_FAILURE,
  CLOSE_DELETE_PROJECT_DIALOG,
  DELETE_PROJECT_START,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  CLOSE_DELETE_PROJECT_TOAST,
  SET_USER_AS_LEADER,
  SET_USER_AS_NON_LEADER,
} from './constants';

const INITIAL_STATE = {
  error: false,
  errorCode: '',
  loading: false,
  basicInfo: {
    message: '',
    messageCode: '',
    loading: false,
  },
  overviewData: {
    message: '',
    messageCode: '',
    loading: false,
  },
  approvalsData: {
    message: '',
    messageCode: '',
    loading: false,
  },
  projectList: {},
  projectListSuccess: '',
  projectOverviewData: {},
  projectRisksData: {},
  projectData: {},
  projectDetails: {},
  projectDetailsDEF: {},
  costPlanData: {},
  costPlanInfo: {
    message: '',
    messageCode: '',
    loading: false,
  },
  costPlanCheckBox: false,
  vendorsListInfo: {
    message: '',
    messageCode: '',
    loading: false,
  },
  allLegacyData: {},
  approversListInfo: {
    message: '',
    messageCode: '',
    loading: false,
  },
  vendorsListData: [],
  approversListData: [],
  reviewSubmitData: {},
  reviewSubmitValidationError: {},
  reviewStarted: false,
  riskSuccess: '',
  approvalsInfoData: {},
  kahuaStatus: {},
  bidStatusInfo: {},
  custom: {
    fetchRiskData: false,
  },
  kahuaSyncFailure: {},
  showDeleteToaster: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleAlertClose: () => {},
  routeChangeFlag: false,
  openDeleteDialog: false,
  mode: '',
  alertDeleteProject: '',
  alertMessage: '',
  projectId: '',
  projectTitle: '',
  message: '',
  isUserLeader: false,
};
// eslint-disable-next-line complexity
const principalLiteProjectRootReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case OPEN_DELETE_PROJECT_DIALOG:
      return {
        ...state,
        openDeleteDialog: true,
        mode: 'delete',
        projectId: action.payload.projectId,
        documentId: action.payload.documentId,
        enabled: action.payload.enabled,
        routeChangeFlag: action.payload.routeChangeFlag,
      };
    case OPEN_DELETE_PROJECT_DIALOG_SUCCESS:
      return {
        ...state,
        openDeleteDialog: true,
        mode: 'delete',
        projectId: action.payload.projectId,
        deleteDialogOpen: true,
      };
    case OPEN_DELETE_PROJECT_DIALOG_FAILURE:
      return {
        ...state,
        openDeleteDialog: false,
        mode: '',
      };
    case CLOSE_DELETE_PROJECT_DIALOG:
      return {
        ...state,
        openDeleteDialog: false,
        mode: '',
        projectId: '',
        deleteDialogOpen: false,
      };
    case DELETE_PROJECT_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        alertDeleteProject: 'success',
        alertMessage: action.payload.deleteProjectMsgSuccess,
        openDeleteDialog: false,
        deleteDialogOpen: false,
        showDeleteToaster: true,
        handleAlertClose: action.payload.handleAlertClose,
      };
    case DELETE_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
        alertDeleteProject: 'failure',
        alertMessage: action.payload.deleteProjectMsgFailure,
        openDeleteDialog: false,
        deleteDialogOpen: false,
        showDeleteToaster: true,
        handleAlertClose: action.payload.handleAlertClose,
      };
    case CLOSE_DELETE_PROJECT_TOAST:
      return {
        ...state,
        alertDeleteProject: 'close',
        alertMessage: '',
        deleteDialogOpen: false,
        showDeleteToaster: false,
      };
    case SET_USER_AS_LEADER:
      return {
        ...state,
        isUserLeader: true,
      };
    case SET_USER_AS_NON_LEADER:
      return {
        ...state,
        isUserLeader: false,
      };
    default:
      return state;
  }
};

export default principalLiteProjectRootReducer;
