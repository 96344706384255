import {
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  GET_PDA_LIST_START,
  GET_PDA_LIST_SUCCESS,
  GET_PDA_LIST_FAILURE,
  GET_PDA_LIST_RESET,
  GET_PDA_PROJECT_DETAILS_RESET,
  GET_VENDOR_LIST_START,
  GET_VENDOR_LIST_SUCCESS,
  GET_VENDOR_LIST_FAILURE,
  GET_VENDOR_LIST_RESET,
  GET_APPROVERS_LIST_START,
  GET_APPROVERS_LIST_SUCCESS,
  GET_APPROVERS_LIST_FAILURE,
  GET_APPROVERS_LIST_RESET,
  UPDATE_FEATURE_FLAG
} from './constants';
import {
  COST_PLAN_SAVE_START,
  COST_PLAN_SAVE_SUCCESS,
  COST_PLAN_SAVE_FAILURE,
  COST_PLAN_SAVE_RESET,
  GET_LEGACY_SUCCESS,
  GET_LEGACY_FAILURE,
  BUDGET_SAVE_FAILURE,
  BUDGET_SAVE_SUCCESS,
  BUDGET_SAVE_RESET,
  PR_CR_VALIDATION_ERROR,
} from './costPlanConstants';

import {
  PL_PDA_SUBMIT_FAILURE,
  PL_PDA_SUBMIT_RESET,
  PL_PDA_SUBMIT_START,
  PL_PDA_SAVE_START,
  PL_PDA_SUBMIT_SUCCESS,
  PL_PDA_SUBMIT_VALIDATION_SET_ERROR,
  PL_PDA_SAVE_SUCCESS,
} from './reviewSubmitConstants';

import {
  CHANGE_MY_PROJECT_MODE,
  CHANGE_MY_PROJECT_MODE_AFTER_APPROVED,
} from './modeConstants';

const INITIAL_STATE = {
  error: false,
  errorCode: '',
  loading: false,
  basicInfo: {
    message: '',
    messageCode: '',
    loading: false,
  },
  overviewData: {
    message: '',
    messageCode: '',
    loading: false,
  },
  approvalsData: {
    message: '',
    messageCode: '',
    loading: false,
  },
  projectList: {},
  projectListSuccess: '',
  projectOverviewData: {},
  projectRisksData: {},
  projectData: {},
  projectDetails: {},
  projectDetailsDEF: {},
  costPlanData: {},
  costPlanInfo: {
    message: '',
    messageCode: '',
    loading: false,
    type: '',
    mode: '',
    initiateSaveAndSubmit: false,
    data: {}
  },
  budgetInfo: {
    response: {},
    message: '',
    messageCode: '',
    loading: false,
    type: '',
    mode: '',
    initiateSaveAndSubmit: false,
    data: {}
  },
  costPlanCheckBox: false,
  vendorsListInfo: {
    message: '',
    messageCode: '',
    loading: false,
  },
  allLegacyData: {},
  approversListInfo: {
    message: '',
    messageCode: '',
    loading: false,
  },
  vendorsListData: [],
  approversListData: [],
  reviewSubmitData: {},
  reviewSubmitValidationError: {},
  reviewStarted: false,
  riskSuccess: '',
  approvalsInfoData: {},
  kahuaStatus: {},
  bidStatusInfo: {},
  custom: {
    fetchRiskData: false,
  },
  kahuaSyncFailure: {},
  showDeleteToaster: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleAlertClose: () => {},
  routeChangeFlag: false,
  openDeleteDialog: false,
  mode: '',
  alertDeleteProject: '',
  alertMessage: '',
  projectId: '',
  projectTitle: '',
  isPreviewMode: true,
  isApprovedPreviewMode: true,
  hideChecklistEdit: false,
  prCrValidation: {
    isError: false, 
    errorMessage: '', 
    errorActivities: [],
  },
  cdfFlag: false,
};
// eslint-disable-next-line complexity
const principalLiteProjectRootReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case GET_PDA_LIST_SUCCESS: {
      const { data, custom } = action.payload;
      return {
        ...state,
        projectDetails: data,
        projectDetailsDEF: data,
        loading: false,
        projectListSuccess: 'success',
        reviewStarted: false,
        custom: custom ? { ...state.custom, ...custom } : { ...state.custom },
      };
    }
    case GET_PDA_LIST_START:
      return {
        ...state,
        loading: true,
        // projectDetails: {},
        projectListSuccess: '',
      };
    case GET_PDA_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        projectDetails: action.payload,
      };
    case GET_PDA_LIST_RESET:
      return {
        ...state,
        projectListSuccess: '',
        loading: false,
      };
    case GET_PDA_PROJECT_DETAILS_RESET:
      return {
        ...state,
        projectDetails: {},
      };
    case COST_PLAN_SAVE_RESET:
      return {
        ...state,
        costPlanInfo: {
          message: '',
          messageCode: '',
          loading: false,
          type: '',
          mode: '',
          initiateSaveAndSubmit: false,
        },
      };
    case COST_PLAN_SAVE_SUCCESS:
      return {
        ...state,
        costPlanInfo: {
          message: 'success',
          loading: false,
          messageCode: '',
          type: action.payload.isType,
          mode: action.payload.type,
          initiateSaveAndSubmit: action?.payload?.initiateSaveAndSubmit || false,
          data: action?.payload?.data,
        },
        costPlanData: action.payload,
        loading: false,
      };
    case COST_PLAN_SAVE_FAILURE:
      return {
        ...state,
        costPlanInfo: {
          response: action.payload,
          loading: false,
          type: '',
          mode: '',
          initiateSaveAndSubmit: false,
        },
        loading: false,
      };
    case COST_PLAN_SAVE_START:
      return {
        ...state,
        costPlanInfo: {
          message: '',
          messageCode: '',
          loading: true,
          type: '',
          mode: '',
          initiateSaveAndSubmit: false,
        },
        loading: true,
      };
    case BUDGET_SAVE_RESET:
      return {
        ...state,
        costPlanInfo: {
          message: '',
          messageCode: '',
          loading: false,
          type: '',
          mode: '',
          initiateSaveAndSubmit: false,
          data: {},
        },
        budgetInfo: {
          response: {},
          message: '',
          messageCode: '',
          loading: false,
          type: '',
          mode: '',
          initiateSaveAndSubmit: false,
          data: {},
        },
      };
    case BUDGET_SAVE_SUCCESS:
      return {
        ...state,
        costPlanInfo: {
          message: 'success',
          loading: false,
          messageCode: '',
          type: action.payload.isType,
          mode: action.payload.type,
          initiateSaveAndSubmit: action?.payload?.initiateSaveAndSubmit || false,
          data: action?.payload?.data,
        },
        budgetInfo: {
          response: action?.payload,
          message: 'success',
          loading: false,
          messageCode: '',
          type: action.payload.isType,
          mode: action.payload.type,
          initiateSaveAndSubmit: action?.payload?.initiateSaveAndSubmit || false,
          data: action?.payload?.data,
        },
        costPlanData: action.payload,
        loading: false,
      };
    case BUDGET_SAVE_FAILURE:
      return {
        ...state,
        costPlanInfo: {
          response: action?.payload,
          loading: false,
          type: '',
          mode: '',
          initiateSaveAndSubmit: false,
          data: {},
        },
        budgetInfo: {
          response: action?.payload,
          loading: false,
          type: '',
          mode: '',
          initiateSaveAndSubmit: false,
          data: {}
        },
        loading: false,
      };
    case GET_VENDOR_LIST_RESET:
      return {
        ...state,
        vendorsListInfo: {
          message: '',
          messageCode: '',
          loading: false,
        },
      };
    case GET_VENDOR_LIST_SUCCESS:
      return {
        ...state,
        vendorsListInfo: {
          message: 'success',
          loading: false,
          messageCode: '',
        },
        vendorsListData: action.payload,
        loading: false,
      };
    case GET_VENDOR_LIST_FAILURE:
      return {
        ...state,
        vendorsListInfo: {
          response: action.payload,
          loading: false,
        },
        loading: false,
      };
    case GET_VENDOR_LIST_START:
      return {
        ...state,
        vendorsListInfo: {
          message: '',
          messageCode: '',
          loading: true,
        },
        loading: true,
      };
    case GET_LEGACY_SUCCESS:
      return {
        ...state,
        allLegacyData: action.payload,
        loading: false,
      };
    case GET_LEGACY_FAILURE:
      return {
        ...state,
        allLegacyData: action.payload,
        loading: false,
      };
    case GET_APPROVERS_LIST_START:
      return {
        ...state,
        approversListInfo: {
          message: '',
          messageCode: '',
          loading: true,
        },
        loading: true,
      };
    case GET_APPROVERS_LIST_RESET:
      return {
        ...state,
        approversListInfo: {
          message: '',
          messageCode: '',
          loading: false,
        },
      };
    case GET_APPROVERS_LIST_SUCCESS:
      return {
        ...state,
        approversListInfo: {
          message: 'success',
          loading: false,
          messageCode: '',
        },
        approversListData: action.payload,
        loading: false,
      };
    case GET_APPROVERS_LIST_FAILURE:
      return {
        ...state,
        approversListInfo: {
          response: action.payload,
          loading: false,
        },
        loading: false,
      };
    case PL_PDA_SUBMIT_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      let stateObj = {
        ...state,
        reviewSubmitData: action.payload,
        reviewStarted: false,
        loading: false,
      };
      stateObj.projectDetails.project.workFlowStatus.code =
        action.payload.workFlowStatusCode;
      return stateObj;
    case PL_PDA_SUBMIT_FAILURE:
      return {
        ...state,
        reviewSubmitData: action.payload,
        loading: false,
      };
    case PL_PDA_SUBMIT_RESET:
      return {
        ...state,
        reviewSubmitData: {},
        reviewStarted: false,
      };
    case PL_PDA_SUBMIT_VALIDATION_SET_ERROR:
      return {
        ...state,
        loading: true,
        reviewSubmitValidationError: action.payload,
      };
    case PL_PDA_SUBMIT_START:
      return {
        ...state,
        loading: true,
        reviewStarted: true,
      };
      case PL_PDA_SAVE_START:
        return {
          ...state,
          loading: true,
        };
        case PL_PDA_SAVE_SUCCESS:
          return {
            ...state,
            loading: false,
          };
    case CHANGE_MY_PROJECT_MODE:
      return {
        ...state,
        projectId: action.payload.projectId,
        isPreviewMode: action.payload.isPreviewMode,
        hideChecklistEdit:
          action.payload?.hideChecklistEdit ?? state.hideChecklistEdit,
      };
    case CHANGE_MY_PROJECT_MODE_AFTER_APPROVED:
      return {
        ...state,
        projectId: action.payload.projectId,
        isApprovedPreviewMode: action.payload.isApprovedPreviewMode,
        hideChecklistEdit: action.payload.hideChecklistEdit,
      };
    case PR_CR_VALIDATION_ERROR:
      return {
        ...state,
        prCrValidation: {
          isError: action.payload.isError, 
          errorMessage: action.payload.errorMessage, 
          errorActivities: action.payload.errorActivities,
        },
        loading: false,
      };
    case UPDATE_FEATURE_FLAG:
      return {
        ...state,
        cdfFlag: action?.payload?.cdfFlag,
        loading: false,
      };
    default:
      return state;
  }
};

export default principalLiteProjectRootReducer;
