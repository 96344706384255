export const RESET_SEARCH_ADD_USER = 'RESET_SEARCH_ADD_USER';
export const DISABLE_SEARCH_ADD_USER = 'DISABLE_SEARCH_ADD_USER';
export const START_SEARCH_ADD_USER = 'START_SEARCH_ADD_USER';
export const SUCCESS_SEARCH_ADD_USER = 'SUCCESS_SEARCH_ADD_USER';
export const FAILURE_SEARCH_ADD_USER = 'FAILURE_SEARCH_ADD_USER';
export const GET_ALL_LOOKUPS_SUCCESS = 'GET_ALL_LOOKUPS_SUCCESS';
export const GET_ALL_LOOKUPS_FAILURE = 'GET_ALL_LOOKUPS_FAILURE';
export const GET_ALL_USER_ROLES_SUCCESS = 'GET_ALL_USER_ROLES_SUCCESS';
export const GET_ALL_USER_ROLES_FAILURE = 'GET_ALL_USER_ROLES_FAILURE';
export const GET_ALL_USER_ROLES_RESET = 'GET_ALL_USER_ROLES_RESET';
