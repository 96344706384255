
/* eslint-disable */
import {
  GET_ALL_LOOKUPS_SUCCESS,
  GET_ALL_LOOKUPS_FAILURE,
  GET_ALL_LOOKUPS_START,
  GET_CLIENT_DATA_FIELDS_SUCCESS,
  SUBMIT_CLIENT_DATA_FIELDS_SUCCESS,
  SUBMIT_CLIENT_DATA_FIELDS_START,
  SUBMIT_CLIENT_DATA_FIELDS_FAILURE,
  RESET,
  SET_DEFAULT_VALUES,
  RESET_API_MSG,
  GET_CLIENT_DATA_FIELDS_FAILURE
} from './constants';

const INITIAL_STATE = {
  lookupData: {},
  loading: false,
  fetchProjectDetails: true,
  fetchJsonSchemaError: false,
  fetchLookupError: false,
  fetchLookupsStatus: '',
  apiMessage: '',
  apiMessageStatus: '',
  apiMessageCode: '',
  jsonSchema: [],
  defaultValues: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        lookupData: action.payload,
        loading: false,
        fetchLookupsStatus: 'success',
        fetchProjectDetails: true
      };
    case GET_ALL_LOOKUPS_FAILURE:
      return {
        ...state,
        loading: false,
        fetchLookupsStatus: 'failure',
        fetchLookupError: true,
        apiMessage: action.payload,
        apiMessageStatus: 'failure'
      };
    case GET_CLIENT_DATA_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        jsonSchema: action.payload
      };
    case GET_CLIENT_DATA_FIELDS_FAILURE:
      return {
        ...state,
        loading: false,        
        fetchJsonSchemaError: true,
        apiMessage: action.payload.message,
        apiMessageCode: action.payload.messageCode,
        apiMessageStatus: 'failure'
      };
    case SUBMIT_CLIENT_DATA_FIELDS_START:
      return {
        ...state,
        loading: true,
        apiMessage: '',
        apiMessageStatus: '',
        apiMessageCode: '',
      };
    case SUBMIT_CLIENT_DATA_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        apiMessage: action.payload,
        apiMessageStatus: 'success'
      };
    case SUBMIT_CLIENT_DATA_FIELDS_FAILURE:
      return {
        ...state,
        loading: false,
        apiMessage: action.payload,
        apiMessageStatus: 'failure'
      };
    case GET_ALL_LOOKUPS_START:
      return {
        ...state,
        loading: true
      };
    case SET_DEFAULT_VALUES:
      return {
        ...state,
        defaultValues: action.payload
      };
    case RESET_API_MSG:
      return {
        ...state,
        apiMessage: '',
        apiMessageStatus: '',
        apiMessageCode: '',
      };
    case RESET:
      return {
        ...state,
        fetchLookupsError: false,
        fetchLookupError:false,
        fetchJsonSchemaError: false,
        loading: false,
        fetchLookupsStatus: '',
        apiMessage: '',
        apiMessageStatus: '',
        apiMessageCode: '',
        jsonSchema: [],
        lookupData: {}
      };

    default:
      return state;
  }
};
