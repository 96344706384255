export const GET_PDA_LIST_SUCCESS = 'CBRE-PJM-PDA-FORM-ROOT/GET_PDA_LIST_SUCCESS';
export const GET_PDA_LIST_FAILURE = 'CBRE-PJM-PDA-FORM-ROOT/GET_PDA_LIST_FAILURE';
export const GET_PDA_LIST_START = 'CBRE-PJM-PDA-FORM-ROOT/GET_PDA_LIST_START';
export const GET_PDA_LIST_RESET = 'CBRE-PJM-PDA-FORM-ROOT/GET_PDA_LIST_RESET';
export const GET_PDA_PROJECT_DETAILS_RESET = 'CBRE-PJM-PDA-FORM-ROOT/GET_PDA_PROJECT_DETAILS_RESET';
export const GET_CPP_DOCUMENT_START = 'CBRE-PJM-PDA-FORM-ROOT/GET_DOCUMENT_START';
export const GET_CPP_DOCUMENT_SUCCESS = 'CBRE-PJM-PDA-FORM-ROOT/GET_DOCUMENT_SUCCESS';
export const GET_CPP_DOCUMENT_FAILURE = 'CBRE-PJM-PDA-FORM-ROOT/GET_DOCUMENT_FAILURE';
export const GET_CPP_DOCUMENT_RESET = 'CBRE-PJM-PDA-FORM-ROOT/GET_DOCUMENT_RESET';
export const GET_CPP_DOCUMENT_SUBMIT_START = 'CBRE-PJM-CPP-FORM-ROOT/GET_DOCUMENT_SUBMIT_START';
export const GET_CPP_DOCUMENT_SUBMIT_SUCCESS = 'CBRE-PJM-CPP-FORM-ROOT/GET_DOCUMENT_SUBMIT_SUCCESS';
export const GET_CPP_DOCUMENT_SUBMIT_FAILURE = 'CBRE-PJM-CPP-FORM-ROOT/GET_DOCUMENT_SUBMIT_FAILURE';
export const GET_CPP_DOCUMENT_SUBMIT_RESET = 'CBRE-PJM-CPP-FORM-ROOT/GET_DOCUMENT_SUBMIT_RESET';
export const GET_CPP_DOCUMENT_REVOKE_START = 'CBRE-PJM-CPP-FORM-ROOT/GET_DOCUMENT_REVOKE_START';
export const GET_CPP_DOCUMENT_REVOKE_SUCCESS = 'CBRE-PJM-CPP-FORM-ROOT/GET_DOCUMENT_REVOKE_SUCCESS';
export const GET_CPP_DOCUMENT_REVOKE_FAILURE = 'CBRE-PJM-CPP-FORM-ROOT/GET_DOCUMENT_REVOKE_FAILURE';
export const GET_CPP_DOCUMENT_REVOKE_RESET = 'CBRE-PJM-CPP-FORM-ROOT/GET_DOCUMENT_REVOKE_RESET';
export const SAVE_CPP_DOCUMENT_START = 'CBRE-PJM-CPP-FORM-ROOT/SAVE_CPP_DOCUMENT_START';
export const SAVE_CPP_DOCUMENT_SUCCESS = 'CBRE-PJM-CPP-FORM-ROOT/SAVE_CPP_DOCUMENT_SUCCESS';
export const SAVE_CPP_DOCUMENT_FAILURE = 'CBRE-PJM-CPP-FORM-ROOT/SAVE_CPP_DOCUMENT_FAILURE';
export const SAVE_CPP_DOCUMENT_RESET = 'CBRE-PJM-CPP-FORM-ROOT/SAVE_CPP_DOCUMENT_RESET';
