
/* eslint-disable */
import {
  GET_PLAN_START,
  GET_PLAN_SUCCESS,
  GET_PLAN_FAILURE,
  GET_PLAN_RESET,
  GET_FILTER_SUCCESS,
  GET_FILTER_FAILURE,
  GET_FILTER_RESET,
  GET_TOP10_PROJECTS_SUCCESS,
  GET_TOP10_PROJECTS_FAILURE,
  GET_TOP10_PROJECTS_RESET,
  SAVE_PROJECT_PRIORITY_SCORE_SUCCESS,
  SAVE_PROJECT_PRIORITY_SCORE_FAILURE,
  GET_CASH_FLOW_GRAPH_DATA_SUCCESS,
  GET_CASH_FLOW_GRAPH_DATA_RESET,
  GET_CASH_FLOW_GRAPH_DATA_FAILURE,
  GET_PLAN_LIST,
  GET_PLAN_LIST_FAILURE,
  GET_PLAN_LIST_SUCCESS,
  SET_CHECKED_PROJECT_LIST,
  UPDATE_CP_PROJECT_TAB_COUNT,
  SAVE_PARENT_INFO
} from './constants';

const INITIAL_STATE = {
  planHdrDetails: {},
  filterHdrDetails: {},
  top10Projects: {},
  savePriorityScore: {},
  parentInfo: {},
  saveRenderCount: 0,
  error: false,
  errorCode: '',
  loading: false,
  cashFlowData: [],
  planList: [],
  checkedProjectList: [],
  CPProjectCount: 0,
  programCount: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PLAN_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case GET_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        planHdrDetails: action.payload,
      };
    case GET_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_PLAN_RESET:
      return {
        ...state,
        loading: false,
        planHdrDetails: {},
      };
    case GET_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        filterHdrDetails: action.payload,
      };
    case GET_FILTER_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        errorCode: action.payload,
      };
    case GET_FILTER_RESET:
      return {
        ...state,
        loading: false,
        filterHdrDetails: {},
      };
    case GET_TOP10_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        top10Projects: action.payload,
      };
    case GET_TOP10_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_TOP10_PROJECTS_RESET:
      return {
        ...state,
        loading: false,
        top10Projects: {},
      };
    case SAVE_PROJECT_PRIORITY_SCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        savePriorityScore: action.payload,
        saveRenderCount: state.saveRenderCount + 1,
      };
    case SAVE_PROJECT_PRIORITY_SCORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_CASH_FLOW_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        cashFlowData: action.payload,
      };
    case GET_CASH_FLOW_GRAPH_DATA_RESET:
      return {
        ...state,
        loading: false,
        cashFlowData: [],
      };
    case GET_CASH_FLOW_GRAPH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_PLAN_LIST:
      return {
        ...state,
        loading: true,
      }
    case GET_PLAN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        planList: action.payload,
      }
    case GET_PLAN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      }
    case SET_CHECKED_PROJECT_LIST:
      return {
        ...state,
        checkedProjectList: action.payload,
      }
    case UPDATE_CP_PROJECT_TAB_COUNT:
      return {
        ...state,
        ...action.payload,
      };
    case SAVE_PARENT_INFO:
      return {
        ...state,
        parentInfo: action.payload,
      };
    default:
      return state;
  }
};
