import AppLocale from '../lngProvider';

/* eslint-disable */
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const meterToFoot = 3.28084;
export const roundDecimalNumber = 5;

export const workflowStageMapping = {
  'WFS_OPPORTUNITY': 'opportunities',
  'WFS_PIPELINE': 'pipeline',
  'WFS_ORDER_BOOK': 'orderbook',
  "WFS_TEMPLATE": 'templates',
}

export const dateFormatter = (date, format) => {
  // const dateObj = new Date(date);
  const dateObj = new Date(date).toISOString().slice(0, 10);
  const dateSplit = dateObj.split('-');
  const dateText = dateSplit[2] < 10 ? `0${dateSplit[1]}` : dateSplit[2];
  const month = (new Date(date).getMonth() + 1) < 10 ? `0${new Date(date).getMonth() + 1}` : new Date(date).getMonth() + 1;
  const year = dateSplit[0];
  switch (true) {
    case format === 'DD/MM/YYYY':
      return `${dateText}/${month}/${year}`;
    case format === 'MM/DD/YYYY':
      return `${month}/${dateText}/${year}`;
    case format === 'dateString':
      return `${months[new Date(date).getMonth()]} ${dateText}, ${year}`;
    default:
      break;
  }
};
export const roundToTwoDecimals = (value) => Number(`${Math.round(`${value}e2`)}e-2`);
export const unitConversion = (value, selectedUnit) => {
  if (value > 0) {
    return selectedUnit === 'SQ MT' ? roundToTwoDecimals(value / meterToFoot) : roundToTwoDecimals(value * meterToFoot);
  }
  return value;
};
export const currencyRangeSymbol =(num) =>{
  let isNegative = false
  if (num < 0) {
      isNegative = true
  }
  num = Math.abs(num)
  let formattedNumber;
  if (num >= 1000000000) {
      formattedNumber = (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
  } else if (num >= 1000000) {
      formattedNumber =  (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  } else  if (num >= 1000) {
      formattedNumber =  (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  } else {
      formattedNumber = num;
  }   
  if(isNegative) { formattedNumber = '-' + formattedNumber }
  return formattedNumber;
}

export function interpolate(message, interpolations = {}) {
  return Object.keys(interpolations).reduce(
    (interpolated, key) =>
      interpolated.replace(
        new RegExp(`{\\s*${key}\\s*}`, "g"),
        interpolations[key],
      ),
    message,
  );
}

export const getMessage = ({id, defaultMessage, ...rest}) => {
  let localizedText = AppLocale[localStorage.getItem("locale")]?.messages || {};
 return interpolate(localizedText[id], rest) || defaultMessage || id
}

export  const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

/**
 * Formats a number to have at least two decimal places, and optionally formats it as a percentage.
 *
 * @param {number|string} num - The number to format. If a string, it can optionally end with '%'.
 * @param {boolean} [isPercentage=false] - Whether to format the number as a percentage.
 * @param {boolean} [removePercentSign=false] - Whether to remove the '%' sign from the input.
 * @returns {string} The formatted number. If `isPercentage` is `true`, the number is followed by '%'.
 */

export const formatNumber = (num, isPercentage = false, removePercentSign = false) => {
  if (typeof num === 'string' && num.endsWith('%')) {
    num = num.slice(0, -1);
    if (!removePercentSign) {
      isPercentage = true;
    }
  }
  num = parseFloat(num);
  let decimalPart = num.toString().split('.')[1];
  if (!decimalPart || decimalPart.length < 2) {
    num = num.toFixed(2);
  } else if (decimalPart.length > roundDecimalNumber) {
    num = parseFloat(num.toFixed(roundDecimalNumber));
  }
  return isPercentage ? `${num}%` : num.toString();
};

/**
 * This function is used to reload the current page with a specified mode. The mode is set as a query parameter in the URL.
*/

export const reloadPageWithMode = (mode) => {
  const currentURL = new URL(window.location.href);
  const params = new URLSearchParams(currentURL.search);
  params.set('mode', mode);
  window.location.href = `${currentURL.origin}${currentURL.pathname}?${params.toString()}`;
};

export const checkUrlPath = (routeName, projectType = false) => {
  const pathArray = window.location.pathname.split('/');
  return pathArray?.includes(routeName) || projectType;
};

//reload the page with correct sourceSection if the url is incorrect
export const reloadPageWithCorrectSourceSection = (currentWorkflowStage) => {
  const currentURL = new URL(window.location.href);
  const params = new URLSearchParams(currentURL.search);
  const sourceSection = params.get('sourceSection')
  if(sourceSection !== workflowStageMapping[currentWorkflowStage]){
    params.set('sourceSection', workflowStageMapping[currentWorkflowStage]);
    if (currentURL?.pathname?.includes('newproject') && (currentWorkflowStage === 'WFS_PIPELINE' || currentWorkflowStage === 'WFS_ORDER_BOOK')) {
      currentURL.pathname = currentURL.pathname.replace('/newproject/', '/project/');
    }
    if (currentURL?.pathname?.includes('project') && currentWorkflowStage === 'WFS_OPPORTUNITY') {
      currentURL.pathname = currentURL.pathname.replace('/project/', '/newproject/');
    }
    window.location.href = `${currentURL.origin}${currentURL.pathname}?${params.toString()}`;
  }
};