export const INITIATE_IMPORT = 'CBRE-PJM-ADMIN-DATA-IMPORT/INITIATE_IMPORT';
export const RESET_INITIATE_IMPORT = 'CBRE-PJM-ADMIN-DATA-IMPORT/RESET_INITIATE_IMPORT';
export const INITIATE_IMPORT_SUCCESS = 'CBRE-PJM-ADMIN-DATA-IMPORT/INITIATE_IMPORT_SUCCESS';
export const INITIATE_IMPORT_FAILURE = 'CBRE-PJM-ADMIN-DATA-IMPORT/INITIATE_IMPORT_FAILURE';
export const SET_LOADING_TRUE = 'CBRE-PJM-ADMIN-DATA-IMPORT/SET_LOADING_TRUE';
export const SET_LOADING_FALSE = 'CBRE-PJM-ADMIN-DATA-IMPORT/SET_LOADING_FALSE';
export const GET_IMPORT_TYPE_SUCCESS = 'CBRE-PJM-ADMIN-DATA-IMPORT/GET_IMPORT_TYPE_SUCCESS';
export const GET_IMPORT_TYPE_FAILURE = 'CBRE-PJM-ADMIN-DATA-IMPORT/GET_IMPORT_TYPE_FAILURE';
export const IMPORT_DATA_START = 'CBRE-PJM-ADMIN-DATA-IMPORT/IMPORT_DATA_START';
export const IMPORT_DATA_SUCCESS = 'CBRE-PJM-ADMIN-DATA-IMPORT/IMPORT_DATA_SUCCESS';
export const IMPORT_DATA_FAILURE = 'CBRE-PJM-ADMIN-DATA-IMPORT/IMPORT_DATA_FAILURE';
export const GET_IMPORTS_LIST_START = 'CBRE-PJM-ADMIN-DATA-IMPORT/GET_IMPORTS_LIST_START';
export const GET_IMPORTS_LIST_SUCCESS = 'CBRE-PJM-ADMIN-DATA-IMPORT/GET_IMPORTS_LIST_SUCCESS';
export const GET_IMPORTS_LIST_FAILURE = 'CBRE-PJM-ADMIN-DATA-IMPORT/GET_IMPORTS_LIST_FAILURE';
export const GET_IMPORTS_DETAILS_START = 'CBRE-PJM-ADMIN-DATA-IMPORT/GET_IMPORTS_DETAILS_START';
export const GET_IMPORTS_DETAILS_SUCCESS = 'CBRE-PJM-ADMIN-DATA-IMPORT/GET_IMPORTS_DETAILS_SUCCESS';
export const GET_IMPORTS_DETAILS_FAILURE = 'CBRE-PJM-ADMIN-DATA-IMPORT/GET_IMPORTS_DETAILS_FAILURE';
export const SET_SELECTED_JOB_ID = 'CBRE-PJM-ADMIN-DATA-IMPORT/SET_SELECTED_JOB_ID';
