const spacePlanDefaultDataSQM = {
  headCount: 300,
  sharingRatio: 1,
  seatDensity: 20,
  officeVsWorkStationPercentage: 10,
  circulationFactorPercentage: 30,
  lossFactorPercentage: 20,
  headCountMin: 0,
  headCountMax: 5000,
  sharingRatioMin: 0,
  sharingRatioMax: 2,
  seatDensityMin: 5,
  seatDensityMax: 50,
  officeWorkstationRatioMin: 0,
  officeWorkstationRatioMax: 30,
  sqMtToSqFT: 10.7639,
};
export default spacePlanDefaultDataSQM;
