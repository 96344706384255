/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import React, { useContext } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import asyncComponent from '../util/asyncComponent';
import withSegmentSelection from './Utils/withSegmentSelection';
import {
  PROGRAM_MANAGEMENT_CLIENTS_ROUTE,
  PROGRAM_MANAGEMENT_PROGRAMS_ROUTE,
  PROGRAM_MANAGEMENT_ROUTE,
  PROGRAM_MANAGEMENT_PROJECTS_ROUTE,
} from './routes';
import { UserHierarchyAccessContext } from 'containers/Services/UserHierarchyAccessProvider';
import SegmentSelectorDialog from '../common/SegmentSelectorDialog';

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/dashboard',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const SegmentSelectorDialogWrapper = React.memo(({ open, projectOnlyAccess, firstStep, ...props }) => {
  return <SegmentSelectorDialog open={open} projectOnlyAccess={projectOnlyAccess} firstStep={firstStep} {...props} />;
});
// eslint-disable-next-line react/prop-types
const Routes = React.memo(({ match }) => {
  const { projectOnlyAccess } = useContext(UserHierarchyAccessContext);

  return (
    <Switch>
      <Route
        path={`${match.url}dashboard`}
        component={asyncComponent(() => import('./Dashboard'))}
      />
      <Route
        exact={true}
        path="/fusion"
        component={asyncComponent(() => import('./Fusion'))}
      />
      <Route
        exact={true}
        path="/fusion/:projectId"
        component={asyncComponent(() => import('./Fusion'))}
      />
      <Route
        exact={true}
        path={`${match.url}pda/myactions`}
        component={asyncComponent(() => import('./MyActions'))}
      />
      <Route
        exact={true}
        path="/myactions"
        component={asyncComponent(() => import('./MyActions'))}
      />
      <Route
        exact={true}
        path="/myactions/:projectApproverTaskId"
        component={asyncComponent(() => import('./MyActions'))}
      />
      <Route
        exact={true}
        path={`${match.url}pda/myactions/:projectApproverTaskId`}
        component={asyncComponent(() => import('./MyActions'))}
      />
      <Route
        path={`${match.url}AccessManagement`}
        component={asyncComponent(() => import('./UserManagement'))}
      />
      <Route
        path={`${match.url}DataAdministration`}
        component={asyncComponent(() => import('./DataAdministration'))}
      />
      <Route
        path={`${match.url}CustomFieldsMgmt/ManageLists`}
        component={asyncComponent(() => import('./ManageLookupType'))}
      />
      <Route
        path={`${match.url}CustomFieldsMgmt`}
        component={asyncComponent(() => import('./CustomFieldsMgmt'))}
      />
      <Route
        path={`${match.url}Administration/Approvals/Groups`}
        component={asyncComponent(() => import('./AdminRootDetails'))}
      />
      <Route
        path={`${match.url}Administration/Approvals/Configuration`}
        component={asyncComponent(() => import('./AdminRootDetails'))}
      />
      <Route
        path={`${match.url}Administration/CustomFieldsMgmt`}
        component={asyncComponent(() => import('./AdminRootDetails'))}
      />
      <Route
        path={`${match.url}Administration/ProgramManagement`}
        component={asyncComponent(() => import('./AdminRootDetails'))}
      />
      <Route
        path={`${match.url}Administration/DataAdministration`}
        component={asyncComponent(() => import('./AdminRootDetails'))}
      />
      <Route
        path={`${match.url}Administration/AccessManagement/ClientAccess`}
        component={asyncComponent(() => import('./AdminRootDetails'))}
      />
      <Route
        path={`${match.url}Administration/AccessManagement/HierarchyAccess`}
        component={asyncComponent(() => import('./AdminRootDetails'))}
      />
      <Route
        path={`${match.url}Administration/AccessManagement/ManageTeams`}
        component={asyncComponent(() => import('./AdminRootDetails'))}
      />
      <Route
        path={`${match.url}Administration`}
        component={asyncComponent(() => import('./AdminRootDetails'))}
      />
      <Route
        path={`${match.url}Administration/AccessManagement/UserRequests`}
        component={asyncComponent(() => import('./AdminRootDetails'))}
      />
      <Route
        path={`${match.url}approvalConfiguration`}
        exact
        component={asyncComponent(() => import('./ApprovalConfiguration'))}
      />
      <Route
        path={`${match.url}approvalConfiguration/addGroup`}
        component={asyncComponent(() =>
          import('./ApprovalConfiguration/approvalConfigAddGroup'),
        )}
      />
      <Route
        exact={true}
        path={`${match.url}capitalplan/plandetails`}
        component={asyncComponent(() => import('./CapitalPlan/plandetails'))}
      />
      <Route
        exact={true}
        path={`${match.url}capitalplan/plandetails/:clientId/:clientName/:planId/:planName`}
        component={asyncComponent(() => import('./CapitalPlan/plandetails'))}
      />
      <Route
        exact={true}
        path={`${match.url}capitalplan/import/:clientId/:clientName/:planId/:planName`}
        component={asyncComponent(() => import('./CapitalPlan/import'))}
      />
      <Route
        exact={true}
        path={`${match.url}capitalplan`}
        component={asyncComponent(() => import('./CapitalPlan'))}
      />
      <Route
        exact={true}
        path={`${match.url}capitalplan/clients`}
        component={asyncComponent(() => import('./CapitalPlan'))}
      />
      <Route
        exact={true}
        path={`${match.url}capitalplan/plans`}
        component={asyncComponent(() => import('./CapitalPlan/plansList'))}
      />
      <Route
        exact={true}
        path={`${match.url}capitalplan/plans/:clientId/:clientName`}
        component={asyncComponent(() => import('./CapitalPlan/plansList'))}
      />
      <Route
        exact={true}
        path={`${match.url}capitalplan/plans2`}
        component={asyncComponent(() => import('./CapitalPlan/plansListTemp'))}
      />
      <Route
        exact={true}
        path={`${match.url}capitalplan/newproject/:clientId/:clientName/:planId/:planName`}
        component={asyncComponent(() => import('./CapitalPlan/newProject'))}
      />
      <Route
        exact={true}
        path={`${match.url}capitalplan/newproject/:clientId/:clientName/:planId/:planName/:projectId`}
        component={asyncComponent(() => import('./CapitalPlan/newProject'))}
      />
      <Route
        exact={true}
        path={`${match.url}capitalplan/newproject/:clientId/:clientName/:planId/:planName/:projectId/fundingrequest/:fundingRequestId`}
        component={asyncComponent(() => import('./CapitalPlan/newProject'))}
      />
      <Route
        exact={true}
        path={`${match.url}projecttracker/newproject`}
        component={asyncComponent(() => import('./ProjectTracker/newProject'))}
      />
      <Route
        exact={true}
        path={`${match.url}projecttracker/newproject/:projectId`}
        component={asyncComponent(() => import('./ProjectTracker/newProject'))}
      />
      <Route
        exact={true}
        path={`${match.url}capitalplan/criteriasetting/:clientId/:clientName`}
        component={asyncComponent(() =>
          import('./CapitalPlan/criteriaSetting'),
        )}
      />
      <Route
        exact={true}
        path={`${match.url}capitalplan/criteriasetting/:clientId/:clientName/:planId/:planName`}
        component={asyncComponent(() =>
          import('./CapitalPlan/criteriaSetting'),
        )}
      />
      <Route
        exact={true}
        path={`${match.url}projecttracker`}
        component={asyncComponent(() =>
          import('./ProjectTracker/projectTrackerMainPage'),
        )}
      />
      <Route
        exact={true}
        path={`${match.url}pda`}
        component={asyncComponent(() => import('./JA/jAMainPage'))}
      />
      <Route
        exact={true}
        path={`${match.url}pda/project`}
        component={asyncComponent(() => import('./PDS'))}
      />
      <Route
        exact={true}
        path={`${match.url}${PROGRAM_MANAGEMENT_ROUTE}`}
        component={asyncComponent(() => import('./ProgramManagement'))}
      />
      <Route
        exact={true}
        path={`${match.url}${PROGRAM_MANAGEMENT_CLIENTS_ROUTE}`}
        component={asyncComponent(() => import('./ProgramManagement'))}
      />
      <Route
        exact={true}
        path={`${match.url}${PROGRAM_MANAGEMENT_PROGRAMS_ROUTE}`}
        component={asyncComponent(() =>
          import('./ProgramManagement/programsList'),
        )}
      />
      <Route
        exact={true}
        path={`${match.url}${PROGRAM_MANAGEMENT_PROJECTS_ROUTE}`}
        component={asyncComponent(() =>
          import('./ProgramManagement/projectsList'),
        )}
      />
      <Route
        exact={true}
        path={`${match.url}pda/project/:projectId`}
        component={asyncComponent(() => import('./PDS'))}
      />
      <Route
        exact={true}
        path={`${match.url}cpp`}
        component={asyncComponent(() => import('./CPP'))}
      />
      <Route
        exact={true}
        path={`${match.url}cpp/:projectId`}
        component={asyncComponent(() => import('./CPP/cppForm'))}
      />
      <Route
        exact={true}
        path={`${match.url}cpp/project/:projectId`}
        component={asyncComponent(() => import('./CPP/cppForm'))}
      />
      <Route
        exact={true}
        path={`${match.url}cpp/project/:projectId/document/:documentId`}
        component={asyncComponent(() => import('./CPP/cppForm'))}
      />
      <RestrictedRoute
        exact={true}
        path={`${match.url}estimator`}
        authUser={true}
        component={asyncComponent(() => import('./Estimator'))}
      />
      <RestrictedRoute
        exact={true}
        authUser={true}
        path={`${match.url}estimator/spaceplan/:clientId/:clientName/:projectId/:projectName`}
        component={asyncComponent(() => import('./Estimator/spacePlan'))}
      />
      <RestrictedRoute
        exact={true}
        authUser={true}
        path={`${match.url}estimator/projects/:clientId/:clientName`}
        component={asyncComponent(() => import('./Estimator/projects'))}
      />
      <RestrictedRoute
        exact={true}
        authUser={true}
        path={`${match.url}estimator/newproject/:clientId/:clientName`}
        component={asyncComponent(() => import('./Estimator/newProject'))}
      />
      <RestrictedRoute
        exact={true}
        authUser={true}
        path={`${match.url}estimator/newproject/:clientId/:clientName/:projectId`}
        component={asyncComponent(() => import('./Estimator/newProject'))}
      />
      <RestrictedRoute
        exact={true}
        authUser={true}
        path={`${match.url}estimator/newversion/:clientId/:clientName/:projectId`}
        component={asyncComponent(() => import('./Estimator/newProject'))}
      />
      <Route
        exact={true}
        path={`${match.url}projecttracker/dashboard`}
        component={asyncComponent(() => import('./ProjectTracker/dashboard'))}
      />
      <Route
        exact={true}
        path={`${match.url}admin/dataimport`}
        component={asyncComponent(() => import('./Admin/import'))}
      />
      <Route
        exact={true}
        path={`${match.url}myprojects`}
        component={withSegmentSelection(asyncComponent(() => import('./PrincipalLite/principalLitePage')))}
      />
      <Route
        exact={true}
        path={`${match.url}myprojects/project`}
        component={withSegmentSelection(asyncComponent(() => import('./PrincipalLite/principalLiteProject')))}
      />
      <Route
        exact={true}
        path={`${match.url}myprojects/project/:projectId`}
        component={withSegmentSelection(asyncComponent(() => import('./PrincipalLite/principalLiteProject')))}
      />
      <Route
        exact={true}
        path={`${match.url}myprojects/template/project/:projectId`}
        component={withSegmentSelection(asyncComponent(() => import('./PrincipalLite/principalLiteProject')))}
      />
      <Route
        exact={true}
        path={`${match.url}myprojects/project/:projectId/fundingrequest/:fundingRequestId`}
        component={withSegmentSelection(asyncComponent(() => import('./PrincipalLite/principalLiteProject')))}
      />
      {/* Route to view specific budget */}
      <Route
        exact={true}
        path={`${match.url}myprojects/project/:projectId/budget/:budgetId`}
        component={withSegmentSelection(asyncComponent(() => import('./PrincipalLite/principalLiteProject')))}
      />
      <Route
        exact={true}
        path={`${match.url}myprojects/newproject`}
        component={withSegmentSelection(asyncComponent(() => import('./PrincipalLite/principalLiteNewProject')))}
      />
      <Route
        exact={true}
        path={`${match.url}myprojects/newproject/:projectId`}
        component={withSegmentSelection(asyncComponent(() => import('./PrincipalLite/principalLiteNewProject')))}
      />
      <Route
        exact={true}
        path={`${match.url}myprojects/template/project`}
        component={withSegmentSelection(asyncComponent(() => import('./PrincipalLite/principalLiteProject')))}
      />
      <Route
        exact={true}
        path={`${match.url}download/:fileId`}
        component={asyncComponent(() =>
          import(
            './../modules/cbre-pjm-download/src/components/documentsDownload'
          ),
        )}
      />
      <Route
        exact={true}
        path={`${match.url}segment-selector`}
        render={(props) => <SegmentSelectorDialogWrapper open={false} projectOnlyAccess={projectOnlyAccess} firstStep={true} {...props} />}
      />
      {/* <Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))}/> */}
    </Switch>
  );
});

export default withRouter(Routes);
