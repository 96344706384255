import { SplitSdk, SplitFactoryProvider, SplitContext, useSplitTreatments } from '@splitsoftware/splitio-react';
import { useSelector } from 'react-redux';

const FeatureFlagProvider = (props) => {
  const { condition, children } = props;
  const { userEmailId } = useSelector(({ auth }) => auth);
  const { client } = useSelector(({ config }) => config);
  const { splitClientKey, enableFeatureFlag } = client || {};
  const factory =
    condition || enableFeatureFlag
      ? SplitSdk({
          core: {
            authorizationKey: splitClientKey,
            key: userEmailId?.toLowerCase() || 'anonymous',
          },
          debug: process.env.NODE_ENV === 'development',
        })
      : undefined;
  return condition || enableFeatureFlag? (
    <SplitFactoryProvider factory={factory} updateOnSdkUpdate={true}>
      {children}
    </SplitFactoryProvider>
  ) : (
    <>{children}</>
  );
};

const getFeatureFlag = (featureName, defaultValue, flagType, configObj) => {
    const { treatments, isReady } = useSplitTreatments({ names: [featureName], attributes: configObj || {} });
    const { treatment, config: treatmentConfig } = treatments[featureName];
    let flagValue;
    if(isReady && flagType?.toLowerCase() === 'json' && treatmentConfig){
        flagValue = JSON.parse(treatmentConfig);
    } else {
        flagValue = treatment;
    }
    return isReady ? flagValue : defaultValue;

};


export { getFeatureFlag, FeatureFlagProvider };
