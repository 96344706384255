
/* eslint-disable */
import {
  GET_ALL_LOOKUPS_SUCCESS,
  GET_ALL_LOOKUPS_FAILURE,
  GET_ALL_USER_ROLES_RESET,
  GET_ALL_USER_ROLES_SUCCESS,
  GET_ALL_USER_ROLES_FAILURE,
  RESET_SEARCH_ADD_USER,
  DISABLE_SEARCH_ADD_USER,
  START_SEARCH_ADD_USER,
  SUCCESS_SEARCH_ADD_USER,
  FAILURE_SEARCH_ADD_USER
} from './constants';

const INITIAL_STATE = {
  loading: false,
  error: false,
  approvalEditValues: [],
  messageCode: '',
  alert: '',
  alertMessage: '',
  userAlert: false,
  users: [],
  userEditValues: [],
  approvalUserRoleData: [],
  contactData: [],
  usersListMap : new Map(),

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DISABLE_SEARCH_ADD_USER:
      return {
        ...state,
      };
    case RESET_SEARCH_ADD_USER:
      return {
        ...state,
        error: false,
        messageCode: '',
        addUserSuccess: false,
      };
    case START_SEARCH_ADD_USER:
      return {
        ...state,
        loading: true,
        userEditValues: action.payload,
      };

    case SUCCESS_SEARCH_ADD_USER:
      return {
        ...state,
        userEditValues: action.payload,
        contactData: action.payload,

      };
    case FAILURE_SEARCH_ADD_USER:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        contactData: action.payload
      };

    case GET_ALL_USER_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        approvalUserRoleData: action.payload,
        usersListMap: action.payload,
      };

    case GET_ALL_LOOKUPS_FAILURE:
    case GET_ALL_USER_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload
      };

    case GET_ALL_USER_ROLES_RESET:
      return {
        ...state,
        loading: false,
        approvalUserRoleData: {},
      };

    default:
      return state;
  };

};
