import {
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  FETCH_COMMENTS_REQUEST,
  FETCH_COMMENTS_SUCCESS,
  FETCH_COMMENTS_FAILURE,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_FAILURE,
  RESET_COMMENTS,
  CURRENT_USER
} from './constants';

const INITIAL_STATE = {
  loading: false,
  data: [],
  error: null
};


export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false
      };
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case FETCH_COMMENTS_REQUEST:
    case ADD_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COMMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        comments: action.payload,
      };
    case ADD_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        comments: [...state.comments, action.payload],
      };
    case FETCH_COMMENTS_FAILURE:
    case ADD_COMMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_COMMENTS:
      return {
        ...state,
        comments: [],
        error: null,
      };
    case CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};