
/* eslint-disable */
import flatten from 'flat';
import frCanadaMessages from '../locales/fr_CA.json';
import cppProjectsListLanguage from '../../modules/cbre-pjm-cpp-projects-list/locales/fr_CA.json';
import cppBasicProjectInfoLanguage from '../../modules/cbre-pjm-cpp-basic-project-info/locales/fr_CA.json';
import cppFormRoot from '../../modules/cbre-pjm-cpp-form-root/locales/fr_CA.json';
import projectTrackerAddUserLanguage from '../../modules/cbre-pjm-project-tracker-new-project-add-user/locales/fr_CA.json';
import adminDataImport from '../../modules/cbre-pjm-admin-data-import/locales/fr_CA.json';
import ApprovalsLanguage from '../../modules/cbre-pjm-approvals/locales/fr_CA.json';
import documentLanguage from '../../modules/cbre-pjm-document/locales/fr_CA.json';
import reactandSubmitLanguage from '../../modules/cbre-pjm-pds-review/locales/fr_CA.json';
import jABasicProjectInfoLanguage from '../../modules/cbre-pjm-pds-basic-project-info/locales/fr_CA.json';
import costPlanInfoLanguage from '../../modules/cbre-pjm-pds-cost-plan/locales/fr_CA.json';
import pdsProjectOverviewLanguage from '../../modules/cbre-pjm-pds-project-overview/locales/fr_CA.json';
import pdsProjectRisksLanguage from '../../modules/cbre-pjm-pds-project-risks/locales/fr_CA.json';
import pdsApprovalsLanguage from '../../modules/cbre-pjm-pds-approvals/locales/fr_CA.json';
import pdsClientApprovalsLanguage from '../../modules/cbre-pjm-pds-client-approvals/locales/fr_CA.json';
import pdsMyActionsLanguage from '../../modules/cbre-pjm-pds-my-actions/locales/fr_CA.json';
import pdsFormRoot from '../../modules/cbre-pjm-pds-form-root/locales/fr_CA.json';
import jAProjectsListLanguage from '../../modules/cbre-pjm-ja-projects-list/locales/fr_CA.json';
import userManagementDetailsLanguage from '../../modules/cbre-pjm-user-management/locales/en_US.json';
import HierarchyAccessSearchAddUserTeamLanguage from '../../modules/cbre-pjm-hierarchy-access-search-add-user-team/locales/en_US.json';
import userManagementSearchAddUserLanguage from '../../modules/cbre-pjm-user-management-search-add-user/locales/fr_CA.json';
import createProgramLanguage from '../../modules/cbre-pjm-create-program/locales/fr_CA.json';
import ManageTeamsLanguage from '../../modules/cbre-pjm-manage-teams/locales/en_US.json';
import ManageTeamUsersLanguage from '../../modules/cbre-pjm-manage-team-users/locales/en_US.json';
import customFieldsManagementDetailsLanguage from '../../modules/cbre-pjm-custom-fields-management/locales/en_US.json';
import GridListLanguage from '../../modules/cbre-pjm-grid/locales/fr_CA.json';
// import principalLiteProjectCostPlan from '../../modules/principal-lite/cbre-pjm-principal-lite-project-cost-plan/locales/fr-CA.json';
// import principalLiteProjectCostPlan from '../../modules/principal-lite/cbre-pjm-principal-lite-project-cost-plan/locale';
import PrincipalLiteAddUserLanguage from '../../modules/principal-lite/cbre-pjm-principle-lite-add-user/locales/fr_CA.json';

const frCanadaLangMessages = flatten({
  ...frCanadaMessages,
  ...{ 'cbre-pjm-cpp-projects-list': cppProjectsListLanguage },
  ...{ 'cbre-pjm-cpp-basic-project-info': cppBasicProjectInfoLanguage },
  ...{ 'cbre-pjm-cpp-form-root': cppFormRoot },
  ...{ 'cbre-pjm-project-tracker-adduser': projectTrackerAddUserLanguage },
  ...{ 'cbre-pjm-admin-data-import': adminDataImport },
  ...{ 'cbre-pjm-approvals': ApprovalsLanguage },
  ...{ 'cbre-pjm-document': documentLanguage },
  ...{ 'cbre-pjm-pds-basic-project-info': jABasicProjectInfoLanguage },
  ...{ 'cbre-pjm-pds-cost-plan': costPlanInfoLanguage },
  ...{ 'cbre-pjm-pds-review': reactandSubmitLanguage },
  ...{ 'cbre-pjm-pds-project-overview': pdsProjectOverviewLanguage },
  ...{ 'cbre-pjm-pds-project-risks': pdsProjectRisksLanguage },
  ...{ 'cbre-pjm-pds-approvals': pdsApprovalsLanguage },
  ...{ 'cbre-pjm-pds-client-approvals': pdsClientApprovalsLanguage },
  ...{ 'cbre-pjm-pds-my-actions': pdsMyActionsLanguage },
  ...{ 'cbre-pjm-pds-form-root': pdsFormRoot },
  ...{ 'cbre-pjm-ja-projects-list': jAProjectsListLanguage },
  ...{ 'cbre-pjm-user-management': userManagementDetailsLanguage },
  ...{ 'cbre-pjm-user-management-search-add-user': userManagementSearchAddUserLanguage },
  ...{ 'cbre-pjm-create-program': createProgramLanguage },
  ...{ 'cbre-pjm-hierarchy-access-search-add-user-team': HierarchyAccessSearchAddUserTeamLanguage,},
  ...{ 'cbre-pjm-manage-teams': ManageTeamsLanguage},
  ...{ 'cbre-pjm-manage-team-users': ManageTeamUsersLanguage},
  ...{ 'cbre-pjm-custom-fields-management': customFieldsManagementDetailsLanguage },
  ...{ 'cbre-pjm-grid': GridListLanguage },
  ...{ 'cbre-pjm-principle-lite-add-user': PrincipalLiteAddUserLanguage },
  // ...{ 'cbre-pjm-principal-lite-project-cost-plan': principalLiteProjectCostPlan },

});
// https://formatjs.io/docs/core-concepts/basic-internationalization-principles#locales-language-and-region use only react Intl supported formats
const FrCanadaLang = {
  messages: frCanadaLangMessages,
  locale: 'fr-CA',
};
export default FrCanadaLang;
