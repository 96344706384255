export const COST_PLAN_DELETE_START = 'COST_PLAN_DELETE_START';
export const COST_PLAN_DELETE_SUCCESS = 'COST_PLAN_DELETE_SUCCESS';
export const COST_PLAN_DELETE_FAILURE = 'COST_PLAN_DELETE_FAILURE';
export const COST_PLAN_DELETE_RESET = 'COST_PLAN_DELETE_RESET';
export const COST_PLAN_STATE_UPDATE = 'COST_PLAN_STATE_UPDATE';
export const COST_PLAN_OBJ_REQUESTED = 'COST_PLAN_OBJ_REQUESTED';

export const GET_DEFAULT_WORKPACKAGE_START = 'GET_DEFAULT_WORKPACKAGE_START';
export const GET_DEFAULT_WORKPACKAGE_SUCCESS = 'GET_DEFAULT_WORKPACKAGE_SUCCESS';
export const GET_DEFAULT_WORKPACKAGE_FAILURE = 'GET_DEFAULT_WORKPACKAGE_FAILURE';
