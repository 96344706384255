export const SET_LOADING_TRUE = 'CBRE-PJM-KAHUA-DATA-MIGRATION/SET_LOADING_TRUE';
export const SET_SELECTED_JOB_ID = 'CBRE-PJM-KAHUA-DATA-MIGRATION/SET_SELECTED_JOB_ID';
export const SET_SELECTED_ITEM_ID = 'CBRE-PJM-KAHUA-DATA-MIGRATION/SET_SELECTED_ITEM_ID';
export const GET_MIGRATION_LIST_START = 'CBRE-PJM-KAHUA-DATA-MIGRATION/GET_MIGRATION_LIST_START';
export const GET_MIGRATION_LIST_FAILURE = 'CBRE-PJM-KAHUA-DATA-MIGRATION/GET_MIGRATION_LIST_FAILURE';
export const GET_MIGRATION_LIST_SUCCESS = 'CBRE-PJM-KAHUA-DATA-MIGRATION/GET_MIGRATION_LIST_SUCCESS';
export const GET_MIGRATION_DETAILS_START = 'CBRE-PJM-KAHUA-DATA-MIGRATION/GET_MIGRATION_DETAILS_START';
export const GET_MIGRATION_DETAILS_FAILURE = 'CBRE-PJM-KAHUA-DATA-MIGRATION/GET_MIGRATION_DETAILS_FAILURE';
export const GET_MIGRATION_DETAILS_SUCCESS = 'CBRE-PJM-KAHUA-DATA-MIGRATION/GET_MIGRATION_DETAILS_SUCCESS';
export const GET_MIGRATION_COMPLETE_SUCCESS = 'CBRE-PJM-KAHUA-DATA-MIGRATION/GET_MIGRATION_COMPLETE_SUCCESS';
export const GET_MIGRATION_COMPLETE_FAILURE = 'CBRE-PJM-KAHUA-DATA-MIGRATION/GET_MIGRATION_COMPLETE_FAILURE';
export const CLOSE_MIGRATION_COMPLETE_TOAST = 'CBRE-PJM-KAHUA-DATA-MIGRATION/CLOSE_MIGRATION_COMPLETE_TOAST';
export const GET_MIGRATION_STEP_START = 'CBRE-PJM-KAHUA-DATA-MIGRATION/GET_MIGRATION_STEP_START';
