
/* eslint-disable */
import {
  OPEN_MANAGE_IN_KAHUA_DIALOG,
  CLOSE_MANAGE_IN_KAHUA_DIALOG,
  DISABLE_MANAGE_IN_KAHUA,
  SELECT_MANAGE_IN_KAHUA,
} from './constants';

const INITIAL_STATE = {
  open: false,
  input: '',
  expanded: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_MANAGE_IN_KAHUA_DIALOG:
      return {
        ...state,
        open: true,
      };
    case CLOSE_MANAGE_IN_KAHUA_DIALOG:
      return {
        ...state,
        open: false,
        expanded: false,
      };
    case DISABLE_MANAGE_IN_KAHUA:
      return {
        ...state,
        input: 'no',
        open: false,
        expanded: false,
      };
    case SELECT_MANAGE_IN_KAHUA:
      return {
        ...state,
        input: 'yes',
        expanded: true,

      };
    default:
      return state;
  }
};
