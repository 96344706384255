/* eslint-disable no-template-curly-in-string */
const config = {
  Headcount: 'Headcount related to new space only (total # headcount).',
  SharingRatio: 'Ratio for identifying Seatcount based on mobility (i.e. 1 Seat = 1.1 Headcount).',
  SeatDensity: 'Rentable Square Foot Per Built Seat (RSF/Seat).',
  OfficevsWorkstation: 'Enclosed  Working (office) to Open Working (Workstation) working ratio.',
  CirculationFactor: 'The space allowing movement between the individual rooms/spaces.',
  LossFactor: 'Areas shared by all tenants in a multi-tenant building (lobby, restrooms, shared hallways, etc.).',
  Workspace: 'Individual work areas, including workstations and private offices.',
  CollaborativeSpace: 'Multi-user spaces that facilitate interactions and activities (e.g. Conference, Training Rooms).',
  CommonArea: 'Support areas and amenities (e.g. Receptions, Storage, Mail rooms and Wellness).',
  TotalRentableArea: 'Represents the entire area (footprint) that the tenant occupies and pays for, including shared spaces and non-usable space.',
  UsableOfficeSize: 'Represents the office space solely occupied and usable by the tenant.',
  UnAllocatedSpace: ' Un-allocated space is the balance of office footprint yet to be allocated to a specific function (as additional space is created within the Space Plan and allocated to the three main space categories, the balance of Un-Allocated space reduces towards zero).',
  NoteOne: 'Pricing is indicative only and intended to provide an early feasibility level estimate only. For more detailed budget guidance and estimating, contact the local or regional Cost Consultancy team',
  NoteTwo: 'All unit rates are inclusive of sales taxes relevant to the market selected',
  NoteThree: 'Excluded from the budget pricing are all Owner’s Costs, including physical IT hardware and equipment, plus internal resources (e.g. client project management, finance, workplace, etc.)',
  NoteFour: 'For more information, guidance or questions on assumptions and exclusions, please contact your Estimator super-user, or contact the local Cost Consultancy team',
  ZeroRentalableArea: 'Do you want to update the Space Plan before exporting the report? Click Yes to go back, No to continue.',
  ZeroCost: 'Do you want to update the Cost Estimate before exporting the report? Click Yes to go back, No to continue.',
  UnSavedNavigation: 'You have unsaved changes in the project. Please save the changes before exporting the report.',
  ClientUserExport: 'Export is restricted for modified values. Please go back to original configuration.',
  NetworkError: 'Network error.',
  MandatoryFields: 'Please provide inputs for all mandatory fields.',
  ErrorOccured: 'Error occured. Please try again.',
  SaveSuccessfully: 'Data saved successfully.',
  FileExtenXLS: 'Error: The selected file type is not supported. Please select the file with extension as - xls, xlsx.',
  DeleteMsgForProj: 'Do you really want to delete the project? Please select "Yes" to confirm or "No" to cancel.',
  ProjDelMsg: 'Project deleted successfully.',
  ErrorMsg: 'Some error occured.',
  StartDateErrorMsg: 'Start Date should be less than End Date.',
  EndtDateErrorMsg: 'End Date should be greater than Start Date.',
  ValidClientContactEmail: 'Please provide valid Client Contact Email Id. ',
  NewVersionCreated: 'New Version Created Successfully.',
  EditSuccess: 'Project Edit Successfully.',
  PrjCreateSucc: 'Project Created Successfully',
  UnsavedChange: 'There are unsaved changes present, do you still want to navigate?',
  FileUploadingError: 'File Uploading error.',
  FileSizeError: 'File type greater than 10mb. Please try uploading a file less than 10 m.b.',
  FileTypeExtError: 'Error: The selected file type is not supported. Please select the file with extension as - jpeg, png, xlsx, pptx, docx, .pdf.',
  AttachmentSaved: 'Attachment saved successfully.',
  AttachmentMaxSize: 'Attachment Maximum Size is 5.',
  DeleteFileMsg: 'Do you really want to delete the selected file? Please select "Yes" to confirm or "No" to cancel.',
  DeletedSuccessfully: 'Attachment Deleted Successfully.',
  ErrorOnFetching: 'Error in fetching Data',
  ProjectNotAvailable: 'Project not available.',
  ErrorOfficesWorkstations: 'Error: Offices and Workstations are allocated using the Office vs Workstation Percentage selected. Please select the required Office vs Workstation ratio.',
  UserAdded: 'User is already added.',
  DeleteUser: 'Do you really want to delete the User Role? Please select "Yes" to confirm or "No" to cancel.',
  SelectUserRole: 'Please select a role for the user.',
  SelectCategory: 'Please select the category.',
  LeavePage: 'Do you want to save your changes? Click Yes to go back and save, No to discard your changes.',
  HeadeCountValidation: 'Error: Headcount is a mandatory input and cannot be 0 or blank. Please provide a valid input.',
  SharingRatioValidation: 'Error: Sharing Ratio is a mandatory input and cannot be 0 or blank. Please provide a valid input.',
  SeatDensityValidation: 'Error: Target Seat Density is a mandatory input and cannot be 0 or blank. Please provide a valid input.',
  TargetSeatDensityValidation: 'Error: Target Seat Density is a mandatory input and cannot be 0 or blank. Please provide a valid input.',
  TargetSeatDensityValidationMax: 'Error: Target Seat Density ({ calculatedSeatDensity }) is greater than allowed Seat Density {seatDensityMax}. Please work on the space plan.',
  SpaceNameValidDescription: 'Error: Please Enter Valid Value in Description field of newly added item under {spaceName}.',
  SpaceNameValidQuantity: 'Error: Please Enter Valid Value in Quantity field of newly added item under {spaceName}.',
  SpaceNameValidDimensionL: 'Error: Please Enter Valid Value in Dimension (L) field of newly added item under {spaceName}.',
  SpaceNameValidDimensionW: 'Error: Please Enter Valid Value in Dimension (W) field of newly added item under {spaceName}.',
  UnabaleSaveSpacePlan: 'Unable Save Space Plan. Please Try Again.',
  OfficesWorkstationRatio: 'Error: Offices and Workstations are allocated using the Office vs Workstation Percentage selected. Please select the required Office vs Workstation ratio.',
  CostEstimateSaveError: 'Unable Save CostEstimate. Please Try Again.',
  ScheduleSaveError: 'Unable Save Schedule Chart. Please Try Again.',
  SaveProjectDetails: 'Project details have been saved successfully.',
  DeleteItem: 'Do you really want to delete the selected item? Please select "Yes" to confirm or "No" to cancel.',
  SpacePlanErrorText: 'Note:Target Seat Density does not match calculated Seat Density ',
  RendableAreaErrorText: 'Error: Total Rentable Area cannot be greater than {total}',
  validationErrorMessage: 'Error: {field} is a mandatory input and cannot be 0 or blank. Please enter a valid value.',
  headCountErrorKey: 'Headcount',
  SharingRatioErrorKey: 'Sharing Ratio',
  SeatDensityErrorKey: 'Target Seat Density',
  defaultErrorKey: 'Default',
};

// eslint-disable-next-line no-underscore-dangle
export const _ProjectConfig = {
  mandatoryInputs: [
    {
      valueType: 'headCount',
      max: 5000,
      min: 0,
      step: 10,
      headerText: 'Headcount',
    },
    {
      valueType: 'sharingRatio',
      max: 2,
      min: 0,
      step: 0.01,
      headerText: 'Sharing/Mobility Ratio',
    },
    {
      valueType: 'seatDensity',
      headerText: 'Target Seat Density',
    },
  ],
  optionalInputs: [
    {
      headerText: 'Office vs Workstation',
      valueType: 'workSpaceRatio',
      tailText: '(%)',
      min: 0,
      max: 30,
      step: 1,
    },
    {
      headerText: 'Circulation Factor',
      valueType: 'circulationFactor',
      tailText: '(%)',
      min: 0,
      max: 50,
      step: 1,
    },
    {
      headerText: 'Loss Factor (Common Area Factor)',
      valueType: 'lossFactor',
      tailText: '(%)',
      min: 0,
      max: 40,
      step: 1,
    },
  ],
};

export default config;
