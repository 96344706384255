import enLang from './entries/en-US';
import zhLang from './entries/zh-Hans-CN';
import gbLang from './entries/en-GB';
import frLang from './entries/fr-FR';
import deLang from './entries/de-DE';
import esLang from './entries/es-ES';
import heLang from './entries/he-IL';
import itLang from './entries/it-IT';
import jaLang from './entries/ja-JP';
import nlbeLang from './entries/nl-BE';
import nlnlLang from './entries/nl-NL';
import plLang from './entries/pl-PL';
import svLang from './entries/sv-SE';
import ptLang from './entries/pt-PT';
import ptbrLang from './entries/pt-BR';
import esLtLang from './entries/es-LA';
import frcaLang from './entries/fr-CA';
import krLang from './entries/ko-KR';
import thlang from './entries/th-TH';
import viLang from './entries/vi-VN';

const AppLocale = {
  en_US: enLang,
  zh_CN: zhLang,
  en_GB: gbLang,
  fr_FR: frLang,
  de_DE: deLang,
  es_ES: esLang,
  he_IL: heLang,
  it_IT: itLang,
  ja_JP: jaLang,
  nl_BE: nlbeLang,
  nl_NL: nlnlLang,
  pl_PL: plLang,
  sv_SE: svLang,
  pt_PT: ptLang,
  pt_BR: ptbrLang,
  es_LA: esLtLang,
  fr_CA: frcaLang,
  ko_KR: krLang,
  th_TH: thlang,
  vi_VN: viLang,
};

export default AppLocale;
