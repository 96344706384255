
/* eslint-disable */
import {
  OPEN_MOVE_PROGRAM_DIALOG,
  CLOSE_MOVE_PROGRAM_DIALOG,
  MOVE_PROGRAM_START,
  MOVE_PROGRAM_SUCCESS,
  MOVE_PROGRAM_FAILURE,
  CLOSE_MOVE_PROGRAM_TOAST,
  OPEN_MOVE_PROGRAM_DIALOG_SUCCESS,
  OPEN_MOVE_PROGRAM_DIALOG_FAILURE,
  UPDATE_PLAN_ID,
  ENABLE_MOVE_PROGRAM,
  UPDATE_PLAN_NAME,
} from '../constants';

const INIT_STATE = {
  open: false,
  mode: '',
  loading: false,
  error: false,
  enabled: false,
  errorCode: '',
  alert: '',
  alertMessage: '',
  programId: '',
  planId: '',
  planName: '',
  programTitle: '',
  moveDialogOpen: false,
  defaultValues: {},
  projectsGridRenderCount: 0,
  showDeleteToaster: false,
  handleAlertClose: () => { },
  routeChangeFlag: false,
  isPipelineProject: false,
  redirectUrl:''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPEN_MOVE_PROGRAM_DIALOG:
      return {
        ...state,
        ...action.payload,
        open: true,
        mode: 'move',
        programId: action.payload.programId,
        enabled: action.payload.enabled,
        routeChangeFlag: action.payload.routeChangeFlag,
        isPipelineProject: action.payload.isPipelineProject,
        planId: action.payload.planId,
        redirectUrl:action.payload.redirectUrl,
      };
    case OPEN_MOVE_PROGRAM_DIALOG_SUCCESS:
      return {
        ...state,
        open: true,
        mode: 'move',
        programId: action.payload.programId,
        deleteDialogOpen: true,
      };
    case OPEN_MOVE_PROGRAM_DIALOG_FAILURE:
      return {
        ...state,
        open: false,
        mode: '',
      };
    case CLOSE_MOVE_PROGRAM_DIALOG:
      return {
        ...state,
        open: false,
        mode: '',
        programId: '',
        moveDialogOpen: false,
        redirectUrl: '',
      };
    case MOVE_PROGRAM_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case MOVE_PROGRAM_SUCCESS:

      return {
        ...state,
        loading: false,
        alert: 'success',
        alertMessage: action.payload.moveProgramMsgSuccess,
        open: false,
        deleteDialogOpen: false,
        projectsGridRenderCount: state.projectsGridRenderCount + 1,
        showMoveToaster: true,
        handleAlertClose: action.payload.handleAlertClose,
      };
    case MOVE_PROGRAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
        alert: 'failure',
        // alertMessage: 'The project "'+action.payload.programTitle+'" was not deleted due to error code '+action.payload.errorCode,
        alertMessage: action.payload.moveProgramMsgFailure,
        open: false,
        deleteDialogOpen: false,
        showDeleteToaster: true,
        handleAlertClose: action.payload.handleAlertClose,
      };
    case CLOSE_MOVE_PROGRAM_TOAST:
      return {
        ...state,
        alert: 'close',
        alertMessage: '',
        moveDialogOpen: false,
      };
    case UPDATE_PLAN_ID:
      return {
        ...state,
        planId: action.payload
      };
    case UPDATE_PLAN_NAME:
      return {
        ...state,
        planName: action.payload
      };
    case ENABLE_MOVE_PROGRAM:
      return {
        ...state,
        enabled: action.payload
      }
    default:
      return state;
  }
};
