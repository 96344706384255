export const ENABLE_PLAN_EDIT =
  'CBRE-PJM-CAPITAL-PLAN-PLANS-LIST/ENABLE_PLAN_EDIT';
export const ENABLE_PLAN_DELETE =
  'CBRE-PJM-CAPITAL-PLAN-PLANS-LIST/ENABLE_PLAN_DELETE';
export const GET_CLIENT_FILTER_SUCCESS =
  'CBRE-PJM-CAPITAL-PLAN-PLANS-LIST/GET_CLIENT_FILTER_SUCCESS';
export const GET_CLIENT_FILTER_FAILURE =
  'CBRE-PJM-CAPITAL-PLAN-PLANS-LIST/GET_CLIENT_FILTER_FAILURE';
export const GET_CLIENT_FILTER_RESET =
  'CBRE-PJM-CAPITAL-PLAN-PLANS-LIST/GET_CLIENT_FILTER_RESET';
export const GET_CLIENT_FILTER_START =
  'CBRE-PJM-CAPITAL-PLAN-PLANS-LIST/GET_CLIENT_FILTER_START';
export const UPDATE_CP_TAB_COUNT =
  'CBRE-PJM-CAPITAL-PLAN-PLANS-LIST/UPDATE_CP_TAB_COUNT';
export const MODULE = 'cbre-pjm-approval-configuration';
export const GET_CLIENT_SUCCESS =
  'CBRE-PJM-APPROVAL-CONFIGURATION/GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAILURE =
  'CBRE-PJM-APPROVAL-CONFIGURATION/GET_CLIENT_FAILURE';
export const GET_CLIENT_RESET =
  'CBRE-PJM-APPROVAL-CONFIGURATION/GET_CLIENT_RESET';
export const GET_CLIENT_START =
  'CBRE-PJM-APPROVAL-CONFIGURATION/GET_CLIENT_START';
export const GET_ALL_LOOKUPS_SUCCESS =
  'CBRE-PJM-APPROVAL-CONFIGURATION/GET_ALL_LOOKUPS_SUCCESS';
export const GET_ALL_LOOKUPS_FAILURE =
  'CBRE-PJM-APPROVAL-CONFIGURATION/GET_ALL_LOOKUPS_FAILURE';
export const GET_HIERARCHY = 'CBRE-PJM-APPROVAL-CONFIGURATION/GET_HIERARCHY';
export const GET_HIERARCHY_SUCCESS =
  'CBRE-PJM-APPROVAL-CONFIGURATION/GET_HIERARCHY_SUCCESS';
export const GET_HIERARCHY_FAILURE =
  'CBRE-PJM-APPROVAL-CONFIGURATION/GET_HIERARCHY_FAILURE';
export const GET_APPROVALCONFIGDETAILS =
  'CBRE-PJM-APPROVAL-CONFIGURATION/GET_APPROVALCONFIGDETAILS';
export const GET_APPROVALCONFIGDETAILS_SUCCESS =
  'CBRE-PJM-APPROVAL-CONFIGURATION/GET_APPROVALCONFIGDETAILS_SUCCESS';
export const GET_APPROVALCONFIGDETAILS_FAILURE =
  'CBRE-PJM-APPROVAL-CONFIGURATION/GET_APPROVALCONFIGDETAILS_FAILURE';
export const GET_APPROVE_OPTIONS = 'CBRE-PJM-APPROVAL-CONFIGURATION/GET_APPROVE_OPTIONS';
export const GET_APPROVE_OPTIONS_SUCCESS =
  'CBRE-PJM-APPROVAL-CONFIGURATION/GET_APPROVE_OPTIONS_SUCCESS';
export const GET_APPROVE_OPTIONS_FAILURE =
  'CBRE-PJM-APPROVAL-CONFIGURATION/GET_APPROVE_OPTIONS_FAILURE';
