export const GET_CPP_LIST_FILTER_SUCCESS = 'CBRE-PJM-CPP-PROJECTS-LIST/GET_FILTER_SUCCESS';
export const GET_CPP_LIST_FILTER_FAILURE = 'CBRE-PJM-CPP-PROJECTS-LIST/GET_FILTER_FAILURE';
export const GET_CPP_LIST_FILTER_RESET = 'CBRE-PJM-CPP-PROJECTS-LIST/GET_FILTER_RESET';
export const GET_CPP_DIVISION_FILTER_SUCCESS = 'CBRE-PJM-CPP-PROJECTS-LIST/GET_DIVISION_FILTER_SUCCESS';
export const GET_CPP_DIVISION_FILTER_FAILURE = 'CBRE-PJM-CPP-PROJECTS-LIST/GET_DIVISION_FILTER_FAILURE';
export const GET_CPP_DIVISION_FILTER_RESET = 'CBRE-PJM-CPP-PROJECTS-LIST/GET_DIVISION_FILTER_RESET';
export const OPEN_DELETE_CPP_DIALOG = 'CBRE-PJM-CPP-PROJECTS-LIST/OPEN_DELETE_CPP_DIALOG';
export const OPEN_DELETE_CPP_DIALOG_SUCCESS = 'CBRE-PJM-CPP-PROJECTS-LIST/OPEN_DELETE_CPP_DIALOG_SUCCESS';
export const OPEN_DELETE_CPP_DIALOG_FAILURE = 'CBRE-PJM-CPP-PROJECTS-LIST/OPEN_DELETE_CPP_DIALOG_FAILURE';
export const DELETE_CPP_START = 'CBRE-PJM-CPP-PROJECTS-LIST/DELETE_CPP_START';
export const DELETE_CPP_SUCCESS = 'CBRE-PJM-CPP-PROJECTS-LIST/DELETE_CPP_SUCCESS';
export const DELETE_CPP_FAILURE = 'CBRE-PJM-CPP-PROJECTS-LIST/DELETE_CPP_FAILURE';
export const CLOSE_DELETE_CPP_TOAST = 'CBRE-PJM-CPP-PROJECTS-LIST/CLOSE_DELETE_CPP_TOAST';
export const CLOSE_DELETE_CPP_DIALOG = 'CBRE-PJM-CPP-PROJECTS-LIST/CLOSE_DELETE_CPP_DIALOG';
