export const OPEN_DIALOG = 'CBRE-PJM-NEWCLIENT/OPEN_DIALOG';
export const CLOSE_DIALOG = 'CBRE-PJM-NEWCLIENT/CLOSE_DIALOG';
export const ADD_CLIENT_START = 'CBRE-PJM-NEWCLIENT/ADD_CLIENT_START';
export const ADD_CLIENT_SUCCESS = 'CBRE-PJM-NEWCLIENT/ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_FAILURE = 'CBRE-PJM-NEWCLIENT/ADD_CLIENT_FAILURE';
export const ADD_CLIENT_EXISTS_FAILURE = 'CBRE-PJM-NEWCLIENT/ADD_CLIENT_EXISTS_FAILURE';
export const RESET_CLIENT_EXISTS_FAILURE = 'CBRE-PJM-NEWCLIENT/RESET_CLIENT_EXISTS_FAILURE';
export const CLOSE_TOAST = 'CBRE-PJM-NEWCLIENT/CLOSE_TOAST';
export const GET_ALL_LOOKUPS_SUCCESS = 'CBRE-PJM-NEWCLIENT/GET_ALL_LOOKUPS_SUCCESS';
export const GET_ALL_LOOKUPS_FAILURE = 'CBRE-PJM-NEWCLIENT/GET_ALL_LOOKUPS_FAILURE';
export const LOGO_UPLOAD_ADD_CLIENT_START = 'LOGO_UPLOAD_ADD_CLIENT_START';
export const LOGO_UPLOAD_ADD_CLIENT_SUCCESS = 'LOGO_UPLOAD_ADD_CLIENT_SUCCESS';
export const LOGO_UPLOAD_ADD_CLIENT_ERROR = 'LOGO_UPLOAD_ADD_CLIENT_ERROR';
export const EDIT_CLIENT_START = 'EDIT_CLIENT_START';
export const EDIT_CLIENT_SUCCESS = 'EDIT_CLIENT_SUCCESS';
export const EDIT_CLIENT_FAILURE = 'EDIT_CLIENT_FAILURE';
export const EDIT_CLIENT_CLICK = 'EDIT_CLIENT_CLICK';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE';
export const UPDATE_CLIENT_START = 'UPDATE_CLIENT_START';
export const RESET_CLIENT_INFO = 'RESET_CLIENT_INFO';
export const RESET_CLIENT_LOGO = 'RESET_CLIENT_LOGO';
