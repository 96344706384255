import {
  ADD_CLIENTS_TO_STATE,
  ADD_CLIENTS_START,
} from './constants';

const initialState = {
  clientsList: [],
  loading: true,
};

const ProjectInfo = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_CLIENTS_TO_STATE: {
      return {
        ...state,
        loading: false,
        clientsList: action.payload?.data?.recordsList,
        totalRecords: action.payload?.data?.totalRecords,
      };
    }
    case ADD_CLIENTS_START: {
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export default ProjectInfo;
