
/* eslint-disable */
import {
  GET_ESTIMATOR_PROJECTS_LIST_FILTER_SUCCESS,
  GET_ESTIMATOR_PROJECTS_LIST_FILTER_FAILURE,
  GET_ESTIMATOR_PROJECTS_LIST_FILTER_RESET,

  OPEN_DELETE_ESTIMATOR_DIALOG,
  CLOSE_DELETE_ESTIMATOR_DIALOG,
  DELETE_ESTIMATOR_START,
  DELETE_ESTIMATOR_SUCCESS,
  DELETE_ESTIMATOR_FAILURE,
  CLOSE_DELETE_ESTIMATOR_TOAST,
  OPEN_DELETE_ESTIMATOR_DIALOG_SUCCESS,
  OPEN_DELETE_ESTIMATOR_DIALOG_FAILURE,

} from './constants';

const INITIAL_STATE = {
  filterHdrDetails: {},
  error: false,
  errorCode: '',
  selectedProjectId: '',
  open: false,
  mode: '',
  loading: false,
  alertMessage: '',
  projectId: '',
  projectTitle: '',
  defaultValues: {},
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case GET_ESTIMATOR_PROJECTS_LIST_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        filterHdrDetails: action.payload,
      };
    case GET_ESTIMATOR_PROJECTS_LIST_FILTER_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        errorCode: action.payload,
      };
    case GET_ESTIMATOR_PROJECTS_LIST_FILTER_RESET:
      return {
        ...state,
        loading: false,
        filterHdrDetails: {},
      };

         /* Project Delete */
    case OPEN_DELETE_ESTIMATOR_DIALOG:
      return {
        ...state,
        open: true,
        mode: 'delete',
        projectId: action.payload.projectId,
        enabled: action.payload.enabled,
        routeChangeFlag: action.payload.routeChangeFlag,        
      };

    case OPEN_DELETE_ESTIMATOR_DIALOG_SUCCESS:
      return {
        ...state,
        open: true,
        mode: 'delete',
        projectId: action.payload.projectId,
        deleteDialogOpen: true,
      };
      
    case OPEN_DELETE_ESTIMATOR_DIALOG_FAILURE:
      return {
        ...state,
        open: false,
        mode: '',
      };
    case CLOSE_DELETE_ESTIMATOR_DIALOG:
      return {
        ...state,
        open: false,
        mode: '',
        projectId: '',
        deleteDialogOpen: false,
      };
    case DELETE_ESTIMATOR_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case DELETE_ESTIMATOR_SUCCESS:

      return {
        ...state,
        loading: false,
        alertDeleteProject: 'success',
        alertMessage: action.payload.deleteProjectMsgSuccess,
        open: false,
        deleteDialogOpen: false,
        projectsGridRenderCount: state.projectsGridRenderCount + 1,
        showDeleteToaster: true,
        handleAlertClose: action.payload.handleAlertClose,
      };
    case DELETE_ESTIMATOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
        alertDeleteProject: 'failure',
        alertMessage: action.payload.deleteProjectMsgFailure,
        open: false,
        deleteDialogOpen: false,
        showDeleteToaster: true,
        handleAlertClose: action.payload.handleAlertClose,
      };
    case CLOSE_DELETE_ESTIMATOR_TOAST:
      return {
        ...state,
        alertDeleteProject: 'close',
        alertMessage: '',
        deleteDialogOpen: false,
      };
    default:
      return state;
  }
};
