
/* eslint-disable */
import {
  OPEN_EMAIL_AUTHENTICATION_DIALOG,
  CLOSE_EMAIL_AUTHENTICATION_DIALOG,
  DISABLE_EMAIL_AUTHENTICATION,
  RESET_EMAIL_AUTHENTICATION,
  START_EMAIL_AUTHENTICATION,
  FAILURE_EMAIL_AUTHENTICATION,
  SUCCESS_EMAIL_AUTHENTICATION,
  DUPLICATE_EMAIL_AUTHENTICATION,
  CLOSE_TOAST_EMAIL_AUTHENTICATION,
} from './constants';

const INITIAL_STATE = {
  open: false,
  loading: false,
  error: false,
  messageCode: '',
  alert: '',
  alertMessage: '',
  isAuthenticated: false,

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_EMAIL_AUTHENTICATION_DIALOG:
      return {
        ...state,
        open: true,
      };
    case CLOSE_EMAIL_AUTHENTICATION_DIALOG:
      return {
        ...state,
        open: false,
        ...action.payload,
      };
    case DISABLE_EMAIL_AUTHENTICATION:
      return {
        ...state,
        open: false,
      };
    case RESET_EMAIL_AUTHENTICATION:
      return {
        ...state,
        error: false,
        messageCode: '',
      };
    case START_EMAIL_AUTHENTICATION:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };

    case SUCCESS_EMAIL_AUTHENTICATION:
      return {
        ...state,
        loading: false,
        open: false,
        alert: 'success',
        ...action.payload,

      };
    case FAILURE_EMAIL_AUTHENTICATION:
      return {
        ...state,
        loading: false,
        error: true,

      };
    case DUPLICATE_EMAIL_AUTHENTICATION:
      return {
        ...state,
        loading: false,
        error: true,
        ...action.payload,

      };
    case CLOSE_TOAST_EMAIL_AUTHENTICATION:
      return {
        ...state,
        alert: 'close',
        alertMessage: '',
        ...action.payload,

      };
    default:
      return state;
  }
};
