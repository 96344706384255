export const GET_PDA_LIST_SUCCESS = 'CBRE-PJM-PDA-FORM-ROOT/GET_PDA_LIST_SUCCESS';
export const GET_PDA_LIST_FAILURE = 'CBRE-PJM-PDA-FORM-ROOT/GET_PDA_LIST_FAILURE';
export const GET_PDA_LIST_START = 'CBRE-PJM-PDA-FORM-ROOT/GET_PDA_LIST_START';
export const GET_PDA_LIST_RESET = 'CBRE-PJM-PDA-FORM-ROOT/GET_PDA_LIST_RESET';
export const GET_PDA_PROJECT_DETAILS_RESET = 'CBRE-PJM-PDA-FORM-ROOT/GET_PDA_PROJECT_DETAILS_RESET';
export const GET_VENDOR_LIST_SUCCESS = 'CBRE-PJM-PDA-FORM-ROOT/GET_VENDOR_LIST_SUCCESS';
export const GET_VENDOR_LIST_FAILURE = 'CBRE-PJM-PDA-FORM-ROOT/GET_VENDOR_LIST_FAILURE';
export const GET_VENDOR_LIST_START = 'CBRE-PJM-PDA-FORM-ROOT/GET_VENDOR_LIST_START';
export const GET_VENDOR_LIST_RESET = 'CBRE-PJM-PDA-FORM-ROOT/GET_VENDOR_LIST_RESET';
export const GET_APPROVERS_LIST_SUCCESS = 'CBRE-PJM-PDA-FORM-ROOT/GET_APPROVERS_LIST_SUCCESS';
export const GET_APPROVERS_LIST_FAILURE = 'CBRE-PJM-PDA-FORM-ROOT/GET_APPROVERS_LIST_FAILURE';
export const GET_APPROVERS_LIST_START = 'CBRE-PJM-PDA-FORM-ROOT/GET_APPROVERS_LIST_START';
export const GET_APPROVERS_LIST_RESET = 'CBRE-PJM-PDA-FORM-ROOT/GET_APPROVERS_LIST_RESET';
