export const GET_TAGS = 'cbre-pjm-project-tagging/GET_TAGS';
export const GET_TAGS_START = 'cbre-pjm-project-tagging/GET_TAGS_START';
export const GET_TAGS_SUCCESS = 'cbre-pjm-project-tagging/GET_TAGS_SUCCESS';
export const GET_TAGS_FAILURE = 'cbre-pjm-project-tagging/GET_TAGS_FAILURE';
export const SUBMIT_TAGS = 'cbre-pjm-project-tagging/SUBMIT_TAGS';
export const SUBMIT_TAGS_START = 'cbre-pjm-project-tagging/SUBMIT_TAGS_START';
export const SUBMIT_TAGS_SUCCESS = 'cbre-pjm-project-tagging/SUBMIT_TAGS_SUCCESS';
export const SUBMIT_TAGS_FAILURE = 'cbre-pjm-project-tagging/SUBMIT_TAGS_FAILURE';
export const GET_TAGS_DROPDOWN = 'cbre-pjm-project-tagging/GET_TAGS_DROPDOWN';
export const GET_TAGS_DROPDOWN_START = 'cbre-pjm-project-tagging/GET_TAGS_DROPDOWN_START';
export const GET_TAGS_DROPDOWN_SUCCESS = 'cbre-pjm-project-tagging/GET_TAGS_DROPDOWN_SUCCESS';
export const GET_TAGS_DROPDOWN_FAILURE = 'cbre-pjm-project-tagging/GET_TAGS_DROPDOWN_FAILURE';
export const TAGGING_RESET = 'cbre-pjm-project-tagging/TAGGING_RESET';
