import React, { useState, useEffect, useContext } from 'react';
import {
  Drawer,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router';
import { UserHierarchyAccessContext } from 'containers/Services/UserHierarchyAccessProvider';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 380,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    flexGrow: 1,
  },
  formControl: {
    width: '90%',
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
}));

const OrganizationDrawer = ({ open, onClose }) => {
  const {
    segmentArray,
    clientArray,
    selectedSegment,
    selectedClient,
    handleSegmentChange,
    handleClientChange,
  } = useContext(UserHierarchyAccessContext);
  const history = useHistory();
  const classes = useStyles();
  const [activeClient, setActiveClient] = useState(selectedClient?.clientId);
  const [activeSegment, setActiveSegment] = useState(
    selectedSegment?.uniqueCode,
  );

  useEffect(() => {
    setActiveSegment(selectedSegment?.uniqueCode);
    setActiveClient(selectedClient?.clientId);
  }, [selectedSegment, selectedClient]);

  const segmentChangeHandler = (event) => {
    setActiveSegment(event.target.value);
  };

  const clientChangeHandler = (event) => {
    setActiveClient(event.target.value);
  };

  const handleApply = () => {
    const updatedSegments = segmentArray.map((segment) => {
      if (segment.uniqueCode === activeSegment) {
        return { ...segment, isSelected: true };
      } else {
        return { ...segment, isSelected: false };
      }
    });
    handleSegmentChange(updatedSegments);

    const activeClientObj = clientArray.find(
      (client) => client.clientId === activeClient,
    );
    handleClientChange(activeClientObj);
    onClose();

    // Clearing widgetFilters from localStorage on global Segment or Client change
    localStorage.removeItem('widgetFilters');

    history.push('/myprojects');
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawer }}
    >
      <div className={classes.drawerContent}>
        <div className={classes.header}>
          <div>Organization</div>
          <IconButton onClick={onClose} aria-label="Close drawer">
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.content}>
          {segmentArray && segmentArray.length > 1 && (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="segment-label">Segments</InputLabel>
              <Select
                labelId="segment-label"
                id="segment-select"
                value={activeSegment}
                onChange={segmentChangeHandler}
                label="Segments"
              >
                {segmentArray.map((segment) => (
                  <MenuItem key={segment.id} value={segment.uniqueCode}>
                    {segment.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {activeSegment &&
            clientArray &&
            activeSegment === 'EA' &&
            clientArray.length > 1 && (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="client-label">Clients</InputLabel>
                <Select
                  labelId="client-label"
                  id="client-select"
                  value={activeClient}
                  onChange={clientChangeHandler}
                  label="Clients"
                >
                  {clientArray.map((client) => (
                    <MenuItem key={client.clientId} value={client.clientId}>
                      {client.clientName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
        </div>
        <div className={classes.footer}>
          <Button
            variant="outlined"
            onClick={onClose}
            className={classes.cancelButton}
            aria-label="Cancel"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApply}
            aria-label="Apply"
          >
            Apply
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default OrganizationDrawer;
