
/* eslint-disable */
import {
  OPEN_DELETE_PROJECT_DIALOG,
  CLOSE_DELETE_PROJECT_DIALOG,
  DELETE_PROJECT_START,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  CLOSE_DELETE_PROJECT_TOAST,
  OPEN_DELETE_PROJECT_DIALOG_SUCCESS,
  OPEN_DELETE_PROJECT_DIALOG_FAILURE,

} from '../constants';

const INIT_STATE = {
  apiUrl:'',
  open: false,
  mode: '',
  loading: false,
  error: false,
  errorCode: '',
  alert: '',
  alertMessage: '',
  projectId: '',
  projectTitle: '',
  deleteDialogOpen: false,
  defaultValues: {},
  projectsGridRenderCount: 0,
  showDeleteToaster: false,
  handleAlertClose: () => {},
  routeChangeFlag: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPEN_DELETE_PROJECT_DIALOG:
      return {
        ...state,
        open: true,
        mode: 'delete',
        projectId: action.payload.projectId,
        enabled: action.payload.enabled,
        apiUrl:action.payload.apiUrl,
        routeChangeFlag: action.payload.routeChangeFlag,
      };
    case OPEN_DELETE_PROJECT_DIALOG_SUCCESS:
      return {
        ...state,
        open: true,
        mode: 'delete',
        projectId: action.payload.projectId,
        deleteDialogOpen: true,
        apiUrl:'',
      };
    case OPEN_DELETE_PROJECT_DIALOG_FAILURE:
      return {
        ...state,
        open: false,
        mode: '',
      };
    case CLOSE_DELETE_PROJECT_DIALOG:
      return {
        ...state,
        open: false,
        mode: '',
        projectId: '',
        apiUrl: '',
        deleteDialogOpen: false,
      };
    case DELETE_PROJECT_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case DELETE_PROJECT_SUCCESS:

      return {
        ...state,
        loading: false,
        alert: 'success',
        apiUrl:'',
        // alertMessage: 'The project "'+action.payload.projectTitle+'" has been deleted successfully',
        alertMessage: action.payload.deleteProjectMsgSuccess,
        open: false,
        deleteDialogOpen: false,
        projectsGridRenderCount: state.projectsGridRenderCount + 1,
        showDeleteToaster: true,
        handleAlertClose: action.payload.handleAlertClose,
      };
    case DELETE_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
        alert: 'failure',
        // alertMessage: 'The project "'+action.payload.projectTitle+'" was not deleted due to error code '+action.payload.errorCode,
        alertMessage: action.payload.deleteProjectMsgFailure,
        open: false,
        deleteDialogOpen: false,
        showDeleteToaster: true,
        handleAlertClose: action.payload.handleAlertClose,
      };
    case CLOSE_DELETE_PROJECT_TOAST:
      return {
        ...state,
        alert: 'close',
        alertMessage: '',
        deleteDialogOpen: false,
      };
    default:
      return state;
  }
};
