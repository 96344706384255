
/* eslint-disable */
import {
  UPDATE_CP_TAB_COUNT,
  GET_CLIENT_RESET,
  GET_CLIENT_FAILURE,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_START,
  GET_ALL_LOOKUPS_SUCCESS,
  GET_HIERARCHY_SUCCESS,
  GET_HIERARCHY,
  GET_HIERARCHY_FAILURE,
  GET_APPROVE_OPTIONS_SUCCESS,
  GET_APPROVE_OPTIONS,
  GET_APPROVE_OPTIONS_FAILURE,
  GET_APPROVALCONFIGDETAILS_SUCCESS,
  GET_APPROVALCONFIGDETAILS,
  GET_APPROVALCONFIGDETAILS_FAILURE,
} from './constants';

const INIT_STATE = {
  selectedPlanId: '',
  selectedPlanEditClickCount: 0,
  key: 'plans',
  clientFilterDetails: {},
  clientDetails: {},
  error: false,
  errorCode: '',
  loading: true,
  planListCount: 0,
  pipelineListCount: 0,
  allLegacy: [],
  hierarchyDetails: [],
  approvalConfigDetails: [],
  approvalConfigOptions: []

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLIENT_START:
      return {
        ...state,
        loading: true,
      };

    case GET_CLIENT_RESET:
      return {
        ...state,
        loading: false,
        clientDetails: {}
      };
    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        clientDetails: action.payload,
        totalRecords: action.payload.totalRecords,
        pageIndex: action.payload.pageIndex
      };
    case GET_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload
      };

    case UPDATE_CP_TAB_COUNT:
      return {
        ...state,
        ...action.payload,
      };
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        allLegacy: action.payload,
        loading: false,
        fetchLookupsStatus: 'success',
      };
    case GET_HIERARCHY_SUCCESS:
      console.log(action);
      return {
        ...state,
        loading: false,
        error: false,
        hierarchyDetails: action.payload,
        totalRecords: action.payload.length
      };
    case GET_HIERARCHY:
      return {
        ...state,
        loading: true,
        error: '',
        ...action.payload
      };
    case GET_HIERARCHY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        alertSuccess: 'failure',
        errorCode: action.payload
      };
    case GET_APPROVE_OPTIONS_SUCCESS:
      console.log(action);
      return {
        ...state,
        loading: false,
        error: false,
        approvalConfigOptions: action.payload,
        totalRecords: action.payload.length
      };
    case GET_APPROVE_OPTIONS:
      return {
        ...state,
        loading: true,
        error: '',
        ...action.payload
      };
    case GET_APPROVE_OPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        alertSuccess: 'failure',
        errorCode: action.payload
      };
      case GET_APPROVALCONFIGDETAILS_SUCCESS:
        console.log(action);
        return {
          ...state,
          loading: false,
          error: false,
          approvalConfigDetails: action.payload,
          totalRecords: action.payload.length
        };
      case GET_APPROVALCONFIGDETAILS:
        return {
          ...state,
          loading: true,
          error: '',
          ...action.payload
        };
      case GET_APPROVALCONFIGDETAILS_FAILURE:
        return {
          ...state,
          loading: false,
          error: true,
          alertSuccess: 'failure',
          errorCode: action.payload
        };
    default:
      return state;
  }
};
