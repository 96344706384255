export const RESET_PLAN_EXISTS_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/RESET_PLAN_EXISTS_FAILURE';
export const MIGRATION_SUBMIT_START = 'CBRE-PJM-KAHUA-NEW-MIGRATION/MIGRATION_SUBMIT_START';
export const MIGRATION_SUBMIT_END = 'CBRE-PJM-KAHUA-NEW-MIGRATION/MIGRATION_SUBMIT_END';
export const ADD_MIGRATION_START = 'CBRE-PJM-KAHUA-NEW-MIGRATION/ADD_MIGRATION_START';
export const OPEN_ADD_MIGRATION_DIALOG = 'CBRE-PJM-KAHUA-NEW-MIGRATION/OPEN_ADD_MIGRATION_DIALOG';
export const OPEN_EDIT_MIGRATION_DIALOG = 'CBRE-PJM-KAHUA-NEW-MIGRATION/OPEN_EDIT_MIGRATION_DIALOG';
export const OPEN_EDIT_MIGRATION_DIALOG_SUCCESS = 'CBRE-PJM-KAHUA-NEW-MIGRATION/OPEN_EDIT_MIGRATION_DIALOG_SUCCESS';
export const OPEN_EDIT_MIGRATION_DIALOG_FAILURE = 'CBRE-PJM-KAHUA-NEW-MIGRATION/OPEN_EDIT_MIGRATION_DIALOG_FAILURE';
export const CLOSE_ADD_MIGRATION_DIALOG = 'CBRE-PJM-KAHUA-NEW-MIGRATION/CLOSE_ADD_MIGRATION_DIALOG';
export const ADD_MIGRATION_SUCCESS = 'CBRE-PJM-KAHUA-NEW-MIGRATION/ADD_MIGRATION_SUCCESS';
export const EDIT_MIGRATION_SUCCESS = 'CBRE-PJM-KAHUA-NEW-MIGRATION/EDIT_MIGRATION_SUCCESS';
export const ADD_MIGRATION_FAILURE = 'CBRE-PJM-KAHUA-NEW-MIGRATION/ADD_MIGRATION_FAILURE';
export const CLOSE_ADD_MIGRATION_TOAST = 'CBRE-PJM-KAHUA-NEW-MIGRATION/CLOSE_ADD_MIGRATION_TOAST';
export const GET_ALL_LOOKUPS_SUCCESS = 'CBRE-PJM-KAHUA-NEW-MIGRATION/GET_ALL_LOOKUPS_SUCCESS';
export const GET_ALL_LOOKUPS_FAILURE = 'CBRE-PJM-KAHUA-NEW-MIGRATION/GET_ALL_LOOKUPS_FAILURE';
export const GET_KAHUA_APPS_SUCCESS = 'CBRE-PJM-KAHUA-NEW-MIGRATION/GET_KAHUA_APPS_SUCCESS';
export const GET_KAHUA_APPS_FAILURE = 'CBRE-PJM-KAHUA-NEW-MIGRATION/GET_KAHUA_APPS_FAILURE';
