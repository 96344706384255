export const SET_LOADING_TRUE = 'principal-lite/cbre-pjm-principal-lite-moveproject/SET_LOADING_TRUE';
export const SET_LOADING_FALSE = 'principal-lite/cbre-pjm-principal-lite-moveproject/SET_LOADING_FALSE';
export const SET_ERROR_TRUE = 'principal-lite/cbre-pjm-principal-lite-moveproject/SET_ERROR_TRUE';
export const SET_ERROR_FALSE = 'principal-lite/cbre-pjm-principal-lite-moveproject/SET_ERROR_FALSE';
export const SET_SUCCESS_TRUE = 'principal-lite/cbre-pjm-principal-lite-moveproject/SET_SUCCESS_TRUE';
export const SET_SUCCESS_FALSE = 'principal-lite/cbre-pjm-principal-lite-moveproject/SET_SUCCESS_FALSE';
export const GET_ERP_SYSTEM_LIST_FAILURE =
  'principal-lite/cbre-pjm-principal-lite-moveproject/GET_ERP_SYSTEM_LIST_FAILURE';
export const GET_ERP_SYSTEM_LIST_START =
  'principal-lite/cbre-pjm-principal-lite-moveproject/GET_ERP_SYSTEM_LIST_START';
export const GET_ERP_SYSTEM_LIST_RESET =
  'principal-lite/cbre-pjm-principal-lite-moveproject/GET_ERP_SYSTEM_LIST_RESET';
export const GET_ERP_SYSTEM_LIST_SUCCESS =
  'principal-lite/cbre-pjm-principal-lite-moveproject/GET_ERP_SYSTEM_LIST_SUCCESS';