import flatten from 'flat';
import ApprovalsGroup from 'modules/cbre-pjm-approvals-group/locales/zh_CN.json';
import zhMessages from '../locales/zh-Hans.json';
import documentLanguage from '../../modules/cbre-pjm-document/locales/zh-Hans-CH.json';
import cppProjectsListLanguage from '../../modules/cbre-pjm-cpp-projects-list/locales/zh_CN.json';
import cppBasicProjectInfoLanguage from '../../modules/cbre-pjm-cpp-basic-project-info/locales/zh_CN.json';
import cppFormRoot from '../../modules/cbre-pjm-cpp-form-root/locales/zh_CN.json';
import projectTrackerAddUserLanguage from '../../modules/cbre-pjm-project-tracker-new-project-add-user/locales/zh_CN.json';
import projectTrackerNewProjectLanguage from '../../modules/cbre-pjm-project-tracker-new-project/locales/zh_CN.json';
import projectTrackerNewProjectKahuaLanguage from '../../modules/cbre-pjm-project-tracker-new-project-kahua/locales/zh_CN.json';
import projectTrackerProjectsListLanguage from '../../modules/cbre-pjm-project-tracker-projects-list/locales/zh_CN.json';
import adminDataImport from '../../modules/cbre-pjm-admin-data-import/locales/zh_CN.json';
import ApprovalsLanguage from '../../modules/cbre-pjm-approvals/locales/zh_CN.json';
import reactandSubmitLanguage from '../../modules/cbre-pjm-pds-review/locales/zh_CN.json';
import jABasicProjectInfoLanguage from '../../modules/cbre-pjm-pds-basic-project-info/locales/zh_CN.json';
import costPlanInfoLanguage from '../../modules/cbre-pjm-pds-cost-plan/locales/zh_CN.json';
import pdsProjectOverviewLanguage from '../../modules/cbre-pjm-pds-project-overview/locales/zh_CN.json';
import pdsProjectRisksLanguage from '../../modules/cbre-pjm-pds-project-risks/locales/zh_CN.json';
import pdsApprovalsLanguage from '../../modules/cbre-pjm-pds-approvals/locales/zh_CN.json';
import pdsClientApprovalsLanguage from '../../modules/cbre-pjm-pds-client-approvals/locales/zh_CN.json';
import pdsMyActionsLanguage from '../../modules/cbre-pjm-pds-my-actions/locales/zh_CN.json';
import pdsFormRoot from '../../modules/cbre-pjm-pds-form-root/locales/zh_CN.json';
import jAProjectsListLanguage from '../../modules/cbre-pjm-ja-projects-list/locales/zh_CN.json';
import capitalPlanDeleteProjectLanguage from '../../modules/cbre-pjm-capital-plan-deleteproject/locales/zh_CN.json';
import capitalPlanDetailsLanguage from '../../modules/cbre-pjm-capital-plan-details/locales/zh_CN.json';
import capitalPlanImportLanguage from '../../modules/cbre-pjm-capital-plan-import/locales/zh_CN.json';
import capitalPlanNewProjectLanguage from '../../modules/cbre-pjm-capital-plan-new-project/locales/zh_CN.json';
import capitalPlanPlansListLanguage from '../../modules/cbre-pjm-capital-plan-plans-list/locales/zh_CN.json';
import capitalPlanNewProjectExportToKahuaLanguage from '../../modules/cbre-pjm-capital-planner-export-to-kahua/locales/zh_CN.json';
import capitalPlansLanguage from '../../modules/cbre-pjm-cp-clients/locales/zh_CN.json';
import addPlanLanguage from '../../modules/cbre-pjm-capital-plan-addplan/locales/zh_CN.json';
import capitalPlanCriteriaSettingsLanguage from '../../modules/cbre-pjm-capital-plan-criteriasetting/locales/zh_CN.json';
import capitalPlanDeletePlanLanguage from '../../modules/cbre-pjm-capital-plan-deleteplan/locales/zh_CN.json';
import capitalPlanDeleteProgramLanguage from '../../modules/cbre-pjm-capital-plan-deleteprogram/locales/zh_CN.json';
import estimatorLanguage from '../../modules/cbre-pjm-estimator-clients/locales/zh_CN.json';
import estimatorNewProjectLanguage from '../../modules/cbre-pjm-estimator-new-project/locales/zh_CN.json';
import estimatorSpacePlanLanguage from '../../modules/cbre-pjm-estimator-space-plan/locales/zh_CN.json';
import estimatorProjectLanguage from '../../modules/cbre-pjm-estimator-projects-list/locales/zh_CN.json';
import requestaccesslanguage from '../../modules/cbre-pjm-requestaccess/locales/zh_CN.json';
import userManagementSearchAddUserLanguage from '../../modules/cbre-pjm-user-management-search-add-user/locales/zh_CN.json';
import createProgramLanguage from '../../modules/cbre-pjm-create-program/locales/zh_CN.json';
import HierarchyAccessSearchAddUserTeamLanguage from '../../modules/cbre-pjm-hierarchy-access-search-add-user-team/locales/zh_CN.json';
import ManageTeamsLanguage from '../../modules/cbre-pjm-manage-teams/locales/zh_CN.json';
import ManageTeamUsersLanguage from '../../modules/cbre-pjm-manage-team-users/locales/zh_CN.json';
import userManagementDetailsLanguage from '../../modules/cbre-pjm-user-management/locales/zh_CN.json';
import fusionVendorProjectsLanguage from '../../modules/cbre-pjm-fusion-vendors-projects/locales/zh_CN.json';
import newclientlanguage from '../../modules/cbre-pjm-newclient/locales/zh_CN.json';
import emailAuthentication from '../../modules/cbre-pjm-email-authentication/locales/zh_CN.json';
import capitalPlanMoveProjectLanguage from '../../modules/cbre-pjm-capital-plan-moveproject/locales/zh_CN.json';
import capitalPlanMoveProgramLanguage from '../../modules/cbre-pjm-capital-plan-moveprogram/locales/zh_CN.json';
import clientDataFields from '../../modules/cbre-pjm-capital-plan-client-data-fields/locales/zh_CN.json';
import programManagementProjectLanguage from '../../modules/cbre-pjm-pm-projects-list/locales/zh_CN.json';
import projectTagging from '../../modules/cbre-pjm-project-tagging/locales/zh_CN.json';
import forecast from '../../modules/cbre-pjm-capital-plan-forecasting/locales/zh_CN.json';
import forecastPL from '../../modules/principal-lite/cbre-pjm-principal-lite-project-forecast/locales/zh_CN.json';
import capitalPlanManageReference from '../../modules/cbre-pjm-capital-plan-manage-references/locales/zh_CN.json';
import customFieldsManagementDetailsLanguage from '../../modules/cbre-pjm-custom-fields-management/locales/en_US.json';
import manageLookupTypeListLanguage from '../../modules/cbre-pjm-manage-list/locales/zh_CN.json';
import approvalConfigAddGroup from '../../modules/cbre-pjm-approval-configuration-add-group/locales/zh_CN.json';
import approvalConfig from '../../modules/cbre-pjm-approval-configuration/locales/zh_CN.json';
import executiveSummaryLanguage from '../../modules/cbre-pjm-pds-executive-summary/locales/zh_CN.json';
import GridListLanguage from '../../modules/cbre-pjm-grid/locales/zh_CN.json';
import PrincipalLiteRootLanguage from '../../modules/principal-lite/cbre-pjm-principal-lite-root/locales/zh_CN.json';
import PrincipalLiteProjectRootLanguage from '../../modules/principal-lite/cbre-pjm-principal-lite-project-root/locales/zh_CN.json';
import PrincipalLiteProjectBasicInformationLanguage from '../../modules/principal-lite/cbre-pjm-principal-lite-project-basic-information-root/locales/zh_CN.json';
import principalLiteProjectCostPlan from '../../modules/principal-lite/cbre-pjm-principal-lite-project-cost-plan/locales/zh_CN.json';
import principalLiteNewProject from '../../modules/principal-lite/cbre-pjm-principal-lite-new-project/locales/zh_CN.json';
import principalLiteClientBilling from '../../modules/principal-lite/cbre-pjm-principal-lite-client-billing/locales/zh_CN.json';
import ProjectOverviewCost from '../../modules/cbre-pjm-project-cost-overview/locales/zh_CN.json';
import ProjectDashboard from '../../modules/principal-lite/cbre-pjm-principal-lite-project-dashboard/locales/zh_CN.json';
import principalLiteRecentProjects from '../../modules/principal-lite/cbre-pjm-principal-lite-widget-content/locales/zh_CN.json';
import principalLiteCppDocument from '../../modules/principal-lite/cbre-pjm-principal-lite-cpp-document/locales/zh_CN.json';
import PrincipalLiteProjectDocumentLanguage from '../../modules/principal-lite/cbre-pjm-principal-lite-project-documents/locales/zh_CN.json';
import DocumentRepository from '../../modules/common/cbre-pjm-document-repository/locales/zh_CN.json';
import principalLiteActions from '../../modules/principal-lite/cbre-pjm-principal-lite-widget-actions/locales/zh_CN.json';
import DocumentsDownloadLanguage from '../../modules/cbre-pjm-download/locales/zh_CN.json';
import principalLiteReports from '../../modules/principal-lite/cbre-pjm-principal-lite-reports/locales/zh_CN.json';
import checkListRoot from '../../modules/principal-lite/cbre-pjm-principal-lite-project-checklist/locales/zh_CN.json';
import approvalHistory from '../../modules/common/cbre-pjm-approval-history/locales/zh_CN.json';
import PrincipalLiteFinancialOverview from '../../modules/principal-lite/cbre-pjm-principal-lite-financial-overview/locales/zh_CN.json';
import PrincipalLiteAddUserLanguage from '../../modules/principal-lite/cbre-pjm-principle-lite-add-user/locales/zh_CN.json';
import principalLiteWidgetSummary from '../../modules/principal-lite/cbre-pjm-principal-lite-widget-summary/locales/zh_CN.json';
import principalLiteDashboardGraphs from '../../modules/principal-lite/cbre-pjm-principal-lite-widget-graphs/locales/zh_CN.json';
import PrincipalLiteOrderBook from '../../modules/principal-lite/cbre-pjm-principal-lite-order-book/locales/zh_CN.json';
import PrincipalLitePipeline from '../../modules/principal-lite/cbre-pjm-principal-lite-pipeline/locales/zh_CN.json';
import PrincipalLiteProjectBudgetManagement from '../../modules/principal-lite/cbre-pjm-principal-lite-budget-management/locales/zh_CN.json';
import ApprovalActions from '../../modules/cbre-pjm-approval-actions/locales/zh_CN.json';
import PrincipalLiteMoveProject from '../../modules/principal-lite/cbre-pjm-principal-lite-moveproject/locales/zh_CN.json';
import MyBuyRequestsPage from '../../modules/principal-lite/cbre-pjm-principal-lite-mybuy-requests/locales/zh_CN.json';
import ProjectTemplateDrawer from '../../modules/cbre-pjm-project-template-drawer/locales/zh_CN.json';

const zhLangMessages = flatten({
  ...zhMessages,
  ...{
    'cbre-pjm-capital-plan-deleteproject': capitalPlanDeleteProjectLanguage,
  },
  ...{ 'cbre-pjm-capital-plan-details': capitalPlanDetailsLanguage },
  ...{ 'cbre-pjm-capital-plan-import': capitalPlanImportLanguage },
  ...{ 'cbre-pjm-capital-plan-new-project': capitalPlanNewProjectLanguage },
  ...{ 'cbre-pjm-capital-plan-plans-list': capitalPlanPlansListLanguage },
  ...{
    'cbre-pjm-capital-planner-export-to-kahua':
      capitalPlanNewProjectExportToKahuaLanguage,
  },
  ...{ 'cbre-pjm-cp-clients': capitalPlansLanguage },
  ...{ 'cbre-pjm-estimator-clients': estimatorLanguage },
  ...{ 'cbre-pjm-estimator-new-project': estimatorNewProjectLanguage },
  ...{ 'cbre-pjm-estimator-space-plan': estimatorSpacePlanLanguage },
  ...{ 'cbre-pjm-estimator-projects-list': estimatorProjectLanguage },
  ...{ 'cbre-pjm-cpp-projects-list': cppProjectsListLanguage },
  ...{ 'cbre-pjm-cpp-basic-project-info': cppBasicProjectInfoLanguage },
  ...{ 'cbre-pjm-cpp-form-root': cppFormRoot },
  ...{ 'cbre-pjm-project-tracker-adduser': projectTrackerAddUserLanguage },
  ...{
    'cbre-pjm-project-tracker-new-project': projectTrackerNewProjectLanguage,
  },
  ...{
    'cbre-pjm-project-tracker-new-project-kahua':
      projectTrackerNewProjectKahuaLanguage,
  },
  ...{
    'cbre-pjm-project-tracker-projects-list':
      projectTrackerProjectsListLanguage,
  },
  ...{ 'cbre-pjm-admin-data-import': adminDataImport },
  ...{ 'cbre-pjm-approvals': ApprovalsLanguage },
  ...{ 'cbre-pjm-document': documentLanguage },
  ...{ 'cbre-pjm-pds-basic-project-info': jABasicProjectInfoLanguage },
  ...{ 'cbre-pjm-pds-cost-plan': costPlanInfoLanguage },
  ...{ 'cbre-pjm-pds-review': reactandSubmitLanguage },
  ...{ 'cbre-pjm-pds-project-overview': pdsProjectOverviewLanguage },
  ...{ 'cbre-pjm-pds-project-risks': pdsProjectRisksLanguage },
  ...{ 'cbre-pjm-pds-approvals': pdsApprovalsLanguage },
  ...{ 'cbre-pjm-pds-client-approvals': pdsClientApprovalsLanguage },
  ...{ 'cbre-pjm-pds-my-actions': pdsMyActionsLanguage },
  ...{ 'cbre-pjm-pds-form-root': pdsFormRoot },
  ...{ 'cbre-pjm-ja-projects-list': jAProjectsListLanguage },
  ...{ 'cbre-pjm-fusion-vendors-projects': fusionVendorProjectsLanguage },
  ...{ 'cbre-pjm-email-authentication': emailAuthentication },
  ...{ 'cbre-pjm-newclient': newclientlanguage },

  ...{
    'cbre-pjm-user-management-search-add-user':
      userManagementSearchAddUserLanguage,
  },
  ...{ 'cbre-pjm-create-program': createProgramLanguage },
  ...{
    'cbre-pjm-hierarchy-access-search-add-user-team':
    HierarchyAccessSearchAddUserTeamLanguage,
  },
  ...{ 'cbre-pjm-manage-teams': ManageTeamsLanguage },
  ...{ 'cbre-pjm-manage-team-users': ManageTeamUsersLanguage},
  ...{ 'cbre-pjm-user-management': userManagementDetailsLanguage },
  ...{
    'cbre-pjm-custom-fields-management': customFieldsManagementDetailsLanguage,
  },
  ...{ 'cbre-pjm-capital-plan-addplan': addPlanLanguage },
  ...{
    'cbre-pjm-capital-plan-criteriasetting':
      capitalPlanCriteriaSettingsLanguage,
  },
  ...{ 'cbre-pjm-capital-plan-deleteplan': capitalPlanDeletePlanLanguage },
  ...{ 'cbre-pjm-capital-plan-deleteprogram': capitalPlanDeleteProgramLanguage },
  ...{ 'cbre-pjm-requestaccess': requestaccesslanguage },
  ...{ 'cbre-pjm-capital-plan-moveproject': capitalPlanMoveProjectLanguage },
  ...{ 'cbre-pjm-capital-plan-moveprogram': capitalPlanMoveProgramLanguage },
  ...{ 'cbre-pjm-capital-plan-client-data-fields': clientDataFields },
  ...{ 'cbre-pjm-pm-projects-list': programManagementProjectLanguage },
  ...{ 'cbre-pjm-project-tagging': projectTagging },
  ...{ 'cbre-pjm-capital-plan-forecasting': forecast },
  ...{ 'cbre-pjm-principal-lite-project-forecast': forecastPL },
  ...{ 'cbre-pjm-capital-plan-manage-references': capitalPlanManageReference },
  ...{ 'cbre-pjm-manage-list': manageLookupTypeListLanguage },
  ...{ 'cbre-pjm-approval-configuration-add-group': approvalConfigAddGroup },
  ...{ 'cbre-pjm-approvals-group': ApprovalsGroup },
  ...{ 'cbre-pjm-approval-configuration': approvalConfig },
  ...{ 'cbre-pjm-pds-executive-summary': executiveSummaryLanguage },
  ...{ 'cbre-pjm-grid': GridListLanguage },
  ...{ 'cbre-pjm-principal-lite-root': PrincipalLiteRootLanguage },
  ...{
    'cbre-pjm-principal-lite-project-root': PrincipalLiteProjectRootLanguage,
  },
  ...{
    'cbre-pjm-principal-lite-project-basic-information':
      PrincipalLiteProjectBasicInformationLanguage,
  },
  ...{
    'cbre-pjm-principal-lite-project-cost-plan': principalLiteProjectCostPlan,
  },
  ...{ 'cbre-pjm-principal-lite-new-project': principalLiteNewProject },
  ...{ 'cbre-pjm-principal-lite-client-billing': principalLiteClientBilling },
  ...{ 'cbre-pjm-principal-lite-widget-content': principalLiteRecentProjects },
  ...{ 'cbre-pjm-project-cost-overview': ProjectOverviewCost },
  ...{ 'cbre-pjm-principal-lite-widget-content': principalLiteRecentProjects },
  ...{ 'cbre-pjm-principal-lite-cpp-document': principalLiteCppDocument },
  ...{ 'cbre-pjm-principal-lite-project-dashboard': ProjectDashboard },
  ...{ 'cbre-pjm-principal-lite-project-checklist': checkListRoot },
  ...{ 'cbre-pjm-grid': GridListLanguage },
  ...{ 'cbre-pjm-principal-lite-root': PrincipalLiteRootLanguage },
  ...{
    'cbre-pjm-principal-lite-project-root': PrincipalLiteProjectRootLanguage,
  },
  ...{ 'cbre-pjm-principal-lite-moveproject': PrincipalLiteMoveProject },
  ...{
    'cbre-pjm-principal-lite-project-basic-information':
      PrincipalLiteProjectBasicInformationLanguage,
  },
  ...{ 'cbre-pjm-principal-lite-reports': principalLiteReports },
  ...{ 'cbre-pjm-pds-executive-summary': executiveSummaryLanguage },
  ...{
    'cbre-pjm-principal-lite-project-documents':
      PrincipalLiteProjectDocumentLanguage,
  },
  ...{
    'cbre-pjm-principal-lite-project-cost-plan': principalLiteProjectCostPlan,
  },
  ...{ 'cbre-pjm-principal-lite-new-project': principalLiteNewProject },
  ...{ 'cbre-pjm-principal-lite-client-billing': principalLiteClientBilling },
  ...{ 'cbre-pjm-project-cost-overview': ProjectOverviewCost },
  ...{ 'cbre-pjm-approval-history': approvalHistory },
  ...{ 'cbre-pjm-document-repository': DocumentRepository },
  ...{ 'cbre-pjm-principal-lite-project-dashboard': ProjectDashboard },
  ...{ 'cbre-pjm-principal-lite-widget-content': principalLiteRecentProjects },
  ...{ 'cbre-pjm-principal-lite-cpp-document': principalLiteCppDocument },
  ...{ 'cbre-pjm-principal-lite-widget-actions': principalLiteActions },
  ...{ 'cbre-pjm-download': DocumentsDownloadLanguage },
  ...{
    'cbre-pjm-principal-lite-financial-overview':
      PrincipalLiteFinancialOverview,
  },
  ...{ 'cbre-pjm-principle-lite-add-user': PrincipalLiteAddUserLanguage },
  ...{ 'cbre-pjm-principal-lite-widget-summary': principalLiteWidgetSummary },
  ...{
    'cbre-pjm-principal-lite-widget-graphs': principalLiteDashboardGraphs,
  },
  ...{ 'cbre-pjm-principal-lite-order-book': PrincipalLiteOrderBook },
  ...{ 'cbre-pjm-principal-lite-pipeline': PrincipalLitePipeline },
  ...{ 'cbre-pjm-principal-lite-project-budget-management': PrincipalLiteProjectBudgetManagement },
  ...{ 'cbre-pjm-approval-actions': ApprovalActions },
  ...{ 'cbre-pjm-principal-lite-mybuy-requests': MyBuyRequestsPage },
  ...{ 'cbre-pjm-project-template-drawer': ProjectTemplateDrawer },
});

// https://formatjs.io/docs/core-concepts/basic-internationalization-principles#locales-language-and-region use only react Intl supported formats

const ZhLan = {
  messages: zhLangMessages,
  locale: 'zh-CN',
};
export default ZhLan;
