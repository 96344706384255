export const GET_ALL_CLIENTS_EXISTS_FAILURE = 'CBRE-PJM-REQUESTACCESS/GET_ALL_CLIENTS_EXISTS_FAILURE';
export const GET_ALL_CLIENTS_START = 'CBRE-PJM-REQUESTACCESS/GET_ALL_CLIENTS_START';
export const GET_ALL_CLIENTS_SUCCESS = 'CBRE-PJM-REQUESTACCESS/GET_ALL_CLIENTS_SUCCESS';
export const GET_ALL_CLIENTS_FAILURE = 'CBRE-PJM-REQUESTACCESS/GET_ALL_CLIENTS_FAILURE';
export const REQUEST_ACCESS_START = 'CBRE-PJM-REQUESTACCESS/REQUEST_ACCESS_START';
export const REQUEST_ACCESS_SUCCESS = 'CBRE-PJM-REQUESTACCESS/REQUEST_ACCESS_SUCCESS';
export const REQUEST_ACCESS_EXISTS_FAILURE = 'CBRE-PJM-REQUESTACCESS/REQUEST_ACCESS_EXISTS_FAILURE';
export const REQUEST_ACCESS_FAILURE = 'CBRE-PJM-REQUESTACCESS/REQUEST_ACCESS_FAILURE';
export const CLOSE_REQUEST_ACCESS_TOAST = 'CBRE-PJM-REQUESTACCESS/CLOSE_REQUEST_ACCESS_TOAST';
export const OPEN_REQUEST_ACCESS_DIALOG = 'CBRE-PJM-REQUESTACCESS/OPEN_REQUEST_ACCESS_DIALOG';
export const CLOSE_REQUEST_ACCESS_DIALOG = 'CBRE-PJM-REQUESTACCESS/CLOSE_REQUEST_ACCESS_DIALOG';
