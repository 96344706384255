export const OPEN_ADD_USER_DIALOG =
  'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/OPEN_ADD_USER_DIALOG';
export const CLOSE_ADD_USER_DIALOG =
  'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/CLOSE_ADD_USER_DIALOG';
export const RESET_ADD_USER =
  'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/RESET_ADD_USER';

export const DISABLE_ADD_USER =
  'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/DISABLE_ADD_USER';
export const START_ADD_USER =
  'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/START_ADD_USER';

export const SUCCESS_ADD_USER =
  'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/SUCCESS_ADD_USER';
export const FAILURE_ADD_USER =
  'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/FAILURE_ADD_USER';
export const DUPLICATE_ADD_USER =
  'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/DUPLICATE_ADD_USER';
export const CLOSE_TOAST_ADD_USER =
  'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/CLOSE_TOAST_ADD_USER';
