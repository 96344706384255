export const COST_PLAN_SAVE_START = 'COST_PLAN_SAVE_START';
export const COST_PLAN_SAVE_SUCCESS = 'COST_PLAN_SAVE_SUCCESS';
export const COST_PLAN_SAVE_FAILURE = 'COST_PLAN_SAVE_FAILURE';
export const COST_PLAN_SAVE_RESET = 'COST_PLAN_SAVE_RESET';
export const GET_LEGACY_SUCCESS = 'GET_LEGACY_SUCCESS';
export const GET_LEGACY_FAILURE = 'GET_LEGACY_FAILURE';
export const BUDGET_SAVE_SUCCESS = 'BUDGET_SAVE_SUCCESS';
export const BUDGET_SAVE_FAILURE = 'BUDGET_SAVE_FAILURE';
export const BUDGET_SAVE_RESET = 'BUDGET_SAVE_RESET';
export const PR_CR_VALIDATION_ERROR = 'PR_CR_VALIDATION_ERROR';
