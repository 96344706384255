export const SET_LOADING_TRUE =
  'cbre-pjm-principal-lite-widget-filters/SET_LOADING_TRUE';
export const SET_LOADING_FALSE =
  'cbre-pjm-principal-lite-widget-filters/SET_LOADING_FALSE';
export const FETCH_MY_FILTERS_FAIL =
  'cbre-pjm-principal-lite-widget-filters/FETCH_MY_FILTERS_FAIL';
export const FETCH_MY_FILTERS_SUCCESS =
  'cbre-pjm-principal-lite-widget-filters/FETCH_MY_FILTERS_SUCCESS';
export const HIDE_WIDGET = 'cbre-pjm-principal-lite-widget-filters/HIDE_WIDGET';
export const SHOW_WIDGET = 'cbre-pjm-principal-lite-widget-filters/SHOW_WIDGET';
