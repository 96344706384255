
/* eslint-disable */
import jwt from 'jwt-decode';
import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  FUSION_USER_SUCCESS,
  FUSION_USER_SIGNOUT_SUCCESS,
} from 'modules/cbre-pjm-Common/constants/ActionTypes';

const authResult = new URLSearchParams(window.location.search);
const pjmToken = sessionStorage.getItem('pjm-token') || authResult.get('pjmToken');
const uiToken = sessionStorage.getItem('ui-token') || authResult.get('uiToken');
const user = uiToken ? jwt(uiToken) : {};
const appPermissions = {};
if (user && user.permissions) {
  appPermissions.isSystemAdmin = user.isSystemAdmin;
  appPermissions.appAdmin = user.appAdmin;
  appPermissions.level = [];
  user && user.permissions && user.permissions.forEach((appPermission, index) => {
    if (!appPermissions[appPermission.pjmAppCode]) appPermissions[appPermission.pjmAppCode] = [];
    appPermissions[appPermission.pjmAppCode].push({ clientId: appPermission.clientId, permissions: appPermission.permissions });
    appPermissions.level.push(user.permissions[index].level);
  });
}
// localStorage.setItem('locale', user.userLocale);
// console.log("user log ====>", user.userLocale)
const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  uuid: user?.uuid || '',
  userDisplayName: user.displayName,
  userEmailId: user.email,
  tokenSource: user.tokenSource,
  isSystemAdmin: appPermissions.isSystemAdmin,
  appAdmin: appPermissions.appAdmin,
  level: appPermissions.level,
  isExternalAccount: user.isExternalAccount,
  permissions: appPermissions,
  authUser: sessionStorage.getItem('ui-token') || (process.env.NODE_ENV === 'development') ? uiToken : null,
  locale: user.userLocale
};

if (process.env.NODE_ENV === 'development' && pjmToken) {
  sessionStorage.setItem('pjm-token', pjmToken);
}
if (process.env.NODE_ENV === 'development' && uiToken) {
  sessionStorage.setItem('ui-token', uiToken);
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case FUSION_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: sessionStorage.getItem('ui-token'),
        tokenSource: action.payload.user.tokenSource,
        userDisplayName: action.payload.userDisplayName,
        userEmailId: action.payload.userEmailId,
        locale: action.payload.user.userLocale
      };
    }
    case FUSION_USER_SIGNOUT_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: null,
        tokenSource: '',
        userDisplayName: '',
        userEmailId: ''
      };
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '/dashboard',
        loader: false,
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false,
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    default:
      return state;
  }
};
