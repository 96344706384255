import {
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  SET_SUB_ACTIVITY,
  GET_BUDGET_DETAILS_SUCCESS,
  GET_BUDGET_DETAILS_FAILURE,
  GET_BUDGET_DETAILS_START,
  GET_BUDGET_DETAILS_RESET,
  GET_BUDGET_LIST_START,
  GET_BUDGET_LIST_FAILURE,
  GET_BUDGET_LIST_SUCCESS,
  GET_BUDGET_LIST_RESET,
  UPDATE_TOTALS,
  BUDGET_DELETE_SUCCESS,
  BUDGET_DELETE_RESET,
  BUDGET_DELETE_FAILED,
  BUDGET_DELETE_START,
} from './constants';

const INITIAL_STATE = {
  // Your initial state here
  addBudget: false,
  budgetLineItem: [],
  budgetDetails: {},
  budgetList: [],
  budgetSummary: [],
  budgetSummaryDetails: [],
  // TODO: Move this to constants
  totals: {
    originalBudget: '0.00',
    totalApprovedBudget: '0.00',
    pendingBudgetApproval: '0.00',
    totalBudget: '0.00',
    budgetSpent: '0.00',
    unbilledBudget: '0.00',
  },
  budgetDeleteInfo: {
    error: false,
    message: '',
    loading: false
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_TOTALS: 
      return {
        ...state,
        budgetSummaryDetails: {
          ...state.budgetSummaryDetails,
          [action.payload.key]: action.payload.newValue,
        },
      };
    case SET_SUB_ACTIVITY:
      return {
        ...state,
        budgetLineItem: action.payload,
      };
    case GET_BUDGET_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        budgetDetails: action?.payload,
        budgetSummaryDetails: action?.payload?.summaryTotals,
      };
    case GET_BUDGET_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        budgetDetails: action.payload,
      };
    case GET_BUDGET_DETAILS_START:
      return {
        ...state,
        loading: true,
        budgetLineItem: action.payload,
      };
    case GET_BUDGET_DETAILS_RESET:
      return {
        ...state,
        loading: false,
        budgetDetails: {},
      };
    case GET_BUDGET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        budgetList: action?.payload?.result,
        budgetSummary: action?.payload?.summaryTotals,
      };
    case GET_BUDGET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        budgetList: action.payload,
      };
    case GET_BUDGET_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case GET_BUDGET_LIST_RESET:
      return {
        ...state,
        loading: false,
        budgetList: [],
      };
    case BUDGET_DELETE_START:
      return {
        ...state,
        budgetDeleteInfo: {
          message: '',
          error: false,
          messageCode: '',
          loading: true
        }
      };
    case BUDGET_DELETE_RESET:
      return {
        ...state,
        budgetDeleteInfo: {
          message: '',
          error: false,
          messageCode: '',
          loading: false
        }
      };
    case BUDGET_DELETE_SUCCESS:
      return {
        ...state,
        budgetDeleteInfo: {
          message: action.payload,
          messageCode: '',
          loading: false
        }
      };
    case BUDGET_DELETE_FAILED:
      return {
        ...state,
        budgetDeleteInfo: {
          message: action.payload,
          error: true,
          messageCode: '',
          loading: false
        },
        loading: false
      };
    default:
      return state;
  }
};
