export const SET_LOADING_TRUE =
  'cbre-pjm-principal-lite-grid-layout/SET_LOADING_TRUE';
export const SET_LOADING_FALSE =
  'cbre-pjm-principal-lite-grid-layout/SET_LOADING_FALSE';
export const SET_CUSTOMIZING =
  'cbre-pjm-principal-lite-grid-layout/SET_CUSTOMIZING';
export const FETCH_GRID_LAYOUT_FAIL =
  'cbre-pjm-principal-lite-grid-layout/FETCH_GRID_LAYOUT_FAIL';
export const FETCH_GRID_LAYOUT_SUCCESS =
  'cbre-pjm-principal-lite-grid-layout/FETCH_GRID_LAYOUT_SUCCESS';
export const SET_GRID_LAYOUT_FAIL =
  'cbre-pjm-principal-lite-grid-layout/SET_GRID_LAYOUT_FAIL';
export const SET_GRID_LAYOUT_SUCCESS =
  'cbre-pjm-principal-lite-grid-layout/SET_GRID_LAYOUT_SUCCESS';
export const GET_LEGACY_SUCCESS = 
  'cbre-pjm-principal-lite-grid-layout/GET_LEGACY_SUCCESS';
export const GET_LEGACY_FAILURE = 
  'cbre-pjm-principal-lite-grid-layout/GET_LEGACY_FAILURE';
