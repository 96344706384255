export const TRACKER_GET_ALL_LOOKUPS_SUCCESS = 'TRACKER_GET_ALL_LOOKUPS_SUCCESS';
export const TRACKER_GET_ALL_LOOKUPS_FAILURE = 'TRACKER_GET_ALL_LOOKUPS_FAILURE';
export const TRACKER_ADD_NEW_PROJECT_START = 'TRACKER_ADD_NEW_PROJECT_START';
export const TRACKER_ADD_NEW_PROJECT_SUCCESS = 'TRACKER_ADD_NEW_PROJECT_SUCCESS';
export const TRACKER_ADD_NEW_PROJECT_FAILURE = 'TRACKER_ADD_NEW_PROJECT_FAILURE';
export const TRACKER_ADD_NEW_PROJECT_EXISTS_FAILURE = 'TRACKER_ADD_NEW_PROJECT_EXISTS_FAILURE';
export const TRACKER_CLOSE_NEW_PROJECT_TOAST = 'TRACKER_CLOSE_NEW_PROJECT_TOAST';
export const TRACKER_RESET_NEW_PROJECT_STATUS = 'TRACKER_RESET_NEW_PROJECT_STATUS';
export const TRACKER_RESET_NEW_PROJECT_ERROR = 'TRACKER_RESET_NEW_PROJECT_ERROR';
export const TRACKER_FILE_UPLOAD_NEW_PROJECT_START = 'TRACKER_FILE_UPLOAD_NEW_PROJECT_START';
export const TRACKER_FILE_UPLOAD_NEW_PROJECT_SUCCESS = 'TRACKER_FILE_UPLOAD_NEW_PROJECT_START';
export const TRACKER_FILE_UPLOAD_NEW_PROJECT_ERROR = 'TRACKER_FILE_UPLOAD_NEW_PROJECT_ERROR';
export const TRACKER_GET_PROJECT_BY_ID_START = 'TRACKER_GET_PROJECT_BY_ID_START';
export const TRACKER_GET_PROJECT_BY_ID_SUCCESS = 'TRACKER_GET_PROJECT_BY_ID_SUCCESS';
export const TRACKER_SET_INITIALS = 'TRACKER_SET_INITIALS';
