
/* eslint-disable */
import {
  BASIC_INFORMATION_ADD_NEW_PROJECT_EXISTS_FAILURE,
  BASIC_INFORMATION_ADD_NEW_PROJECT_FAILURE,
  BASIC_INFORMATION_ADD_NEW_PROJECT_RESET,
  BASIC_INFORMATION_ADD_NEW_PROJECT_START,
  BASIC_INFORMATION_ADD_NEW_PROJECT_SUCCESS,
  UPDATE_APPROVED_PROJECT_FAILURE,
  UPDATE_APPROVED_PROJECT_RESET,
  UPDATE_APPROVED_PROJECT_START,
  UPDATE_APPROVED_PROJECT_SUCCESS,
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
} from './basicInformationConstants';
import { PROJECT_RISKS_ADD_NEW_RISK_SUCCESS, PROJECT_RISKS_ADD_NEW_RISK_START, PROJECT_RISKS_RESET_CUSTOM_FETCH_RISK_DATA, PROJECT_RISKS_ADD_NEW_RISK_FAILURE} from './projectRiskConstants';
import {
  PROJECT_OVERVIEW_ADD_NEW_PROJECT_START,
  PROJECT_OVERVIEW_ADD_NEW_PROJECT_SUCCESS,
  PROJECT_OVERVIEW_ADD_NEW_PROJECT_RESET,
  PROJECT_OVERVIEW_ADD_NEW_PROJECT_EXISTS_FAILURE,
} from './projectOverviewConstants';
import {
  GET_PDA_LIST_START,
  GET_PDA_LIST_SUCCESS,
  GET_PDA_LIST_FAILURE,
  GET_PDA_LIST_RESET,
  GET_PDA_PROJECT_DETAILS_RESET,
  GET_VENDOR_LIST_START,
  GET_VENDOR_LIST_SUCCESS,
  GET_VENDOR_LIST_FAILURE,
  GET_VENDOR_LIST_RESET,
  GET_APPROVERS_LIST_START,
  GET_APPROVERS_LIST_SUCCESS,
  GET_APPROVERS_LIST_FAILURE,
  GET_APPROVERS_LIST_RESET,
} from './constants';
import {
  COST_PLAN_SAVE_START,
  COST_PLAN_SAVE_SUCCESS,
  COST_PLAN_SAVE_FAILURE,
  COST_PLAN_SAVE_RESET,
  GET_LEGACY_SUCCESS,
  GET_LEGACY_FAILURE,
} from './costPlanConstants';
import { REVIEW_SUBMIT_FAILURE, REVIEW_SUBMIT_RESET, REVIEW_SUBMIT_START, REVIEW_SUBMIT_SUCCESS, REVIEW_SUBMIT_VALIDATION_SET_ERROR } from './reviewSubmitConstants';

import {
  APPROVALS_SAVE_START,
  APPROVALS_SAVE_SUCCESS,
  APPROVALS_SAVE_FAILURE,
  APPROVALS_RESET,
} from './approvalsConstants';

import {
  CLIENT_APPROVALS_RESET,
  CLIENT_APPROVALS_SAVE_FAILURE,
  CLIENT_APPROVALS_SAVE_SUCCESS,
  GET_KAHUA_STATUS_SUCCESS,
  SYNC_KAHUA_SAVE_FAILURE
} from './clientApprovalsConstants';

const INITIAL_STATE = {
  error: false,
  errorCode: '',
  loading: false,
  basicInfo: {
    message: '',
    messageCode: '',
    loading: false,
    status: '',
  },
  approvedProjectInfo: {
    message: '',
    messageCode: '',
    loading: false,
  },
  overviewData: {
    message: '',
    messageCode: '',
    loading: false,
  },
  approvalsData: {
    message: '',
    messageCode: '',
    loading: false,
  },
  projectList: {},
  projectListSuccess: '',
  projectOverviewData: {},
  projectRisksData: {},
  projectData: {},
  projectDetails: {},
  projectDetailsDEF: {},
  costPlanData: {},
  costPlanInfo: {
    message: '',
    messageCode: '',
    loading: false,
  },
  costPlanCheckBox: false,
  vendorsListInfo: {
    message: '',
    messageCode: '',
    loading: false,
  },
  allLegacyData: {},
  approversListInfo: {
    message: '',
    messageCode: '',
    loading: false,
  },
  riskInfo: {
    message: '',
    messageCode: '',
    loading: false,
  },
  vendorsListData: [],
  approversListData: [],
  reviewSubmitData: {},
  reviewSubmitValidationError: {},
  reviewStarted: false,
  riskSuccess: '',
  approvalsInfoData: {},
  kahuaStatus: {},
  bidStatusInfo: {},
  custom: {
    fetchRiskData: false
  },
  kahuaSyncFailure:{}
};

// eslint-disable-next-line complexity
const pdsFormRootReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BASIC_INFORMATION_ADD_NEW_PROJECT_RESET:
      return {
        ...state,
        basicInfo: {
          message: '',
          messageCode: '',
          status: '',
          loading: false,
        },
      };
    case BASIC_INFORMATION_ADD_NEW_PROJECT_SUCCESS:
      return {
        ...state,
        projectData: action.payload,
        basicInfo: {
          message: 'success',
          loading: false,
          messageCode: '',
        },
        loading: false,
      };
    case BASIC_INFORMATION_ADD_NEW_PROJECT_EXISTS_FAILURE:
      return {
        ...state,
        basicInfo: {
          ...action.payload,
          loading: false,
        },
        loading: false,
      };
    case BASIC_INFORMATION_ADD_NEW_PROJECT_FAILURE:
      return {
        ...state,
        basicInfo: {
          ...action.payload,
          loading: false,
        },
        loading: false,
      };
    case BASIC_INFORMATION_ADD_NEW_PROJECT_START:
      return {
        ...state,
        basicInfo: {
          message: '',
          messageCode: '',
          status: '',
          loading: true,
        },
        loading: true,
      };
    case UPDATE_APPROVED_PROJECT_RESET:
      return {
        ...state,
        approvedProjectInfo: {
          message: '',
          messageCode: '',
          loading: false,
        },
      };
    case UPDATE_APPROVED_PROJECT_SUCCESS:
      return {
        ...state,
        projectData: action.payload,
        approvedProjectInfo: {
          message: 'success',
          loading: false,
          messageCode: '',
        },
        loading: false,
      };
    case UPDATE_APPROVED_PROJECT_FAILURE:
      return {
        ...state,
        approvedProjectInfo: {
          ...action.payload,
          loading: false,
        },
        loading: false,
      };
    case UPDATE_APPROVED_PROJECT_START:
      return {
        ...state,
        approvedProjectInfo: {
          message: '',
          messageCode: '',
          loading: true,
        },
        loading: true,
      };
    case PROJECT_OVERVIEW_ADD_NEW_PROJECT_RESET:
      return {
        ...state,
        overviewData: {
          message: '',
          messageCode: '',
          loading: false,
        },
      };
    case PROJECT_OVERVIEW_ADD_NEW_PROJECT_SUCCESS:
      return {
        ...state,
        projectOverviewData: action.payload,
        overviewData: {
          message: 'success',
          loading: false,
          messageCode: '',
        },
        loading: false,
      };
    case PROJECT_OVERVIEW_ADD_NEW_PROJECT_EXISTS_FAILURE:
      return {
        ...state,
        overviewData: {
          ...action.payload,
          loading: false,
        },
        loading: false,
      };
    case PROJECT_OVERVIEW_ADD_NEW_PROJECT_START:
      return {
        ...state,
        overviewData: {
          message: '',
          messageCode: '',
          loading: true,
        },
        loading: true,
      };
    case PROJECT_RISKS_ADD_NEW_RISK_SUCCESS:
      return {
        ...state,
        projectRisksData: action.payload,
        riskInfo: {
          message: 'success',
          messageCode: '',
          loading: false,
        },
        riskSuccess: 'success',
      };
    case PROJECT_RISKS_ADD_NEW_RISK_START:
      return {
        ...state,
        ...action.payload,
        riskInfo: {
          message: '',
          messageCode: '',
          loading: true,
        },
        riskSuccess: '',
      };
    case PROJECT_RISKS_ADD_NEW_RISK_FAILURE:
      return {
        ...state,
        ...action.payload,
        riskInfo: {
          message: action.payload?.message || '',
          messageCode: action.payload?.messageCode || '',
          loading: false,
        },
        riskSuccess: 'failure',
      };
    case PROJECT_RISKS_RESET_CUSTOM_FETCH_RISK_DATA:
      return {
        ...state,
        custom: {
          ...state.custom,
          fetchRiskData: false
        }
      }
    case GET_PDA_LIST_SUCCESS:
      let { data, custom } = action.payload
      return {
        ...state,
        projectDetails: data,
        projectDetailsDEF: data,
        loading: false,
        projectListSuccess: 'success',
        reviewStarted: false,
        custom: custom ? { ...state.custom, ...custom } : { ...state.custom }
      };
    case GET_PDA_LIST_START:
      return {
        ...state,
        basicInfo: {
          message: '',
          messageCode: '',
          status: '',
          loading: false,
        },
        loading: true,
        // projectDetails: {},
        projectListSuccess: '',
      };
    case GET_PDA_LIST_FAILURE:
      return {
        ...state,
        projectDetails: action.payload,
      };
    case GET_PDA_LIST_RESET:
      return {
        ...state,
        projectListSuccess: '',
        loading: false,
      };
    case GET_PDA_PROJECT_DETAILS_RESET:
      return {
        ...state,
        projectDetails: {}
      };
    case COST_PLAN_SAVE_RESET:
      return {
        ...state,
        costPlanInfo: {
          message: '',
          messageCode: '',
          loading: false,
        },
      };
    case COST_PLAN_SAVE_SUCCESS:
      return {
        ...state,
        costPlanInfo: {
          message: 'success',
          loading: false,
          messageCode: '',
          type: action.payload.type,
        },
        costPlanData: action.payload.data,
        loading: false,
      };
    case COST_PLAN_SAVE_FAILURE:
      return {
        ...state,
        costPlanInfo: {
          response: action.payload,
          loading: false,
        },
        loading: false,
      };
    case COST_PLAN_SAVE_START:
      return {
        ...state,
        costPlanInfo: {
          message: '',
          messageCode: '',
          loading: true,
        },
        loading: true,
      };
    case GET_VENDOR_LIST_RESET:
      return {
        ...state,
        vendorsListInfo: {
          message: '',
          messageCode: '',
          loading: false,
        },
      };
    case GET_VENDOR_LIST_SUCCESS:
      return {
        ...state,
        vendorsListInfo: {
          message: 'success',
          loading: false,
          messageCode: '',
        },
        vendorsListData: action.payload,
        loading: false,
      };
    case GET_VENDOR_LIST_FAILURE:
      return {
        ...state,
        vendorsListInfo: {
          response: action.payload,
          loading: false,
        },
        loading: false,
      };
    case GET_VENDOR_LIST_START:
      return {
        ...state,
        vendorsListInfo: {
          message: '',
          messageCode: '',
          loading: true,
        },
        loading: true,
      };
    case GET_LEGACY_SUCCESS:
      return {
        ...state,
        allLegacyData: action.payload,
        loading: false,
      };
    case GET_LEGACY_FAILURE:
      return {
        ...state,
        allLegacyData: action.payload,
        loading: false,
      };
    case GET_APPROVERS_LIST_START:
      return {
        ...state,
        approversListInfo: {
          message: '',
          messageCode: '',
          loading: true,
        },
        loading: true,
      };
    case GET_APPROVERS_LIST_RESET:
      return {
        ...state,
        approversListInfo: {
          message: '',
          messageCode: '',
          loading: false,
        },
      };
    case GET_APPROVERS_LIST_SUCCESS:
      return {
        ...state,
        approversListInfo: {
          message: 'success',
          loading: false,
          messageCode: '',
        },
        approversListData: action.payload,
        loading: false,
      };
    case GET_APPROVERS_LIST_FAILURE:
      return {
        ...state,
        approversListInfo: {
          response: action.payload,
          loading: false,
        },
        loading: false,
      };
    case REVIEW_SUBMIT_START:
      return {
        ...state,
        loading: true,
        reviewStarted: true
      };
      case APPROVALS_RESET:
        return {
          ...state,
          approvalsData: {
            message: '',
            messageCode: '',
            loading: false,
          },
        };
      case APPROVALS_SAVE_SUCCESS:
        return {
          ...state,
          approvalsInfoData: action.payload,
          approvalsData: {
            message: 'success',
            loading: false,
            messageCode: '',
          },
          loading: false,
        };
      case APPROVALS_SAVE_FAILURE:
        return {
          ...state,
          approvalsData: {
            ...action.payload,
            loading: false,
          },
          loading: false,
        };
      case APPROVALS_SAVE_START:
        return {
          ...state,
          approvalsData: {
            message: '',
            messageCode: '',
            loading: true,
          },
          loading: true,
        };
    case REVIEW_SUBMIT_SUCCESS:
      let stateObj = {
        ...state,
        reviewSubmitData: action.payload,
        reviewStarted: false,
        loading: false,
      }
      stateObj.projectDetails.project.workFlowStatus.code = action.payload.workFlowStatusCode;
      return stateObj;
    case REVIEW_SUBMIT_FAILURE:
      return {
        ...state,
        reviewSubmitData: action.payload,
        loading: false,
      };
    case REVIEW_SUBMIT_RESET:
      return {
        ...state,
        reviewSubmitData: {},
        reviewStarted: false,
      };
    case REVIEW_SUBMIT_VALIDATION_SET_ERROR:
      return {
        ...state,
        loading: true,
        reviewSubmitValidationError: action.payload,
      }
    case GET_KAHUA_STATUS_SUCCESS:
      return {
        ...state,
        kahuaStatus: action.payload,
      }
    case CLIENT_APPROVALS_SAVE_SUCCESS:
      return {
        ...state,
        bidStatusInfo: action.payload,
      }
    case CLIENT_APPROVALS_SAVE_FAILURE:
      return {
        ...state,
        bidStatusInfo: action.payload,
      }
    case CLIENT_APPROVALS_RESET:
      return {
        ...state,
        bidStatusInfo: {},
      }
      case SYNC_KAHUA_SAVE_FAILURE:
      return {
        ...state,
        kahuaSyncFailure: action.payload,
      }
      case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      }
      case SET_LOADING_FALSE:
      return {
        ...state,
        loading: true,
      }
    default:
      return state;
  }
};

export default pdsFormRootReducer;
