export const GET_PLAN_SUCCESS =
  'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_PLAN_SUCCESS';
export const GET_PLAN_FAILURE =
  'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_PLAN_FAILURE';
export const GET_PLAN_RESET = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_PLAN_RESET';
export const GET_PLAN_START = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_PLAN_START';
export const GET_FILTER_SUCCESS = 'CBRE-PJM-PM/GET_FILTER_SUCCESS';
export const GET_FILTER_FAILURE =
  'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_FILTER_FAILURE';
export const GET_FILTER_RESET =
  'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_FILTER_RESET';
export const GET_TOP10_PROJECTS_SUCCESS =
  'CBRE-PJM-CAPITAL-PLAN-DETAILS/ GET_TOP10_PROJECTS_SUCCESS';
export const GET_TOP10_PROJECTS_FAILURE =
  'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_TOP10_PROJECTS_FAILURE';
export const GET_TOP10_PROJECTS_RESET =
  'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_TOP10_PROJECTS_RESET';
export const SAVE_PROJECT_PRIORITY_SCORE_FAILURE =
  'CBRE-PJM-CAPITAL-PLAN-DETAILS/SAVE_PROJECT_PRIORITY_SCORE_FAILURE';
export const SAVE_PROJECT_PRIORITY_SCORE_SUCCESS =
  'CBRE-PJM-CAPITAL-PLAN-DETAILS/SAVE_PROJECT_PRIORITY_SCORE_SUCCESS';
export const GET_CASH_FLOW_GRAPH_DATA_SUCCESS =
  'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_CASH_FLOW_GRAPH_DATA_SUCCESS';
export const GET_CASH_FLOW_GRAPH_DATA_RESET =
  'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_CASH_FLOW_GRAPH_DATA_RESET';
export const GET_CASH_FLOW_GRAPH_DATA_FAILURE =
  'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_CASH_FLOW_GRAPH_DATA_FAILURE';
export const GET_PLAN_LIST = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_PLAN_LIST';
export const GET_PLAN_LIST_SUCCESS =
  'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_PLAN_LIST_SUCCESS';
export const GET_PLAN_LIST_FAILURE =
  'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_PLAN_LIST_FAILURE';
export const SET_CHECKED_PROJECT_LIST =
  'CBRE-PJM-CAPITAL-PLAN-DETAILS/SET_CHECKED_PROJECT_LIST';
export const GET_ALL_LOOKUPS_FAILURE =
  'CBRE-PROJECT-MANAGEMENT-PROJECTLIST/GET_ALL_LOOKUPS_FAILURE';
export const GET_ALL_LOOKUPS_SUCCESS =
  'CBRE-PROJECT-MANAGEMENT-PROJECTLIST/GET_ALL_LOOKUPS_SUCCESS';
export const GET_ALL_SIMPLE_LOOKUPS_SUCCESS =
  'CBRE-PROJECT-MANAGEMENT-PROJECTLIST/GET_ALL_SIMPLE_LOOKUPS_SUCCESS';
export const GET_ALL_SIMPLE_LOOKUPS_FAILURE =
  'CBRE-PROJECT-MANAGEMENT-PROJECTLIST/GET_ALL_SIMPLE_LOOKUPS_FAILURE';
export const GET_ALL_CONTACT_LOOKUPS_SUCCESS =
  'CBRE-PROJECT-MANAGEMENT-PROJECTLIST/GET_ALL_CONTACT_LOOKUPS_SUCCESS';
export const GET_ALL_CONTACT_LOOKUPS_FAILURE =
  'CBRE-PROJECT-MANAGEMENT-PROJECTLIST/GET_ALL_CONTACT_LOOKUPS_FAILURE';

export const GET_PROJECT_LIST = 'CBRE-PJM-PM-PROJECT-DETAILS/GET_PROJECT_LIST';
export const GET_PROJECT_LIST_SUCCESS =
  'CBRE-PJM-PM-PROJECT-DETAILS/GET_PROJECT_LIST_SUCCESS';
export const GET_PROJECT_LIST_FAILURE =
  'CBRE-PJM-PM-PROJECT-DETAILS/GET_PROJECT_LIST_FAILURE';

export const GET_PROJECT_COLUMNS =
  'CBRE-PJM-PM-PROJECT-DETAILS/GET_PROJECT_COLUMNS';
export const GET_PROJECT_COLUMNS_SUCCESS =
  'CBRE-PJM-PM-PROJECT-DETAILS/GET_PROJECT_COLUMNS_SUCCESS';
export const GET_PROJECT_COLUMNS_FAILURE =
  'CBRE-PJM-PM-PROJECT-DETAILS/GET_PROJECT_COLUMNS_FAILURE';

export const GET_MANAGE_COLUMNS =
  'CBRE-PJM-PM-PROJECT-DETAILS/GET_MANAGE_COLUMNS';
export const GET_MANAGE_COLUMNS_SUCCESS =
  'CBRE-PJM-PM-PROJECT-DETAILS/GET_MANAGE_COLUMNS_SUCCESS';
export const GET_MANAGE_COLUMNS_FAILURE =
  'CBRE-PJM-PM-PROJECT-DETAILS/GET_MANAGE_COLUMNS_FAILURE';

/* ---------------------Push to kahua ----------------------------------------------------- */

export const PUSH_TO_KAHUA_SUCCESS =
  'CBRE-PJM-PM-PROJECTS-LIST/PUSH_TO_KAHUA_SUCCESS';
export const PUSH_TO_KAHUA_FAILURE =
  'CBRE-PJM-PM-PROJECTS-LIST/PUSH_TO_KAHUA_FAILURE';
export const PUSH_TO_KAHUA_START =
  'CBRE-PJM-PM-PROJECTS-LIST/PUSH_TO_KAHUA_START';
export const PUSH_TO_KAHUA_RESET =
  'CBRE-PJM-PM-PROJECTS-LIST/PUSH_TO_KAHUA_RESET';

/* ---------------------------Get to Kahua----------------------------------------------- */

export const GET_FROM_KAHUA_SUCCESS =
  'CBRE-PJM-PM-PROJECTS-LIST/GET_FROM_KAHUA_SUCCESS';
export const GET_FROM_KAHUA_FAILURE =
  'CBRE-PJM-PM-PROJECTS-LIST/GET_FROM_KAHUA_FAILURE';
export const GET_FROM_KAHUA_START =
  'CBRE-PJM-PM-PROJECTS-LIST/GET_FROM_KAHUA_START';
export const GET_FROM_KAHUA_RESET =
  'CBRE-PJM-PM-PROJECTS-LIST/GET_FROM_KAHUA_RESET';

/* ---------------------------Get Program Kahua Status Update ----------------------------------------------- */

export const UPDATE_FROM_KAHUA_SUCCESS =
  'CBRE-PJM-PM-PROJECTS-LIST/GET_STATUS_UPDATE_SUCCESS';
export const UPDATE_FROM_KAHUA_FAILURE =
  'CBRE-PJM-PM-PROJECTS-LIST/GET_STATUS_UPDATE_FAILURE';
export const UPDATE_FROM_KAHUA_START =
  'CBRE-PJM-PM-PROJECTS-LIST/GET_STATUS_UPDATE_START';
export const UPDATE_FROM_KAHUA_RESET =
  'CBRE-PJM-PM-PROJECTS-LIST/GET_STATUS_UPDATE_RESET';

// polling api

export const POLLING_API_SUCCESS =
  'CBRE-PJM-PM-PROJECTS-LIST/POLLING_API_SUCCESS';
export const POLLING_API_FAILURE =
  'CBRE-PJM-PM-PROJECTS-LIST/POLLING_API_FAILURE';
export const POLLING_API_START = 'CBRE-PJM-PM-PROJECTS-LIST/POLLING_API_START';
export const POLLING_API_RESET = 'CBRE-PJM-PM-PROJECTS-LIST/POLLING_API_RESET';
export const UPDATE_CP_TAB_COUNT =
  'CBRE-PJM-PM-PROJECT-LIST/UPDATE_CP_TAB_COUNT';
export const UPDATE_CP_MILESTONE_TAB_COUNT =
  'CBRE-PJM-PM-PROJECT-LIST/UPDATE_CP_MILESTONE_TAB_COUNT';
export const RESET_COLUMNS =
  'CBRE-PJM-PM-PROJECT-LIST/RESET_COLUMNS';

export const projectStatusMapping = {
  'Pending Approval': 'Pending Approval',
  Closed: 'Closed',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
  Lost: 'Lost',
  'On Hold': 'On Hold',
  Approved: 'Approved',
  Pipeline: 'Pipeline'
};
