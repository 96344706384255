
/* eslint-disable */
import {
  MIGRATION_SUBMIT_START,
  MIGRATION_SUBMIT_END,
  ADD_MIGRATION_START,
  OPEN_ADD_MIGRATION_DIALOG,
  OPEN_EDIT_MIGRATION_DIALOG,
  CLOSE_ADD_MIGRATION_DIALOG,
  ADD_MIGRATION_SUCCESS,
  EDIT_MIGRATION_SUCCESS,
  ADD_MIGRATION_FAILURE,
  CLOSE_ADD_MIGRATION_TOAST,
  OPEN_EDIT_MIGRATION_DIALOG_SUCCESS,
  OPEN_EDIT_MIGRATION_DIALOG_FAILURE,
  GET_ALL_LOOKUPS_SUCCESS,
  GET_ALL_LOOKUPS_FAILURE,
  GET_KAHUA_APPS_SUCCESS,
  GET_KAHUA_APPS_FAILURE,
} from '../constants';

const INIT_STATE = {
  open: false,
  mode: '',
  loading: false,
  error: false,
  errorCode: '',
  alert: '',
  simpleLookups: [],
  migration :{},
  alertMessage: '',
  editDialogOpen: false,
  isAddEditSubmitTriggered:false,
  defaultValues: {},
  migrationsGridRenderCount: 0,
  status: '',
  kahuaApps:[],
  reloadMigrationList:false,
  initialLoad : true
  };

export default (state = INIT_STATE, action) => {
  switch (action.type) {
      case OPEN_ADD_MIGRATION_DIALOG:
      return {
        ...state,
        open: true,
        mode: 'add',
      };
    case OPEN_EDIT_MIGRATION_DIALOG_SUCCESS:
      return {
        ...state,
        open: true,
        mode: 'edit',
        migration: action.payload,
        migrationId: action.payload.migrationId,
        editDialogOpen: true,
        loading: false,
        status: '',
      };
    case OPEN_EDIT_MIGRATION_DIALOG_FAILURE:
      return {
        ...state,
        open: false,
        mode: '',
        loading: false,
      };
    case CLOSE_ADD_MIGRATION_DIALOG:
      return {
        ...state,
        open: false,
        mode: '',
        migrationId: '',
        editDialogOpen: false,
        loading: false,
      };
    case ADD_MIGRATION_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case ADD_MIGRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        migration: action.payload,
        alert: 'success',
        alertMessage: 'Migration Added Successfully',
        open: false,
        reloadMigrationList : true,
        initialLoad : false
        // migrationsGridRenderCount: state.migrationsGridRenderCount + 1,
        // mode: ''
      };
    case EDIT_MIGRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        alert: 'success',
        alertMessage: 'Migration Updated Successfully',
        open: false,
        editDialogOpen: false,
        reloadMigrationList : true,
        initialLoad : false,
        // migrationsGridRenderCount: state.migrationsGridRenderCount + 1,
        //   mode: ''
        status: 'success',
        ...action.payload,
      };
    case ADD_MIGRATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
        defaultValues: action.payload.migration,
      };
    case CLOSE_ADD_MIGRATION_TOAST:
      return {
        ...state,
        alert: 'close',
        alertMessage: '',
      };
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        simpleLookups : action.payload,
        loading: false,
        fetchLookupsStatus: 'success',
      };
    case GET_ALL_LOOKUPS_FAILURE:
      return {
        ...state,
        fetchLookupsError: true,
        loading: false,
        fetchLookupsStatus: 'error',
      };
      case GET_KAHUA_APPS_SUCCESS:
      return {
        ...state,
        kahuaApps : action.payload,
        loading: false,
        fetchLookupsStatus: 'success',
      };
    case GET_KAHUA_APPS_FAILURE:
      return {
        ...state,
        fetchLookupsError: true,
        loading: false,
        fetchLookupsStatus: 'error',
      };
    // case RESET_ADD_PLAN_TOAST:
    //   return {
    //     ...state,
    //     alert: '',
    //     alertMessage: '',
    //     status: '',
    //   };
    case MIGRATION_SUBMIT_START:
      return {
        ...state,
        isAddEditSubmitTriggered:true
      };
    
    case MIGRATION_SUBMIT_END:
      return {
        ...state,
        isAddEditSubmitTriggered:false
      };
    default:
      return state;
  }
};
