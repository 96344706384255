
/* eslint-disable */
import {
  GET_CLIENT_DATA_START,
  GET_CLIENT_DATA_SUCCESS,
  GET_CLIENT_DATA_FAILURE,
  GET_CLIENT_DATA_RESET,
} from './constants';

const initialState = {
  clientsList: {},
  loading: true,
  totalRecords: 0,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CLIENT_DATA_START: {
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    }
    case GET_CLIENT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        clientsList: action.payload.recordsList,
        totalRecords: action.payload.totalRecords,
      };
    }
    case GET_CLIENT_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    }
    case GET_CLIENT_DATA_RESET: {
      return {
        ...state,
        loading: true,
        clientsList: {},
      };
    }
    default:
      return state;
  }
};
