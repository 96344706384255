class CurrentProjectIdSingleton {
    constructor() {
      if (!CurrentProjectIdSingleton.instance) {
        this.currentProjectId = null;
        CurrentProjectIdSingleton.instance = this;
      }
  
      return CurrentProjectIdSingleton.instance;
    }
  
    getCurrentProjectId() {
      return this.currentProjectId;
    }
  
    setCurrentProjectId(projectId) {
      this.currentProjectId = projectId;
    }
  }
  
  const instance = new CurrentProjectIdSingleton();
  
  export default instance;