
/* eslint-disable */
import {
  ENABLE_PLAN_EDIT,
  ENABLE_PLAN_DELETE,
  GET_CLIENT_FILTER_START,
  GET_CLIENT_FILTER_RESET,
  GET_CLIENT_FILTER_SUCCESS,
  GET_CLIENT_FILTER_FAILURE,
  UPDATE_CP_TAB_COUNT,
} from './constants';

const INIT_STATE = {
  selectedPlanId: '',
  selectedPlanEditClickCount: 0,
  key: 'plans',
  clientFilterDetails: {},
  error: false,
  errorCode: '',
  loading: true,
  planListCount: 0,
  pipelineListCount: 0,
  allProjectsCount: 0,
  programListCount: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ENABLE_PLAN_DELETE:
      return {
        ...state,
        selectedPlanId: action.payload.id,
        selectedPlanEditClickCount: action.payload.clickCount,
      };
    case ENABLE_PLAN_EDIT:
      return {
        ...state,
        selectedPlanId: action.payload.id,
        selectedPlanEditClickCount: action.payload.clickCount,
      };
    case GET_CLIENT_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        clientFilterDetails: action.payload,
      };
    case GET_CLIENT_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_CLIENT_FILTER_RESET:
      return {
        ...state,
        loading: false,
        clientFilterDetails: {},
      };
    case GET_CLIENT_FILTER_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case UPDATE_CP_TAB_COUNT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
