/* eslint-disable jsx-a11y/control-has-associated-label,react/jsx-filename-extension */
import React from 'react';
import { useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import { handleMediaDownload } from 'modules/cbre-pjm-Common/utils/Auth';

const HelpInfoPopup = () => {
  const configInfo = useSelector((state) => state.config);
  const clients = useSelector((state) => state.config);
  const router = useSelector((state) => state.router);
  const coreAssetUrl =
    configInfo && configInfo.client && configInfo.client.coreUrl
      ? `${configInfo.client.coreUrl}`
      : '';

  const handlePdfDownload = async (fileName) => {
    return await handleMediaDownload({
      downloadUrl: `${coreAssetUrl}asset/files/${fileName}`,
      fileName: fileName,
    });
  };

  const emailAddress =
    clients && clients.client && clients.client.pjmHelpEmailAddress
      ? clients.client.pjmHelpEmailAddress
      : '';
  const pathName =
    router && router.location && router.location.pathname
      ? router.location.pathname
      : '';
  const pathNameLowerCase = pathName.toLowerCase();

  return (
    <div>
      <div className="user-profile">
        <div className="user-detail ml-2">
          <h4 className="user-name mb-0">
            <IntlMessages id="popup.help&Support" />
          </h4>
        </div>
      </div>
      <span
        className="pjm-link dropdown-item text-muted word-wrap"
        onClick={() => handlePdfDownload('PJM_Technology_Stack_Overview.pdf')}
      >
        <i className="zmdi zmdi-collection-pdf zmdi-hc-fw mr-1 zmdi-hc-lg" />
        <IntlMessages id="popup.pjmStackOverviewPdf" />
      </span>
      {pathNameLowerCase.indexOf('/capitalplan') !== -1 && (
        <span
          className="pjm-link dropdown-item text-muted word-wrap"
          onClick={() => handlePdfDownload('CBRE Algorithm Criteria.pdf')}
        >
          <i className="zmdi zmdi-collection-folder-image zmdi-hc-fw mr-1 zmdi-hc-lg" />
          <IntlMessages id="popup.documentation" />
        </span>
      )}
      {(pathNameLowerCase.indexOf('/pda') !== -1 ||
        pathNameLowerCase.indexOf('/cpp') !== -1) && (
        <span
          className="pjm-link dropdown-item text-muted word-wrap"
          onClick={() =>
            handlePdfDownload('PDA_CPP_and_Kahua_User_Guide_v1.2.pdf')
          }
        >
          <i className="zmdi zmdi-collection-folder-image zmdi-hc-fw mr-1 zmdi-hc-lg" />
          <IntlMessages id="popup.documentation" />
        </span>
      )}
      <span className="pjm-link dropdown-item text-muted word-wrap">
        <i className="zmdi zmdi-email zmdi-hc-fw mr-1 zmdi-hc-lg" />
        <a style={{ textDecoration: 'none' }} href={`mailto:${emailAddress}`}>
          <IntlMessages id="popup.contactUs" />
        </a>
      </span>
      {/* <span className="pjm-link dropdown-item text-muted">
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-1"/>
          <IntlMessages id="popup.setting"/>
        </span> */}
      {/* <span className="pjm-link dropdown-item text-muted" onClick={() => {
        dispatch(userSignOut())
      }}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1"/>
          <IntlMessages id="popup.logout"/>
        </span> */}
    </div>
  );
};

export default HelpInfoPopup;
