export const GET_TEMPLATE_DATA_START = 'CBRE-PJM-CAPITAL-PLAN-EXPORT-TO-KAHUA/GET_TEMPLATE_DATA_START';
export const GET_TEMPLATE_DATA_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-EXPORT-TO-KAHUA/GET_TEMPLATE_DATA_SUCCESS';
export const GET_TEMPLATE_DATA_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-EXPORT-TO-KAHUA/GET_TEMPLATE_DATA_FAILURE';
export const RESET_KAHUA_TEMPLATE = 'CBRE-PJM-CAPITAL-PLAN-EXPORT-TO-KAHUA/RESET_KAHUA_TEMPLATE';
export const GET_KAHUA_LOCATION_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-EXPORT-TO-KAHUA/GET_KAHUA_LOCATION_SUCCESS';
export const GET_KAHUA_LOCATION_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-EXPORT-TO-KAHUA/GET_KAHUA_LOCATION_FAILURE';
export const GET_KAHUA_LOCATION_START = 'CBRE-PJM-CAPITAL-PLAN-EXPORT-TO-KAHUA/GET_KAHUA_LOCATION_START';
export const EXPORT_TO_KAHUA_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-EXPORT-TO-KAHUA/EXPORT_TO_KAHUA_SUCCESS';
export const EXPORT_TO_KAHUA_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-EXPORT-TO-KAHUA/EXPORT_TO_KAHUA_FAILURE';
export const EXPORT_TO_KAHUA_START = 'CBRE-PJM-CAPITAL-PLAN-EXPORT-TO-KAHUA/EXPORT_TO_KAHUA_START';
export const EXPORT_TO_KAHUA_RESET = 'CBRE-PJM-CAPITAL-PLAN-EXPORT-TO-KAHUA/EXPORT_TO_KAHUA_RESET';
