/*
* The SurveyBeacon component can be used to load the SurveyGizmo Beacon script when the application is rendered.
* This script is used to track user interactions with the survey.
* The script is appended to the body of the document, and the clean-up function removes the script when the component is unmounted.
*/
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getFeatureFlag } from 'containers/Services/FeatureFlagProvider';

const SurveyBeacon = ({ uuid, app, surveyBeaconKey }) => {
  const routerInfo = useSelector((state) => state.router);
  const featureName = 'pjm_platform_uem_survey';
  const defaultConfig = {
    showSurveyBeacon: false,
  };
  const surveyBeaconFlag =
      getFeatureFlag(featureName, defaultConfig, 'json') === 'on';

  useEffect(() => {
    if (!surveyBeaconFlag) {
      return;
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
      (function(d,e,j,h,f,c,b){d.SurveyGizmoBeacon=f;d[f]=d[f]||function(){(d[f].q=d[f].q||[]).push(arguments)};c=e.createElement(j),b=e.getElementsByTagName(j)[0];c.async=1;c.src=h;b.parentNode.insertBefore(c,b)})(window,document,'script','//d2bnxibecyz4h5.cloudfront.net/runtimejs/intercept/intercept.js','sg_beacon');
      sg_beacon('init', '${surveyBeaconKey}');
      sg_beacon('data','uuid','${uuid}');
      sg_beacon('data','app','${app}');
    `;

    // Append the script to the body
    document.body.appendChild(script);

    // Clean up function
    return () => {
      document.body.removeChild(script);
    };
  }, [uuid, app, surveyBeaconKey, routerInfo.location.pathname, surveyBeaconFlag]);

  return null;
};

export default SurveyBeacon;
