import {
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  SET_ERROR_TRUE,
  SET_ERROR_FALSE,
  SET_SUCCESS_TRUE,
  SET_SUCCESS_FALSE,
  GET_ERP_SYSTEM_LIST_START,
  GET_ERP_SYSTEM_LIST_FAILURE,
  GET_ERP_SYSTEM_LIST_SUCCESS,
  GET_ERP_SYSTEM_LIST_RESET,
} from './constants';

const INITIAL_STATE = {
  loading: false,
  isError: false,
  errorMessage: '',
  isSuccess: false,
  successMessage: '',
  erpSystemList: [],
  isErpOnboarded: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case SET_ERROR_TRUE:
      return {
        ...state,
        isError: true,
        errorMessage: action.payload,
      };
    case SET_ERROR_FALSE:
      return {
        ...state,
        isError: false,
        errorMessage: '',
      };
    case SET_SUCCESS_TRUE:
      return {
        ...state,
        isSuccess: true,
        successMessage: action.payload,
      };
    case SET_SUCCESS_FALSE:
      return {
        ...state,
        isSuccess: false,
        successMessage: '',
      };
    case GET_ERP_SYSTEM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        erpSystemList: action?.payload?.data,
        isErpOnboarded: action?.payload?.isErpOnboarded,
      };

    case GET_ERP_SYSTEM_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        erpSystemList: action?.payload?.data,
        isErpOnboarded: false,
      };
    case GET_ERP_SYSTEM_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case GET_ERP_SYSTEM_LIST_RESET:
      return {
        ...state,
        loading: false,
        erpSystemList: [],
      };
    default:
      return state;
  }
};
