
/* eslint-disable */
import {
  GET_ALL_LOOKUPS_SUCCESS,
} from './constants';

const INIT_STATE = {
  module: "cbre-pjm-manage-teams.",
  approvalLevelList:[],
  rolesList:[],
  contactList:[],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
