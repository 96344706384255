/* eslint-disable */
import {
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  COST_PLAN_DELETE_START,
  COST_PLAN_DELETE_SUCCESS,
  COST_PLAN_DELETE_FAILURE,
  COST_PLAN_DELETE_RESET,
  COST_PLAN_STATE_UPDATE,
  COST_PLAN_OBJ_REQUESTED,
  GET_DEFAULT_WORKPACKAGE_START,
  GET_DEFAULT_WORKPACKAGE_FAILURE,
  GET_DEFAULT_WORKPACKAGE_SUCCESS,
  WORKPACKAGE_ADD_SUCCESS,
  WORKPACKAGE_ADD_START,
  WORKPACKAGE_ADD_FAILURE,
  GET_PURCHASE_ORDERS_SUCCESS,
  GET_PURCHASE_ORDERS_FAILURE,
  GET_VENDOR_INVOICES_SUCCESS,
  GET_VENDOR_INVOICES_FAILURE,
  GET_PURCHASE_REQUEST_START,
  GET_PURCHASE_REQUEST_SUCCESS,
  GET_PURCHASE_REQUEST_FAILURE,
  GET_PURCHASE_REQUEST_DETAILS_START,
  GET_PURCHASE_REQUEST_DETAILS_RESET,
  GET_PURCHASE_REQUEST_DETAILS_SUCCESS,
  GET_PURCHASE_REQUEST_DETAILS_FAILURE,
  GET_ALL_LOOKUPS_FAILURE,
  GET_ALL_LOOKUPS_SUCCESS,
  GET_CHANGE_REQUEST_START,
  GET_CHANGE_REQUEST_SUCCESS,
  GET_CHANGE_REQUEST_FAILURE,
  GET_CHANGE_REQUEST_DETAIL_START,
  GET_CHANGE_REQUEST_DETAIL_RESET,
  GET_CHANGE_REQUEST_DETAIL_SUCCESS,
  GET_CHANGE_REQUEST_DETAIL_FAILURE,
  GET_LAST_CHANGE_REQUEST_DETAIL_START,
  GET_LAST_CHANGE_REQUEST_DETAIL_SUCCESS,
  GET_LAST_CHANGE_REQUEST_DETAIL_FAILURE,
  MYBUY_ACTION_START,
  MYBUY_ACTION_SUCCESS,
  MYBUY_ACTION_FAILURE,
  BUDGET_VALIDATION_ERROR,
} from './constants';

const INITIAL_STATE = {
  actionState: null,
  error: false,
  errorCode: '',
  loading: false,
  defaultWorkpackages: [],
  workPackageAddInfo: {
    message: '',
    messageCode: '',
    loading: false,
  },
  costPlanRequestedObjDEF: {},
  vendorsListData: [],
  costPlanDeleteInfo: {
    message: '',
    messageCode: '',
    loading: false,
    type: '',
  },
  purchaseOrdersData: {},
  vendorInvoicesData: {},
  purchaseRequestData: null,
  purchaseRequestDetails: null,
  changeRequestDetails: null,
  simpleLookups: [],
  fetchLookupsStatus: '',
  fetchLookupsError: false,
  changeRequestData: null,
  budgetValidationError: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MYBUY_ACTION_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MYBUY_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        actionState: action.payload, // Update action state
      };
    case MYBUY_ACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false
      };
    case WORKPACKAGE_ADD_SUCCESS:
      return {
        ...state,
        workPackageAddInfo: {
          message: 'success',
          loading: false,
          messageCode: '',
        },
        loading: false,
      };
    case WORKPACKAGE_ADD_FAILURE:
      return {
        ...state,
        workPackageAddInfo: {
          ...action.payload,
          loading: false,
        },
        loading: false,
      };
    case WORKPACKAGE_ADD_START:
      return {
        ...state,
        workPackageAddInfo: {
          message: '',
          messageCode: '',
          loading: true,
        },
        loading: true,
      };
    case COST_PLAN_DELETE_SUCCESS:
      return {
        ...state,
        costPlanDeleteInfo: {
          message: 'success',
          loading: false,
          messageCode: '',
          type: action.payload.isType
        },
        loading: false,
      };
    case COST_PLAN_DELETE_FAILURE:
      return {
        ...state,
        costPlanDeleteInfo: {
          ...action.payload,
          loading: false,
          type: '',
        },
        loading: false,
      };
    case COST_PLAN_DELETE_START:
      return {
        ...state,
        costPlanDeleteInfo: {
          message: '',
          messageCode: '',
          loading: true,
          type: '',
        },
        loading: true,
      };
    case COST_PLAN_DELETE_RESET:
      return {
        ...state,
        costPlanDeleteInfo: {
          message: '',
          messageCode: '',
          loading: false,
          type: '',
        },
        loading: false,
      };
    case COST_PLAN_STATE_UPDATE:
      return {
        ...state,
        costPlanCheckBlock: action.payload,
      };
    case COST_PLAN_OBJ_REQUESTED:
      return {
        ...state,
        costPlanRequestedObjDEF: action.payload ? action.payload : {},
      };
    case GET_DEFAULT_WORKPACKAGE_SUCCESS:
      return {
        ...state,
        defaultWorkpackages: action.payload,
        loading: false,
      };
    case GET_DEFAULT_WORKPACKAGE_START:
      return {
        ...state,
        defaultWorkpackages: [],
        loading: true,
      };
    case GET_DEFAULT_WORKPACKAGE_FAILURE:
      return {
        ...state,
        defaultWorkpackages: [],
        loading: false,
      };
    case GET_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        purchaseOrdersData: action.payload,
      };
    case GET_PURCHASE_ORDERS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_VENDOR_INVOICES_SUCCESS:
      return {
        ...state,
        vendorInvoicesData: action.payload,
      };
    case GET_VENDOR_INVOICES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_PURCHASE_REQUEST_START:
      return {
        ...state,
        purchaseRequestData: null,
        loading: true,
      };
    case GET_PURCHASE_REQUEST_SUCCESS:
      return {
        ...state,
        purchaseRequestData: action.payload,
        loading: false,
      };
    case GET_PURCHASE_REQUEST_FAILURE:
      return {
        ...state,
        purchaseRequestData: null,
        loading: false,
      };
    case GET_PURCHASE_REQUEST_DETAILS_START:
      return {
        ...state,
        purchaseRequestDetails: null,
        loading: true,
      };
    case GET_PURCHASE_REQUEST_DETAILS_RESET:
      return {
        ...state,
        purchaseRequestDetails: null,
        loading: true,
      };
    case GET_PURCHASE_REQUEST_DETAILS_SUCCESS:
      return {
        ...state,
        purchaseRequestDetails: action.payload,
        loading: false,
      };
    case GET_PURCHASE_REQUEST_DETAILS_FAILURE:
      return {
        ...state,
        purchaseRequestDetails: null,
        loading: false,
      };
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        fetchLookupsStatus: 'success',
      };
    case GET_ALL_LOOKUPS_FAILURE:
      return {
        ...state,
        fetchLookupsError: true,
        loading: false,
        fetchLookupsStatus: 'error',
      };
    case GET_CHANGE_REQUEST_START:
      return {
        ...state,
        changeRequestData: null,
        loading: true,
      };
    case GET_CHANGE_REQUEST_SUCCESS:
      return {
        ...state,
        changeRequestData: action.payload,
        loading: false,
      };
    case GET_CHANGE_REQUEST_FAILURE:
      return {
        ...state,
        changeRequestData: null,
        loading: false,
      };
    case GET_LAST_CHANGE_REQUEST_DETAIL_START:
      return {
        ...state,
        lastChangeRequestDetail: null,
      };
    case GET_LAST_CHANGE_REQUEST_DETAIL_SUCCESS:
      return {
        ...state,
        lastChangeRequestDetail: action.payload,
      };
    case GET_LAST_CHANGE_REQUEST_DETAIL_FAILURE:
      return {
        ...state,
        lastChangeRequestDetail: null,
      };
    case GET_CHANGE_REQUEST_DETAIL_START:
      return {
        ...state,
        changeRequestDetails: null,
        loading: true,
      };
    case GET_CHANGE_REQUEST_DETAIL_RESET:
      return {
        ...state,
        changeRequestDetails: null,
        loading: true,
      };
    case GET_CHANGE_REQUEST_DETAIL_SUCCESS:
      return {
        ...state,
        changeRequestDetails: action.payload,
        loading: false,
      };
    case GET_CHANGE_REQUEST_DETAIL_FAILURE:
      return {
        ...state,
        changeRequestDetails: null,
        loading: false,
      };
    case BUDGET_VALIDATION_ERROR:
      return {
        ...state,
        budgetValidationError: action.payload,
      }
    default:
      return state;
  }
};
