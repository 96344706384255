export const SET_LOADING_TRUE =
  'CBRE_PJM_PRINCIPAL_LITE_FINANCIAL_OVERVIEW/SET_LOADING_TRUE';
export const SET_LOADING_FALSE =
  'CBRE_PJM_PRINCIPAL_LITE_FINANCIAL_OVERVIEW/SET_LOADING_FALSE';
export const GET_FINANCIAL_OVERVIEW_SUCCESS =
  'CBRE_PJM_PRINCIPAL_LITE_FINANCIAL_OVERVIEW/GET_FINANCIAL_OVERVIEW_SUCCESS';
export const GET_FINANCIAL_OVERVIEW_FAILURE =
  'CBRE_PJM_PRINCIPAL_LITE_FINANCIAL_OVERVIEW/GET_FINANCIAL_OVERVIEW_FAILURE';
export const RESET_FINANCIAL_OVERVIEW_MESSAGE =
  'CBRE_PJM_PRINCIPAL_LITE_FINANCIAL_OVERVIEW/RESET_FINANCIAL_OVERVIEW_MESSAGE';
