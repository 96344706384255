export const RESET_SEARCH_ADD_USER = 'CBRE-PJM-APPROVALS/RESET_SEARCH_ADD_USER';
export const DISABLE_SEARCH_ADD_USER = 'CBRE-PJM-APPROVALS/DISABLE_SEARCH_ADD_USER';
export const START_SEARCH_ADD_USER = 'CBRE-PJM-APPROVALS/START_SEARCH_ADD_USER';
export const SUCCESS_SEARCH_ADD_USER = 'CBRE-PJM-APPROVALS/SUCCESS_SEARCH_ADD_USER';
export const FAILURE_SEARCH_ADD_USER = 'CBRE-PJM-APPROVALS/FAILURE_SEARCH_ADD_USER';
export const GET_ALL_LOOKUPS_SUCCESS = 'CBRE-PJM-APPROVALS/GET_ALL_LOOKUPS_SUCCESS';
export const GET_ALL_LOOKUPS_FAILURE = 'CBRE-PJM-APPROVALS/GET_ALL_LOOKUPS_FAILURE';
export const GET_ALL_USER_ROLES_SUCCESS = 'CBRE-PJM-APPROVALS/GET_ALL_USER_ROLES_SUCCESS';
export const GET_ALL_USER_ROLES_FAILURE = 'CBRE-PJM-APPROVALS/GET_ALL_USER_ROLES_FAILURE';
export const GET_ALL_USER_ROLES_RESET = 'CBRE-PJM-APPROVALS/GET_ALL_USER_ROLES_RESET';
export const GET_ALL_USER_ROLES_START = 'CBRE-PJM-APPROVALS/GET_ALL_USER_ROLES_START';
export const GET_APPROVERS_LIST_SUCCESS = 'CBRE-PJM-APPROVALS/GET_APPROVERS_LIST_SUCCESS';
export const GET_APPROVERS_LIST_FAILURE = 'CBRE-PJM-APPROVALS/GET_APPROVERS_LIST_FAILURE';
export const GET_APPROVERS_LIST_START = 'CBRE-PJM-APPROVALS/GET_APPROVERS_LIST_START';
export const GET_APPROVERS_LIST_RESET = 'CBRE-PJM-APPROVALS/GET_APPROVERS_LIST_RESET';
export const APPROVALS_SAVE_START = 'CBRE-PJM-APPROVALS/APPROVALS_SAVE_START';
export const APPROVALS_SAVE_SUCCESS = 'CBRE-PJM-APPROVALS/APPROVALS_SAVE_SUCCESS';
export const APPROVALS_SAVE_FAILURE = 'CBRE-PJM-APPROVALS/APPROVALS_SAVE_FAILURE';
export const APPROVALS_RESET = 'CBRE-PJM-APPROVALS/APPROVALS_RESET';
export const GET_PROJECT_APPROVERS_LIST_SUCCESS = 'CBRE-PJM-APPROVALS/GET_PROJECT_APPROVERS_LIST_SUCCESS';
export const GET_PROJECT_APPROVERS_LIST_FAILURE = 'CBRE-PJM-APPROVALS/GET_PROJECT_APPROVERS_LIST_FAILURE';
export const GET_PROJECT_APPROVERS_LIST_START = 'CBRE-PJM-APPROVALS/GET_PROJECT_APPROVERS_LIST_START';
export const GET_PROJECT_APPROVERS_LIST_RESET = 'CBRE-PJM-APPROVALS/GET_PROJECT_APPROVERS_LIST_RESET';
export const APPROVALS_SUBMIT_START = 'CBRE-PJM-APPROVALS/APPROVALS_SUBMIT_START';
export const APPROVALS_SUBMIT_SUCCESS = 'CBRE-PJM-APPROVALS/APPROVALS_SUBMIT_SUCCESS';
export const APPROVALS_SUBMIT_FAILURE = 'CBRE-PJM-APPROVALS/APPROVALS_SUBMIT_FAILURE';
export const APPROVALS_SUBMIT_RESET = 'CBRE-PJM-APPROVALS/APPROVALS_SUBMIT_RESET';
export const OVERRIDE_APPROVER_SUCCESS = 'CBRE-PJM-APPROVALS/OVERRIDE_APPROVER_SUCCESS';
export const OVERRIDE_APPROVER_FAILURE = 'CBRE-PJM-APPROVALS/OVERRIDE_APPROVER_FAILURE';
export const OVERRIDE_APPROVER_RESET = 'CBRE-PJM-APPROVALS/OVERRIDE_APPROVER_RESET';
export const NO_APPROVAL_BANDING_FOUND = 'CBRE-PJM-APPROVALS/NO_APPROVAL_BANDING_FOUND';
