
/* eslint-disable */
import {
  IMPORT_PROJECT_FAILURE,
  INITIATE_IMPORT_FAILURE,
  GET_IMPORTS_DETAILS_SUCCESS,
  GET_IMPORTS_DETAILS_FAILURE,
  GET_IMPORTS_LIST_SUCCESS,
  GET_IMPORTS_LIST_START,
  GET_IMPORTS_LIST_FAILURE,
  SET_SELECTED_JOB_ID,
  SET_LOADING_TRUE,
  GET_IMPORTS_DROPDOWN_LIST_SUCCESS,
  GET_IMPORTS_DROPDOWN_LIST_FAILURE
} from './constants';

const INITIAL_STATE = {
  importJobList: [],
  importDropDownList: [],
  selectedImportJobId: {},
  currentImportDetails: {},
  selectedJobId: '',
  loading: true,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_IMPORTS_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case GET_IMPORTS_LIST_SUCCESS:
      console.log('action.payload---', action.payload);
      return {
        ...state,
        loading: action.payload && action.payload.length === 0 ? false : state.loading,
        currentImportDetails: action.payload && action.payload.length === 0 ? {} : state.currentImportDetails,
        importJobList: action.payload,
      };
    case GET_IMPORTS_LIST_FAILURE:
      console.log('action.payload---', action.payload);
      return {
        ...state,
        loading: false,
      };
    case GET_IMPORTS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentImportDetails: { ...action.payload },
      };
    case GET_IMPORTS_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_IMPORTS_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        importDropDownList: action.payload,
      };
    case GET_IMPORTS_DROPDOWN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case IMPORT_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case INITIATE_IMPORT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case SET_SELECTED_JOB_ID:
      return {
        ...state,
        selectedJobId: action.payload,
      };
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
