export const GET_TRACKER_PROJECTS_LIST_FILTER_SUCCESS = 'CBRE-PJM-PROJECT-TRACKER-PROJECTS-LIST/GET_FILTER_SUCCESS';
export const GET_TRACKER_PROJECTS_LIST_FILTER_FAILURE = 'CBRE-PJM-PROJECT-TRACKER-PROJECTS-LIST/GET_FILTER_FAILURE';
export const GET_TRACKER_PROJECTS_LIST_FILTER_RESET = 'CBRE-PJM-PROJECT-TRACKER-PROJECTS-LIST/GET_FILTER_RESET';

export const OPEN_DELETE_PROJECTTRACKER_DIALOG = 'CBRE-PJM-PROJECT-TRACKER-PROJECTS-LIST/OPEN_DELETE_PROJECTTRACKER_DIALOG';
export const OPEN_DELETE_PROJECTTRACKER_DIALOG_SUCCESS = 'CBRE-PJM-PROJECT-TRACKER-PROJECTS-LIST/OPEN_DELETE_PROJECTTRACKER_DIALOG_SUCCESS';
export const OPEN_DELETE_PROJECTTRACKER_DIALOG_FAILURE = 'CBRE-PJM-PROJECT-TRACKER-PROJECTS-LIST/OPEN_DELETE_PROJECTTRACKER_DIALOG_FAILURE';
export const DELETE_PROJECTTRACKER_START = 'CBRE-PJM-PROJECT-TRACKER-PROJECTS-LIST/DELETE_PROJECTTRACKER_START';
export const DELETE_PROJECTTRACKER_SUCCESS = 'CBRE-PJM-PROJECT-TRACKER-PROJECTS-LIST/DELETE_PROJECTTRACKER_SUCCESS';
export const DELETE_PROJECTTRACKER_FAILURE = 'CBRE-PJM-PROJECT-TRACKER-PROJECTS-LIST/DELETE_PROJECTTRACKER_FAILURE';
export const CLOSE_DELETE_PROJECTTRACKER_TOAST = 'CBRE-PJM-PROJECT-TRACKER-PROJECTS-LIST/CLOSE_DELETE_PROJECTTRACKER_TOAST';
export const CLOSE_DELETE_PROJECTTRACKER_DIALOG = 'CBRE-PJM-PROJECT-TRACKER-PROJECTS-LIST/CLOSE_DELETE_PROJECTTRACKER_DIALOG';
