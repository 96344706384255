
/* eslint-disable */
import {
  GET_CLIENT, ADD_CLIENTS_TO_STATE, ADD_CLIENTS_START,
} from './constants';

export const getClients = (clientsPayload) => {
  return {
    type: GET_CLIENT,
    payload: clientsPayload,
  };
};

export const addClientsToState = (clients) =>
({
  type: ADD_CLIENTS_TO_STATE,
  payload: clients,
});

export const addClientsState = () => ({
  type: ADD_CLIENTS_START,
});
