import {
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  FETCH_MY_FILTERS_FAIL,
  FETCH_MY_FILTERS_SUCCESS,
  HIDE_WIDGET,
  SHOW_WIDGET
} from './constants';

const initialState = { loading: false, filterLookUpData: undefined, hideWidget: true };

const widgetFilterReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING_TRUE:
      return { ...state, loading: true };
    case SET_LOADING_FALSE:
      return { ...state, loading: false };
    case FETCH_MY_FILTERS_FAIL:
      return { ...state };
    case FETCH_MY_FILTERS_SUCCESS:
      return { ...state, ...payload };
    case HIDE_WIDGET:
      return { ...state, hideWidget: true };
    case SHOW_WIDGET:
      return {...state, hideWidget: false}
    default:
      return state;
  }
};
export default widgetFilterReducer;
