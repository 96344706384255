export const OPEN_ADD_PLAN_DIALOG = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/OPEN_ADD_PLAN_DIALOG';
export const OPEN_EDIT_PLAN_DIALOG = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/OPEN_EDIT_PLAN_DIALOG';
export const OPEN_EDIT_PLAN_DIALOG_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/OPEN_EDIT_PLAN_DIALOG_SUCCESS';
export const OPEN_EDIT_PLAN_DIALOG_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/OPEN_EDIT_PLAN_DIALOG_FAILURE';
export const CLOSE_ADD_PLAN_DIALOG = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/CLOSE_ADD_PLAN_DIALOG';
export const ADD_PLAN_START = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/ADD_PLAN_START';
export const ADD_PLAN_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/ADD_PLAN_SUCCESS';
export const EDIT_PLAN_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/EDIT_PLAN_SUCCESS';
export const ADD_PLAN_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/ADD_PLAN_FAILURE';
export const ADD_PLAN_EXISTS_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/ADD_PLAN_EXISTS_FAILURE';
export const RESET_PLAN_EXISTS_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/RESET_PLAN_EXISTS_FAILURE';
export const CLOSE_ADD_PLAN_TOAST = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/CLOSE_ADD_PLAN_TOAST';
export const GET_ALL_CLIENTS_START = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/GET_ALL_CLIENTS_START';
export const GET_ALL_CLIENTS_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/GET_ALL_CLIENTS_SUCCESS';
export const GET_ALL_CLIENTS_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/GET_ALL_CLIENTS_FAILURE';
export const RESET_ADD_PLAN_TOAST = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/RESET_ADD_PLAN_TOAST';
export const RESET_PLAN_MODIFIED = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/RESET_PLAN_MODIFIED';
export const GET_ALL_LOOKUPS_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/GET_ALL_LOOKUPS_SUCCESS';
export const GET_ALL_LOOKUPS_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/GET_ALL_LOOKUPS_FAILURE';
export const PLAN_SUBMIT_START = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/PLAN_SUBMIT_START';
export const PLAN_SUBMIT_END = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/PLAN_SUBMIT_END';
