
/* eslint-disable */
import {
  GET_ALL_LOOKUPS_SUCCESS,
  POST_APPROVAL_START,
  POST_APPROVAL_FAILURE,
  POST_APPROVAL_SUCCESS,
  POST_APPROVAL_RESET
} from './constants';

const INIT_STATE = {
  module: "cbre-pjm-approvals-group.",
  approvalLevelList: [],
  isEdit: false,
  rolesList: [],
  contactList: [],
  postApprovalLoading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case POST_APPROVAL_START:
      return {
        ...state,
        postApprovalLoading: true,
      };
    case POST_APPROVAL_SUCCESS:
      return {
        ...state,
        postApprovalLoading: false,
        postApprovalStatus: 'success',
      };
    case POST_APPROVAL_FAILURE:
      return {
        ...state,
        postApprovalLoading: false,
        postApprovalStatus: 'failure',
      };
    case POST_APPROVAL_RESET:
      return {
        ...state,
        postApprovalLoading: false,
        postApprovalStatus: '',
      };
    default:
      return state;
  }
};
