import {
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  GET_FINANCIAL_OVERVIEW_FAILURE,
  GET_FINANCIAL_OVERVIEW_SUCCESS,
  RESET_FINANCIAL_OVERVIEW_MESSAGE
} from './constants';

const INITIAL_STATE = {
  loading: false,
  financialOverview: {},
  error: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case GET_FINANCIAL_OVERVIEW_SUCCESS:
      return {
        ...state,
        financialOverview: action.payload,
      };
    case GET_FINANCIAL_OVERVIEW_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_FINANCIAL_OVERVIEW_MESSAGE:
      return {
        ...state,
        error: {},
      };
    default:
      return state;
  }
};
