import {
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  FETCH_PLAN_INPUT_DATA_SUCCESS,
  FETCH_PLAN_INPUT_DATA_FAIL,
  UPDATE_PLAN_INPUT_DATA,
  GET_LEGACY_FAILURE,
  GET_LEGACY_SUCCESS,
} from './constants';

const INITIAL_STATE = {
    loading: false,
    planInputData: undefined,
    planInputDataError: undefined,
    allLegacyData: null
};

const planInputReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOADING_TRUE:
            return {
                ...state,
                loading: true
            };
        case SET_LOADING_FALSE:
            return {
                ...state,
                loading: false
            };
        case FETCH_PLAN_INPUT_DATA_SUCCESS:
            return {
                ...state,
                planInputData: action.payload.planInputData,
                loading: false
            };
        case FETCH_PLAN_INPUT_DATA_FAIL:
            return {
                ...state,
                // planInputDataError: action.payload.error,
                loading: false
            };
        case UPDATE_PLAN_INPUT_DATA:
            const newState = {
                ...state,
                planInputData: {
                    ...state.planInputData,
                    ...action.payload
                }
            };
            return newState;
        case GET_LEGACY_SUCCESS:
            return {
                ...state,
                allLegacyData: action.payload,
                loading: false,
            };
        case GET_LEGACY_FAILURE:
            return {
                ...state,
                allLegacyData: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

export default planInputReducer;