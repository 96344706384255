export const OPEN_SEARCH_ADD_USER_DIALOG = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/OPEN_SEARCH_ADD_USER_DIALOG';
export const CLOSE_SEARCH_ADD_USER_DIALOG = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/CLOSE_SEARCH_ADD_USER_DIALOG';
export const RESET_SEARCH_ADD_USER = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/RESET_SEARCH_ADD_USER';

export const DISABLE_SEARCH_ADD_USER = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/DISABLE_SEARCH_ADD_USER';
export const START_SEARCH_ADD_USER = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/START_SEARCH_ADD_USER';

export const SUCCESS_SEARCH_ADD_USER = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/SUCCESS_SEARCH_ADD_USER';
export const FAILURE_SEARCH_ADD_USER = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/FAILURE_SEARCH_ADD_USER';
export const DUPLICATE_SEARCH_ADD_USER = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/DUPLICATE_SEARCH_ADD_USER';
export const CLOSE_TOAST_SEARCH_ADD_USER = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/CLOSE_TOAST_SEARCH_ADD_USER';
export const FETCH_ROLES_SUCCESS = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILURE = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/FETCH_ROLES_FAILURE';
export const UPDATE_ROLE_SUCCESS = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/UPDATE_ROLE_FAILURE';
