export const GET_VENDOR_SUCCESS = 'GET_VENDOR_SUCCESS';
export const GET_VENDOR_FAILURE = 'GET_VENDOR_FAILURE';
export const GET_VENDOR_START = 'GET_VENDOR_START';
export const GET_FUSION_PROJECTS_SUCCESS = 'GET_FUSION_PROJECTS_SUCCESS';
export const GET_FUSION_PROJECTS_FAILURE = 'GET_FUSION_PROJECTS_FAILURE';
export const GET_FUSION_PROJECT_DETAILS_START = 'GET_FUSION_PROJECT_DETAILS_START';
export const GET_FUSION_PROJECT_DETAILS_SUCCESS = 'GET_FUSION_PROJECT_DETAILS_SUCCESS';
export const GET_FUSION_PROJECT_DETAILS_FAILURE = 'GET_FUSION_PROJECT_DETAILS_FAILURE';
export const GET_FUSION_PROJECTS_START = 'GET_FUSION_PROJECTS_START';
export const GET_FUSION_STATUS_LIST_SUCCESS = 'GET_FUSION_STATUS_LIST_SUCCESS';
export const GET_FUSION_STATUS_LIST_FAILURE = 'GET_FUSION_STATUS_LIST_FAILURE';
export const UPDATE_FUSION_PROJECT_STATUS_SUCCESS = 'UPDATE_FUSION_PROJECT_STATUS_SUCCESS';
export const UPDATE_FUSION_PROJECT_STATUS_FAILURE = 'UPDATE_FUSION_PROJECT_STATUS_FAILURE';
export const UPDATE_FUSION_PROJECT_STATUS_START = 'UPDATE_FUSION_PROJECT_STATUS_START';
