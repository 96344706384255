/* eslint-disable */
import {
  GET_ALL_LOOKUPS_SUCCESS,
  POST_ADD_USER_START,
  POST_ADD_USER_FAILURE,
  POST_ADD_USER_SUCCESS,
  POST_ADD_USER_RESET,
  UPDATE_USER_COUNT
} from './constants';

const INIT_STATE = {
  module: "cbre-pjm-manage-team-users.",
  isEdit: false,
  postAddUserLoading: false,
  totalRecords: 0,
  createdCount: 0,
  updatedCount: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case POST_ADD_USER_START:
      return {
        ...state,
        postAddUserLoading: true,
        createdCount: 0,
        updatedCount: 0
      };
    case POST_ADD_USER_SUCCESS:
      return {
        ...state,
        postAddUserLoading: false,
        postAddUserStatus: 'success',
        createdCount: action.payload?.created?.length || 0,
        updatedCount: action.payload?.updated?.length || 0
      };
    case POST_ADD_USER_FAILURE:
      return {
        ...state,
        postAddUserLoading: false,
        postAddUserStatus: 'failure',
      };
    case POST_ADD_USER_RESET:
      return {
        ...state,
        postAddUserLoading: false,
        postAddUserStatus: '',
      };
    case UPDATE_USER_COUNT:
      return {
        ...state,
        totalRecords: action.payload,
      };
    default:
      return state;
  }
};
