export const INITIATE_IMPORT = 'CBRE-PJM-CAPITAL-PLAN-IMPORT/INITIATE_IMPORT';
export const INITIATE_IMPORT_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-IMPORT/INITIATE_IMPORT_SUCCESS';
export const INITIATE_IMPORT_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-IMPORT/INITIATE_IMPORT_FAILURE';
export const IMPORT_PROJECT_START = 'CBRE-PJM-CAPITAL-PLAN-IMPORT/IMPORT_PROJECT_START';
export const IMPORT_PROJECT_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-IMPORT/IMPORT_PROJECT_SUCCESS';
export const IMPORT_PROJECT_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-IMPORT/IMPORT_PROJECT_FAILURE';
export const GET_IMPORTS_LIST_START = 'CBRE-PJM-CAPITAL-PLAN-IMPORT/GET_IMPORTS_LIST_START';
export const GET_IMPORTS_LIST_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-IMPORT/GET_IMPORTS_LIST_SUCCESS';
export const GET_IMPORTS_LIST_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-IMPORT/GET_IMPORTS_LIST_FAILURE';
export const GET_IMPORTS_DETAILS_START = 'CBRE-PJM-CAPITAL-PLAN-IMPORT/GET_IMPORTS_DETAILS_START';
export const GET_IMPORTS_DETAILS_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-IMPORT/GET_IMPORTS_DETAILS_SUCCESS';
export const GET_IMPORTS_DETAILS_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-IMPORT/GET_IMPORTS_DETAILS_FAILURE';
export const SET_SELECTED_JOB_ID = 'CBRE-PJM-CAPITAL-PLAN-IMPORT/SET_SELECTED_JOB_ID';
export const SET_LOADING_TRUE = 'CBRE-PJM-CAPITAL-PLAN-IMPORT/SET_LOADING_TRUE';
export const GET_IMPORTS_DROPDOWN_LIST_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-IMPORT/GET_IMPORTS_DROPDOWN_LIST_SUCCESS';
export const GET_IMPORTS_DROPDOWN_LIST_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-IMPORT/GET_IMPORTS_DROPDOWN_LIST_FAILURE';