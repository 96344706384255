import {
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  FETCH_GRID_LAYOUT_FAIL,
  FETCH_GRID_LAYOUT_SUCCESS,
  SET_GRID_LAYOUT_FAIL,
  SET_GRID_LAYOUT_SUCCESS,
  SET_CUSTOMIZING,
  GET_LEGACY_FAILURE,
  GET_LEGACY_SUCCESS,
} from './constants';

const initialState = { loading: true, gridLayout: [], customizing: false, allLegacyData: null };

const gridLayoutReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING_TRUE:
      return { ...state, loading: true };
    case SET_LOADING_FALSE:
      return { ...state, loading: false };
    case SET_CUSTOMIZING:
      return { ...state, customizing: payload };
    case FETCH_GRID_LAYOUT_FAIL:
      return { ...state, ...payload };
    case FETCH_GRID_LAYOUT_SUCCESS:
      return { ...state, gridLayout: payload ?? [] };
    case SET_GRID_LAYOUT_FAIL:
      return { ...state, ...payload };
    case SET_GRID_LAYOUT_SUCCESS:
      return { ...state, gridLayout: payload ?? [] };
    case GET_LEGACY_SUCCESS:
      return {
        ...state,
        allLegacyData: payload,
        loading: false,
      };
    case GET_LEGACY_FAILURE:
      return {
        ...state,
        allLegacyData: payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default gridLayoutReducer;
