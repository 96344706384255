import {
  CLIENT_BILLING_DELETE_START,
  CLIENT_BILLING_GET_SUCCESS,
  CLIENT_BILLING_GET_START,
  CLIENT_BILLING_GET_FAILED,
  CLIENT_BILLING_DELETE_SUCCESS,
  CLIENT_BILLING_DELETE_FAILED,
  CLIENT_BILLING_DELETE_RESET,
  CLIENT_BILLING_DETAILS_GET_SUCCESS,
  CLIENT_BILLING_DETAILS_GET_START,
  CLIENT_BILLING_DETAILS_GET_FAILED,
  CLIENT_BILLING_DETAILS_GET_RESET,
  GET_COST_STRUCTURE_FAILURE,
  GET_COST_STRUCTURE_START,
  GET_COST_STRUCTURE_SUCCESS,
  CLIENT_BILLING_DETAILS_SAVE_UPDATE_START,
  CLIENT_BILLING_DETAILS_SAVE_UPDATE_SUCCESS,
  CLIENT_BILLING_DETAILS_SAVE_UPDATE_FAILED,
  CLIENT_BILLING_DETAILS_SAVE_UPDATE_RESET,
  CLIENT_BILLING_SUBMIT_START,
  CLIENT_BILLING_SUBMIT_SUCCESS,
  CLIENT_BILLING_SUBMIT_FAILED,
  CLIENT_BILLING_REVOKE_START,
  CLIENT_BILLING_REVOKE_SUCCESS,
  CLIENT_BILLING_REVOKE_FAILED,
  CLIENT_BILLING_REVOKE_RESET,
  CLIENT_BILLING_VALIDATION_START,
  CLIENT_BILLING_VALIDATION_SUCCESS,
  CLIENT_BILLING_VALIDATION_RESET,
  FORECAST_PROJECT_DETAILS_GET_START,
  FORECAST_PROJECT_DETAILS_GET_SUCCESS,
  FORECAST_PROJECT_DETAILS_GET_FAILED,
  BID_STATUS_SAVE_SUCCESS,
  BID_STATUS_SAVE_FAILURE,
  CLIENT_INVOICES_GET_START,
  CLIENT_INVOICES_GET_SUCCESS,
  CLIENT_INVOICES_GET_FAILED
} from './constants';

const INITIAL_STATE = {
  error: false,
  errorCode: '',
  loading: false,
  fundingRequestTypes: [],
  fundingRequestDeleteInfo: {
    error: false,
    message: '',
    loading: false
  },
  fundingRequestDetails: {},
  defaultCostStructure: [],
  fundingRequestInfo: {
    message: '',
    messageCode: '',
    loading: false
  },
  fundingRequestUpsertInfo: {
    message: '',
    data: {},
    messageCode: '',
    loading: false
  },
  revokeData: {
    message: '',
    messageCode: '',
    loading: false
  },
  validationStarted: false,
  forecastDetails: {
    message: '',
    messageCode: '',
    loading: false,
    error: false
  },
  error: false,
  errorCode: '',
  loading: false,
  invoicesDetails: { clientInvoicesRes: { clientInvoices : []} },
  invoicesDetailsInfo: {
    message: '',
    messageCode: '',
    loading: false,
    error: false,
  },
};

// eslint-disable-next-line complexity,default-param-last
const fundingRequestReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLIENT_BILLING_DELETE_START:
      return {
        ...state,
        fundingRequestDeleteInfo: {
          message: '',
          error: false,
          messageCode: '',
          loading: true
        }
      };
    case CLIENT_BILLING_DELETE_RESET:
      return {
        ...state,
        fundingRequestDeleteInfo: {
          message: '',
          error: false,
          messageCode: '',
          loading: false
        }
      };
    case CLIENT_BILLING_DELETE_SUCCESS:
      return {
        ...state,
        fundingRequestDeleteInfo: {
          message: action.payload,
          messageCode: '',
          loading: false
        }
      };
    case CLIENT_BILLING_DELETE_FAILED:
      return {
        ...state,
        fundingRequestDeleteInfo: {
          message: action.payload,
          error: true,
          messageCode: '',
          loading: false
        },
        loading: false
      };
    case CLIENT_BILLING_GET_START:
      return {
        ...state,
        loading: true
      };
    case CLIENT_BILLING_GET_SUCCESS:
      return {
        ...state,
        fundingRequestTypes: action?.payload || [],
        loading: false
      };
    case CLIENT_BILLING_GET_FAILED:
      return {
        ...state,
        errorCode: action?.payload,
        loading: false
      };
    case CLIENT_BILLING_SUBMIT_START:
      return {
        ...state,
        loading: true
      };
    case CLIENT_BILLING_SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case CLIENT_BILLING_SUBMIT_FAILED:
      return {
        ...state,
        loading: false
      };
    case CLIENT_BILLING_REVOKE_START:
      return {
        ...state,
        loading: true,
        revokeData: {
          message: '',
          messageCode: '',
          loading: true
        }
      };
    case CLIENT_BILLING_REVOKE_RESET:
      return {
        ...state,
        loading: false,
        revokeData: {
          message: '',
          messageCode: '',
          loading: false
        }
      };
    case CLIENT_BILLING_REVOKE_SUCCESS:
      return {
        ...state,
        loading: false,
        revokeData: {
          message: 'success',
          loading: false,
          messageCode: ''
        }
      };
    case CLIENT_BILLING_REVOKE_FAILED:
      return {
        ...state,
        loading: false,
        revokeData: {
          message: 'failure',
          loading: false,
          messageCode: action.payload
        }
      };
    case CLIENT_BILLING_DETAILS_GET_START:
      return {
        ...state,
        loading: true
      };
    case CLIENT_BILLING_DETAILS_GET_SUCCESS:
      return {
        ...state,
        fundingRequestDetails: action?.payload || {},
        fundingRequestInfo: {
          message: 'success',
          messageCode: '',
          loading: false
        },
        loading: false
      };
    case CLIENT_BILLING_DETAILS_GET_FAILED:
      return {
        ...state,
        loading: false,
        fundingRequestInfo: {
          message: '',
          messageCode: '',
          loading: false
        }
      };
    case CLIENT_BILLING_DETAILS_GET_RESET:
      return {
        ...state,
        loading: false,
        fundingRequestInfo: {
          message: '',
          messageCode: '',
          loading: false
        }
      };
    case FORECAST_PROJECT_DETAILS_GET_START:
      return {
        ...state,
        loading: true
      };
    case FORECAST_PROJECT_DETAILS_GET_SUCCESS:
      return {
        ...state,
        forecastDetails: {
          ...(action?.payload?.foreCastData || { forecasts: [] })
        },
        loading: false
      };
    case FORECAST_PROJECT_DETAILS_GET_FAILED:
      return {
        ...state,
        loading: false,
        forecastDetails: {
          message: '',
          messageCode: '',
          loading: false,
          error: true
        }
      };
    case CLIENT_BILLING_DETAILS_SAVE_UPDATE_START:
      return {
        ...state,
        fundingRequestUpsertInfo: {
          message: '',
          data: {},
          messageCode: '',
          loading: true
        },
        loading: true
      };
    case CLIENT_BILLING_DETAILS_SAVE_UPDATE_SUCCESS:
      return {
        ...state,
        fundingRequestUpsertInfo: {
          message: 'success',
          data: action.payload,
          messageCode: '',
          loading: false
        },
        loading: false
      };
    case CLIENT_BILLING_DETAILS_SAVE_UPDATE_FAILED:
      return {
        ...state,
        fundingRequestUpsertInfo: {
          message: 'failure',
          data: {},
          messageCode: '',
          loading: false
        },
        loading: false
      };
    case CLIENT_BILLING_DETAILS_SAVE_UPDATE_RESET:
      return {
        ...state,
        fundingRequestUpsertInfo: {
          message: '',
          data: {},
          messageCode: '',
          loading: false
        },
        validationStarted: true,
        loading: false
      };
    case GET_COST_STRUCTURE_SUCCESS:
      return {
        ...state,
        defaultCostStructure: action.payload,
        loading: false
      };
    case GET_COST_STRUCTURE_START:
      return {
        ...state,
        defaultCostStructure: [],
        loading: true
      };
    case GET_COST_STRUCTURE_FAILURE:
      return {
        ...state,
        defaultCostStructure: [],
        loading: false
      };
    case CLIENT_BILLING_VALIDATION_START:
      return {
        ...state,
        validationStarted: true,
        loading: true
      };
    case CLIENT_BILLING_VALIDATION_SUCCESS:
      return {
        ...state,
        validationStarted: false,
        loading: false
      };
    case CLIENT_BILLING_VALIDATION_RESET:
      return {
        ...state,
        validationStarted: false,
        loading: false
      };
    case BID_STATUS_SAVE_SUCCESS:
      return {
        ...state,
        bidstatusInfo:{...action.payload}
      };
    case BID_STATUS_SAVE_FAILURE:
      return {
        ...state,
        bidstatusInfo:{...action.payload}
      };
      case CLIENT_INVOICES_GET_START:
        return {
          ...state,
          invoicesDetailsInfo: {
            message: '',
            messageCode: '',
            loading: true,
            error: false,
          },
          loading: true,
        };
        case CLIENT_INVOICES_GET_FAILED:
          return {
            ...state,
            loading: false,
            invoicesDetailsInfo: {
              message: '',
              messageCode: '',
              loading: false,
              error: true,
            },
          };
          case CLIENT_INVOICES_GET_SUCCESS:
            return {
              ...state,
              invoicesDetails: {
                ...(action?.payload ||{ clientInvoicesRes: { clientInvoices : []} }),
              },
              invoicesDetailsInfo: {
                message: 'success',
                messageCode: '',
                loading: false,
                error: false,
              },
              loading: false,
            };   
      default:
      return state;
  }
};

export default fundingRequestReducer;
